import { Inject, Injectable } from '@angular/core';
import { HEART_BEAT, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { rewriteProjectWorkspaceUrlPath } from '@core/interceptors/helper-interceptor';
import { TaskMessagesService } from '@core/modules/task-messages/task-messages.service';
import {
  ServiceName,
  ServiceState,
  Task,
  TaskNotification,
  WorkspaceInfo,
} from '@models';
import { Store } from '@ngrx/store';
import { AppActions, AuthActions } from '@store/index';
import * as WorkspaceActions from '@store/workspace/actions';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { WebSocketService } from './websocket.service';

type ServiceMap = Map<Partial<ServiceName>, ServiceState>;

@Injectable()
export class ServerStateSocketService {
  constructor(
    private _store: Store,
    private _webSocketService: WebSocketService,
    private _taskMessagesService: TaskMessagesService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private _websocketState$ = new BehaviorSubject<number>(WebSocket.CLOSED);
  private _serviceStates$ = new BehaviorSubject<ServiceMap>(new Map());
  private _webSocket: WebSocket | null = null;

  getServiceState(
    serviceName: ServiceName
  ): Observable<ServiceState | undefined> {
    return this._serviceStates$.pipe(
      map(serviceStates => serviceStates.get(serviceName))
    );
  }

  isSocketState(state: number): Observable<boolean> {
    return this._websocketState$.pipe(
      map(socketState => socketState === state)
    );
  }

  start(info: WorkspaceInfo): void {
    if (this._webSocket) {
      throw new Error('[Rosetta Error] Previous Web socket not closed!');
    }

    const webSocket = this._webSocketService.create(
      rewriteProjectWorkspaceUrlPath(
        `${this._config.sockets.serverState}/${info.urlPath}`,
        info
      )
    );

    this._webSocket = webSocket;
    this._webSocket.onopen = this._onOpen.bind(this);
    this._webSocket.onmessage = this._onMessage.bind(this);
    this._webSocket.onclose = this._onClose.bind(this);
    this._webSocket.onerror = this._onError.bind(this);
  }

  close(): void {
    if (this._webSocket && this._webSocket.readyState <= 1) {
      this._webSocket.close(1000);
      this._websocketState$.next(this._webSocket.readyState);
    }
    this._serviceStates$.next(new Map());
  }

  send(method: Task | string, params?: any): void {
    const options = {
      jsonrpc: '2.0',
      method,
      ...(params ? { params: JSON.stringify(params) } : {}),
    };

    if (!this._webSocket) {
      return;
    }

    this._webSocket.send(JSON.stringify(options));
  }

  private _onOpen(): void {
    this.send('InitStateServer');
    this._webSocket && this._websocketState$.next(this._webSocket.readyState);
  }

  private _onMessage(event: MessageEvent): void {
    if (typeof event.data === 'string') {
      if (event.data === HEART_BEAT) {
        return;
      }

      const json = JSON.parse(event.data);

      switch (json.method) {
        case 'ServiceState':
          const serviceState = ServiceState.create(json.params);
          this._updateServiceState(serviceState);
          break;
        case 'taskNotification':
          this._taskMessagesService.onTaskNotification(
            json.params as TaskNotification
          );
          break;
        case 'MultipleSessions':
          this._store.dispatch(
            AppActions.showErrorMsg({
              config: {
                message: this._config.text.MultipleSessions,
              },
            })
          );
          break;
        default:
          throw new Error(
            'panel-state socket message unknown method ' + json.method
          );
      }
    }
  }

  private _updateServiceState(serviceState: ServiceState): void {
    const currentServiceState = this._serviceStates$.value;

    const updatedMap = new Map([
      ...currentServiceState.entries(),
      [serviceState.service, serviceState],
    ]);
    this._serviceStates$.next(updatedMap);
  }

  private _onClose(): void {
    if (this._webSocket) {
      this._websocketState$.next(this._webSocket.readyState);
      this._webSocket = null;
    }

    this._store.dispatch(WorkspaceActions.websocketClose());
    this._store.dispatch(AuthActions.checkUserIsAuthenticated());
  }

  private _onError(): void {
    if (this._webSocket) {
      this._websocketState$.next(this._webSocket.readyState);
    }

    this._store.dispatch(WorkspaceActions.websocketError());
  }
}
