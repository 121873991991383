import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WorkspaceMenuItemState, WorkspaceModelInfo } from '@models';
import { UpgradeIconComponent } from '@shared/components/upgrade-icon/upgrade-icon.component';

@Component({
  standalone: true,
  imports: [UpgradeIconComponent],
  selector: 'app-project-version-cell',
  templateUrl: './project-version-cell.component.html',
  styleUrls: ['./project-version-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectVersionCellComponent implements ICellRendererAngularComp {
  modelInfo?: WorkspaceModelInfo;

  agInit({ data }: ICellRendererParams<WorkspaceMenuItemState>): void {
    if (data) {
      this.modelInfo = {
        modelId: data.modelId,
        modelVersion: data.modelVersion,
        modelShortname: data.modelShortname,
        upgradeWarning: data.upgradeWarning,
        deprecationMessage: data.deprecationMessage,
      };
    }
  }

  refresh(): boolean {
    return false;
  }
}
