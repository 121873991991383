import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import {
  BaseWorkspaceStateService,
  WorkspaceChangeEventType,
} from '@core/services';
import {
  WorkspaceUrlPrefixOperator,
  catchErrorAndReturn,
  firstNotNullAndComplete,
} from '@utils/operators';
import { Observable, map, of, shareReplay, switchMap, tap } from 'rxjs';

type SupportedFeaturesMap = Record<string, Observable<boolean>>;

@Injectable()
export class SupportedFeatureService extends BaseWorkspaceStateService {
  constructor(
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {
    super({ changeEventType: WorkspaceChangeEventType.ENGINE });
  }

  private readonly _supportFeatures = [
    // This method has been copied from the RegulationApiService so that we can find out if a model
    // supports the new regulation panel before opening it. It can be removed when this has been fully rolled out.
    {
      featureTabName: 'regulation',
      resourcePath: this._config.resourcePaths.bsp,
      endpoint: '/regulation/body-corpus-list',
    },
  ] as const;

  private _supportedFeaturesMap?: SupportedFeaturesMap;

  override onWorkspaceSwitch(): void {
    this._supportedFeaturesMap = undefined;
  }

  checkSupportFor(featureTabName: string): Observable<boolean> {
    return this.workspaceSwitchAndReady().pipe(
      map(() => this._getSupportedFeatureMap()),
      firstNotNullAndComplete(),
      switchMap(supportedFeatures =>
        supportedFeatures[featureTabName]?.pipe(
          tap(response => {
            if (this._supportedFeaturesMap) {
              this._supportedFeaturesMap[featureTabName] = of(response);
            }
          })
        )
      )
    );
  }

  private _getSupportedFeatureMap(): SupportedFeaturesMap {
    if (!this._supportedFeaturesMap) {
      this._supportedFeaturesMap = this._createSupportFeatureMap();
    }
    return this._supportedFeaturesMap;
  }

  private _createSupportFeatureMap(): SupportedFeaturesMap {
    return this._supportFeatures.reduce(
      (acc, { featureTabName, resourcePath, endpoint }) => {
        acc[featureTabName] = this._createFeatureSupportedObservable(
          resourcePath,
          endpoint
        );
        return acc;
      },
      {} as SupportedFeaturesMap
    );
  }

  private _createFeatureSupportedObservable(
    resourcePath: string,
    endpoint: string
  ): Observable<boolean> {
    return this._store.pipe(
      WorkspaceUrlPrefixOperator(resourcePath),
      switchMap(url => this._http.get<any>(`${url}${endpoint}`)),
      map(() => true),
      catchErrorAndReturn(false),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}
