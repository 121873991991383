<ng-template #defaultTemplate let-option>
  {{ option?.label }}
</ng-template>

<mat-form-field
  [tourAnchor]="selector().tourAnchor"
  appearance="outline"
  [floatLabel]="'always'"
  [class.auto-width]="!!control.value"
>
  <mat-label>{{ selector().label }}</mat-label>

  @if (selector().dataLoading$ | async) {
    <fa-icon
      @inOutAnimation
      matIconPrefix
      icon="circle-notch"
      class="theme-color-primary pl-4 pr-4"
      animation="spin"
      [style.--fa-animation-duration]="'.9s'"
    />
  }
  <mat-select
    *ngrxLet="groupedOptions$ | async as groups"
    hideSingleSelectionIndicator="true"
    [panelClass]="selectorClass()"
    [placeholder]="
      $hasOptions() ? selector().placeholder : 'No options to select'
    "
    [formControl]="control"
    (valueChange)="onValueChanged($event)"
    (openedChange)="onOpenChanged($event)"
  >
    <mat-select-trigger>
      <ng-container
        *ngTemplateOutlet="
          triggerTemplate() || defaultTemplate;
          context: {
            $implicit: selector().findDataItemFromValue(control.value),
          }
        "
      />
    </mat-select-trigger>

    <ng-template #optionsTemplate let-options>
      @for (option of options; track option) {
        <mat-option [value]="option.value">
          <ng-container
            *ngTemplateOutlet="
              optionTemplate() || defaultTemplate;
              context: { $implicit: option }
            "
          />
        </mat-option>
      }
    </ng-template>

    @if (groups.length === 1) {
      <ng-container
        *ngTemplateOutlet="
          optionsTemplate;
          context: {
            $implicit: groups[0]?.options,
          }
        "
      />
    } @else {
      @for (group of groups; track group.label) {
        <mat-optgroup [label]="group.label" class="sticky-groups">
          <ng-container
            *ngTemplateOutlet="
              optionsTemplate;
              context: {
                $implicit: group.options,
              }
            "
          />
        </mat-optgroup>
      }
    }

    <ng-content select="[appRosettaSelectorFooter]" />
  </mat-select>
</mat-form-field>
