/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ThemeColorClass } from '@models';

type MessageType = 'warning' | 'error' | 'info';
interface PanelTypeTheme {
  icon: IconProp;
  iconTheme: ThemeColorClass;
  textTheme: ThemeColorClass;
}

@Component({
  selector: 'app-rosetta-info-message',
  standalone: true,
  imports: [FontsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <fa-icon [class]="iconTheme()" size="lg" [icon]="icon()" />
    <div [class]="textTheme()"><ng-content /></div>
  `,
  styles: [
    `
      @use 'partials/variables' as var;

      :host {
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
        padding: 0.5rem 1rem;
        border-radius: var.$border-radius;
        word-break: break-word;
        line-height: 1.3;
        box-sizing: border-box;

        > div > *:last-child {
          margin-bottom: 0 !important;
        }
      }

      :host(.inline-panel-error),
      :host(.inline-panel-warning) {
        font-weight: bold;
      }
    `,
  ],
  host: {
    class: 'theme-bg-light',
    '[class]': 'type()',
  },
})
export class RosettaInfoMessageComponent {
  type = input<MessageType>('info');

  config = computed(() => this.stateMap[this.type()]);
  icon = computed(() => this.config().icon);
  iconTheme = computed(() => this.config().iconTheme);
  textTheme = computed(() => this.config().textTheme);

  private readonly stateMap: Record<MessageType, PanelTypeTheme> = {
    info: {
      icon: 'info-circle',
      iconTheme: 'theme-color-highlight',
      textTheme: 'theme-color-50',
    },
    warning: {
      icon: 'exclamation-triangle',
      iconTheme: 'theme-color-yellow-alt',
      textTheme: 'theme-color-50',
    },
    error: {
      icon: 'exclamation-circle',
      iconTheme: 'theme-color-warn',
      textTheme: 'theme-color-warn',
    },
  };
}
