@if (hideNative) {
  <app-rosetta-code-editor
    [editorOptions]="editorOptions"
    [options]="options"
    [model]="{
      value: codeView?.nativeCode,
      language,
    }"
  />
}

<div [hidden]="hideNative">
  <header class="small-button-wrapper">
    <button
      class="m-4"
      mat-icon-button
      (click)="treeControl.collapseAll()"
      [disabled]="!codeView?.codeViewNode"
    >
      <fa-icon icon="minus-square" [fixedWidth]="true" />
    </button>
    <button
      class="m-4"
      mat-icon-button
      (click)="treeControl.expandAll()"
      [disabled]="!codeView?.codeViewNode"
    >
      <fa-icon icon="plus-square" [fixedWidth]="true" />
    </button>
    <div class="spacer"></div>
    <app-code-view-search (changes)="scrollTo($event)" />
  </header>

  <ng-scrollbar
    class="flex-1"
    orientation="auto"
    [appScrollPosition]="name"
    *ngrxLet="selectedNodeId$ | async as selectedNodeId"
  >
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      [appPersistExpandState]="name"
    >
      <!-- LEAF-NODE-START -->
      <mat-tree-node
        *matTreeNodeDef="let node"
        class="mat-tree-node"
        [class]="node.nodeClass"
        [class.selected]="node.id === selectedNodeId"
        (click)="selectNode(node.id)"
      >
        <div matTreeNodePadding matTreeNodePaddingIndent="20">
          <button mat-icon-button disabled></button>

          <span class="node-name" [class]="node.nodeClass">
            {{ node?.name }}
          </span>

          @if (node.errors.length > 0) {
            <fa-icon
              class="opacity-50"
              icon="circle-exclamation"
              [appRosettaOverlayHoverTarget]="overlay"
            />
          }
          <app-rosetta-overlay #overlay>
            <app-rosetta-menu maxWidth="450">
              @for (error of node.errors; track error) {
                <p>{{ error }}</p>
              }
            </app-rosetta-menu>
          </app-rosetta-overlay>
        </div>

        <div
          [id]="node?.id"
          #nodeId
          class="node-value"
          [class]="node.nodeClass"
        >
          @if (node.linkId) {
            <span appStopPropagation (click)="onLinkId(node)">{{
              node?.value
            }}</span>
          } @else {
            {{ node?.value }}
          }
        </div>
      </mat-tree-node>
      <!-- LEAF-NODE-END -->

      <!-- NODE-START -->
      <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        class="group-node"
        [class]="node.nodeClass"
        [class.selected]="node.id === selectedNodeId"
        (click)="selectNode(node.id)"
      >
        <div matTreeNodePadding matTreeNodePaddingIndent="20">
          <button
            matTreeNodeToggle
            mat-icon-button
            [attr.aria-label]="'toggle ' + node?.name"
          >
            <fa-icon
              [icon]="[
                'fas',
                treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right',
              ]"
            />
          </button>

          <span class="node-name" [class]="node.childClass">
            {{ node?.name }}
          </span>

          @if (node.errors.length > 0) {
            <fa-icon
              class="opacity-50"
              icon="circle-exclamation"
              [appRosettaOverlayHoverTarget]="overlay"
            />
          }
          <app-rosetta-overlay #overlay>
            <app-rosetta-menu maxWidth="450">
              @for (error of node.errors; track error) {
                <p>{{ error }}</p>
              }
            </app-rosetta-menu>
          </app-rosetta-overlay>
        </div>

        <div
          [id]="node?.id"
          #nodeId
          class="node-value"
          [class]="node.nodeClass"
        >
          @if (node.linkId) {
            <span appStopPropagation (click)="onLinkId(node)">{{
              node?.value
            }}</span>
          } @else {
            {{ node?.value }}
          }
        </div>
      </mat-tree-node>
      <!-- NODE-END -->
    </mat-tree>
  </ng-scrollbar>

  @if (showSelectedCodeView) {
    <app-code-view-selected
      [node]="selectedNode$ | async"
      [modelContent]="modelContent"
    />
  }
</div>
