export enum ServiceName {
  Static = 'STATIC',
  Rosetta = 'ROSETTA',
  Ingestion = 'INGESTION',
  Regulations = 'REGULATIONS',
  IngestActivation = 'INGEST_ACTIVATION',
  GridView = 'GRIDVIEW',
  Model = 'MODEL',
}

export class ServiceState {
  public static create(input: any): ServiceState {
    if (input !== 'undefined') {
      if (input.service && input.success !== 'undefined') {
        return new ServiceState(input.service, input.success);
      }
      throw new Error(
        'Unable to create ServiceState from input: ' + JSON.stringify(input)
      );
    } else {
      throw new Error('Unable to create ServiceSate from input: undefined');
    }
  }

  constructor(
    readonly service: ServiceName,
    readonly success: boolean
  ) {}
}
