<mat-form-field appearance="outline" class="width-full">
  <mat-label>{{ label }}</mat-label>
  <input
    #input
    matInput
    [matAutocomplete]="auto"
    [formControl]="searchControl"
    [placeholder]="placeholder"
    (input)="onSearchInput()"
    (focus)="input.select()"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    [displayWith]="displayWithFunc"
    (optionSelected)="onOptionSelected($event)"
    (closed)="checkValidSection()"
    (opened)="resetList()"
  >
    @if (filteredList() === null) {
      <mat-option [value]="null" disabled> No results </mat-option>
    }
    @for (result of filteredList(); track result) {
      <mat-option [value]="result">
        <h4 class="mb-0" [innerHtml]="result.highlightLabel"></h4>
        @if (result?.highlightDescription) {
          <small
            class="theme-color-50"
            [innerHtml]="result.highlightDescription"
          ></small>
        }
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
