import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { EN_DASH } from '@configs';
import * as transform from '@shared/components/transform/models/data-viewer';
import { GoogleChartInterface } from 'ng2-google-charts';
import { DiagnosticsSummaryData } from '../../../diagnostic-chart/diagnostic.models';
import { DiagnosticsSummaryComponent } from '../../../diagnostics-summary/diagnostics-summary.component';

@Component({
  standalone: true,
  imports: [FontsModule, MatTooltipModule, DiagnosticsSummaryComponent],
  selector: 'app-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './summary.component.html',
  styles: [
    `
      :host {
        padding: 0.25rem 1rem;
        align-items: center;
        height: 3rem;

        > span {
          padding-right: 1.5rem;

          strong {
            padding-left: 0.5rem;
          }
        }

        app-diagnostic-chart {
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    `,
  ],
  host: {
    class: 'flex-row color-50 theme-bg-light',
  },
})
export class SummaryComponent {
  readonly EN_DASH = EN_DASH;

  diagnosticsSummary?: DiagnosticsSummaryData;

  mappingChart?: GoogleChartInterface;
  validationChart?: GoogleChartInterface;

  @Input()
  public set summaryData(value: transform.DataViewerSummary) {
    if (value !== null) {
      this._summaryData = value;
      this.diagnosticsSummary = value?.diagnosticsSummaryData;
      return;
    }
    this.diagnosticsSummary = undefined;
  }
  public get summaryData(): transform.DataViewerSummary {
    return this._summaryData;
  }

  private _summaryData: transform.DataViewerSummary =
    transform.getDefaultDataViewerSummary();
}
