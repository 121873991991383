import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DiagnosticResultKey } from '@shared/components/diagnostic-panel/models/diagnostic.model';
import { RosettaTitleCasePipe } from '@shared/pipes';
import { DiagnosticChartComponent } from '../diagnostic-chart/diagnostic-chart.component';
import {
  DiagnosticSummary,
  DiagnosticsSummaryData,
} from '../diagnostic-chart/diagnostic.models';

@Component({
  standalone: true,
  imports: [DiagnosticChartComponent, RosettaTitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-diagnostics-summary',
  template: `
    @for (key of diagnosticsSummary?.keys(); track key) {
      @if (!isEmpty(diagnosticsSummary?.get(key))) {
        <app-diagnostic-chart
          [title]="key + 's' | rosettaTitleCase"
          [icon]="getIcon(key)"
          [diagnosticSummary]="diagnosticsSummary.get(key)"
          [showExcluded]="showExcluded"
        />
      }
    }
  `,
  styles: [
    `
      app-diagnostic-chart {
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    `,
  ],
  host: {
    class: 'flex flex-right-align color-50',
  },
})
export class DiagnosticsSummaryComponent {
  @Input({ transform: booleanAttribute })
  showExcluded?: boolean;

  @Input()
  set diagnosticsSummary(diagnosticsSummary: DiagnosticsSummaryData) {
    if (!diagnosticsSummary) {
      this._diagnosticsSummary = undefined;
      return;
    }
    // Ensure charts appear in correct order
    this._diagnosticsSummary = new Map(
      [...diagnosticsSummary.entries()].sort()
    );
  }
  get diagnosticsSummary(): DiagnosticsSummaryData {
    return this._diagnosticsSummary;
  }

  private _diagnosticsSummary: DiagnosticsSummaryData;

  isEmpty(diagnosticSummary: DiagnosticSummary): boolean {
    return (
      !diagnosticSummary ||
      (!diagnosticSummary.success &&
        !diagnosticSummary.failure &&
        !diagnosticSummary.excluded)
    );
  }

  getIcon(key: DiagnosticResultKey): IconProp {
    switch (key) {
      case 'mapping':
        return ['far', 'map'];
      case 'validation':
        return 'unlink';
      case 'qualification':
        return null;
    }
  }
}
