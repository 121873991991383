import { ClipboardModule } from '@angular/cdk/clipboard';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { RosettaConfig } from '@configs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Color } from '@models';
import { BehaviorSubject, map, merge, switchMap, timer } from 'rxjs';

interface ICopyPasteIconProps {
  icon: IconProp;
  color?: Color;
}

const HIDE_ICON_DELAY = 2_000;
const DEFAULT_ICON: ICopyPasteIconProps = { icon: ['far', 'copy'] };
const SUCCESS_ICON: ICopyPasteIconProps = {
  color: RosettaConfig.color.primary,
  icon: 'check',
};
const ERROR_ICON: ICopyPasteIconProps = {
  color: RosettaConfig.color.warn,
  icon: 'times',
};

@Component({
  standalone: true,
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    FontsModule,
    ClipboardModule,
    MatButtonModule,
    MatTooltipModule,
  ],
})
export class CopyToClipboardComponent {
  @Input() path = '';

  private _iconSubject$ = new BehaviorSubject<ICopyPasteIconProps>(
    DEFAULT_ICON
  );
  private _defaultIcon$ = this._iconSubject$.pipe(
    switchMap(() => timer(HIDE_ICON_DELAY).pipe(map(() => DEFAULT_ICON)))
  );
  iconData$ = merge(this._defaultIcon$, this._iconSubject$);

  onCopied(success: boolean): void {
    this._iconSubject$.next(success ? SUCCESS_ICON : ERROR_ICON);
  }
}
