<tour-step-template>
  <ng-template let-step="step">
    <div
      [style.width]="step.stepDimensions?.width"
      [style.min-width]="step.stepDimensions?.minWidth"
      [style.max-width]="step.stepDimensions?.maxWidth"
      [class]="step.popoverClass"
      class="rosetta-tour-wrapper"
      appStopPropagation
    >
      <h4 [innerHTML]="step?.title"></h4>
      <p [innerHTML]="step?.content" class="color-50"></p>

      <div
        class="flex-row flex-row-center"
        [class.no-progress]="!step.showProgress"
      >
        <button
          mat-icon-button
          class="prev"
          [disabled]="!tourService.hasPrev(step)"
          (click)="tourService.prev()"
        >
          {{ step?.prevBtnTitle }}
        </button>

        <button
          mat-icon-button
          [disabled]="!tourService.hasPrev(step)"
          (click)="prev()"
        >
          <fa-icon icon="arrow-left" />
        </button>

        @if (step.showProgress) {
          <div class="progress">
            {{ tourService.steps.indexOf(step) + 1 }} /
            {{ tourService.steps.length }}
          </div>
        }

        @if (tourService.hasNext(step) && !step.nextOnAnchorClick) {
          <button mat-icon-button (click)="next()">
            <fa-icon icon="arrow-right" />
          </button>
        }

        <div class="spacer"></div>

        @if (!tourService.hasNext(step)) {
          <button mat-stroked-button color="primary" (click)="end()">
            Got it
          </button>
        }
      </div>
    </div>
  </ng-template>
</tour-step-template>
