import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import {
  IRegisterUserResponse,
  UserRegistrationCredentials,
} from './models/register.model';

@Injectable({
  providedIn: 'root',
})
export class RegisterApiService {
  constructor(
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  register(
    newUser: UserRegistrationCredentials
  ): Observable<IRegisterUserResponse> {
    return this._http.post<IRegisterUserResponse>(
      `${this._config.resourcePaths.userServer}/auth/register`,
      newUser
    );
  }
}
