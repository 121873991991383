import { IDomainModelDetails, Workspace } from '@models';
import { WorkspaceDto } from '@models/dto';
import {
  createWorkspaceItemState,
  getItemModelName,
  workspaceInfoFactory,
} from '@store/workspace/reducers/workspace.reducers.helper';
import { OperatorFunction, map } from 'rxjs';

export const fromWorkspaceDto = (): OperatorFunction<
  [WorkspaceDto, IDomainModelDetails[]],
  Workspace
> => {
  return map(([workspaceDto, models]) => {
    const model = models.find(
      domainModel => domainModel.id === workspaceDto.info.modelId
    );
    return {
      info: workspaceInfoFactory(workspaceDto.info, model),
      items: workspaceDto.items.map(item =>
        createWorkspaceItemState(item, getItemModelName(item, models))
      ),
    };
  });
};
