import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { StatusIndicatorComponent } from '@shared/components/status-indicator/status-indicator.component';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { distinctUntilChanged, map } from 'rxjs';

@Component({
  selector: 'app-workspace-footer-nav-status',
  standalone: true,
  imports: [AsyncPipe, StatusIndicatorComponent, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./workspace-footer-nav-status.component.scss'],
  template: `<app-status-indicator
    [taskStatus$]="status$"
    [diameter]="16"
    [matTooltip]="statusLabel$ | async"
  />`,
  host: { class: 'workspace-footer-nav-status' },
})
export class WorkspaceFooterNavStatusComponent {
  constructor(private _store: Store) {}

  status$ = this._store
    .select(WorkspaceSelectors.getWorkspaceTaskStatus)
    .pipe(distinctUntilChanged());
  statusLabel$ = this.status$.pipe(map(status => `Model: ${status}`));
}
