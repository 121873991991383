<!-- Templates -->
<ng-template
  #actionBtnTemplate
  let-action="action"
  let-tooltip="tooltip"
  let-icon="icon"
>
  <button
    type="button"
    class="btn-no-min-width"
    mat-stroked-button
    (click)="onClick(action)"
    matTooltip="{{ tooltip }}"
    matTooltipShowDelay="500"
  >
    <fa-icon [icon]="icon" />
  </button>
</ng-template>

<!-- Main Content -->
@if (rowError) {
  <app-warning-triangle
    icon="exclamation-triangle"
    class="mr-8 theme-color-warn"
  />
}

<div class="cell-value mr-16 color-75 spacer" [class.remove-row]="isRowRemoved">
  <span class="text-ellipsis">
    {{ cellValue | emptyString }}
  </span>
</div>

<app-transform-status
  [class.ros-row-hover-hide]="hideIconOnHover"
  [status]="rowData.sampleRowStatus"
  [storedOnClient]="storedOnClient"
/>

<div appStopPropagation class="ros-row-hover-show small-button-wrapper">
  @if (canEdit) {
    <ng-container
      [ngTemplateOutlet]="actionBtnTemplate"
      [ngTemplateOutletContext]="{
        action: sampleRowAction.Update,
        tooltip: 'Update',
        icon: ['far', 'edit'],
      }"
    />
  }
  @if (canCopy) {
    <ng-container
      [ngTemplateOutlet]="actionBtnTemplate"
      [ngTemplateOutletContext]="{
        action: sampleRowAction.Copy,
        tooltip: 'Copy',
        icon: ['far', 'copy'],
      }"
    />
  }
  @if (canUpdate) {
    <ng-container
      [ngTemplateOutlet]="actionBtnTemplate"
      [ngTemplateOutletContext]="{
        action: sampleRowAction.Accept,
        tooltip: 'Accept expectation changes',
        icon: 'check',
      }"
    />
  }
  @if (canDiscard) {
    <ng-container
      [ngTemplateOutlet]="actionBtnTemplate"
      [ngTemplateOutletContext]="{
        action: sampleRowAction.Revert,
        tooltip: 'Discard changes',
        icon: 'undo',
      }"
    />
  }
  @if (canDelete) {
    <ng-container
      [ngTemplateOutlet]="actionBtnTemplate"
      [ngTemplateOutletContext]="{
        action: sampleRowAction.Delete,
        tooltip: 'Delete',
        icon: 'trash',
      }"
    />
  }
</div>
