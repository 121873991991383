import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShowWhenViewDirective } from '@features/workspace/directives/show-when-view.directive';
import { RosettaGridComponent } from '@features/workspace/modules/design/definition/rosetta-grid/rosetta-grid.component';
import { RosettaGraphicalNavigatorComponent } from '@features/workspace/modules/design/graphical/pages/rosetta-graphical-navigator/rosetta-graphical-navigator.component';
import { RegulationComponent } from '@features/workspace/modules/design/regulation/pages/regulation/regulation.component';
import { TextualComponent } from '@features/workspace/modules/design/textual/pages/textual/textual.component';

@Component({
  selector: 'app-workspace-content',
  standalone: true,
  imports: [
    RegulationComponent,
    RosettaGraphicalNavigatorComponent,
    RosettaGridComponent,
    ShowWhenViewDirective,
    TextualComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'workspace-content' },
  styles: [
    `
      @use 'partials/variables' as var;

      :host {
        display: block;
        height: calc(100% - var.$panel-height-lg);
        position: relative;
      }
    `,
  ],
  template: `
    @defer (on immediate) {
      <app-textual appShowWhenView="textual" />
    }
    @defer (on idle) {
      <app-rosetta-grid *appShowWhenView="'definition'" />
    }
    @defer (on idle) {
      <app-rosetta-graphical-navigator *appShowWhenView="'graphical'" />
    }
    @defer (on idle) {
      <app-regulation *appShowWhenView="'regulation'" />
    }
  `,
})
export class WorkspaceContentComponent {}
