@if (currentTab$ | async; as currentTab) {
  <div class="header-title spacer">
    <fa-icon [icon]="currentTab.icon" class="theme-color-primary mr-8" />
    <h4 class="color-75 mb-0">
      <span class="font-weight-bold">{{ currentTab.label }}</span>
    </h4>
    @if (currentTab.isBeta) {
      <app-text-badge class="theme-bg-color ml-16"
        ><small>Beta Feature</small></app-text-badge
      >
    }
  </div>
}

@if ({ isExpanded: isExpanded$ | async }; as vm) {
  <button mat-icon-button (click)="toggle(vm.isExpanded)">
    <fa-icon [icon]="['fas', vm.isExpanded ? 'expand-alt' : 'compress-alt']" />
  </button>
  <button mat-icon-button (click)="close()">
    <fa-icon icon="times" />
  </button>
}
