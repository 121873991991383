import { ALL_MODELS } from '@configs';
import { SettingsReducers } from '@features/settings/store';
import { LoadingState } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectFeature = createFeatureSelector<SettingsReducers.State>(
  SettingsReducers.featureKey
);

export const selectUserProfileSaveState = createSelector(
  selectFeature,
  state => state.userProfileSaveState.state
);

export const selectDownloadState = (modelId: ModelInstanceId = ALL_MODELS) =>
  createSelector(
    selectFeature,
    (state: SettingsReducers.State) =>
      state.downloadState[modelId] || {
        state: LoadingState.INITIAL,
        errorMessage: null,
      }
  );

export const isUserProfileSaving = createSelector(
  selectUserProfileSaveState,
  state => state === LoadingState.LOADING
);
