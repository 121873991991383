import { Injectable } from '@angular/core';
import { ServerStateSocketService } from '@core/services/server-state-socket.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TaskActions } from '@store/.';
import { tap } from 'rxjs';

@Injectable()
export class TaskEffects {
  constructor(
    private _actions$: Actions,
    private _ServerStateSocketService: ServerStateSocketService
  ) {}

  submitTask$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(TaskActions.submitTask),
        tap(({ data }) =>
          this._ServerStateSocketService.send('submitTask', data)
        )
      );
    },
    { dispatch: false }
  );
}
