import { Directive, Input, TemplateRef } from '@angular/core';
import { DiagnosticGroup } from '../models/diagnostic.model';

@Directive({
  standalone: true,
  selector: '[appDiagnosticPanelGroupDef]',
})
export class DiagnosticPanelGroupDefDirective {
  @Input('appDiagnosticPanelGroupDef') groupDef!: DiagnosticGroup['def'];

  constructor(public templateRef: TemplateRef<unknown>) {}
}
