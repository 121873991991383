import { EnvironmentProviders, Provider } from '@angular/core';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { SUPPORT_VIEW } from '@configs';
import {
  ActiveServerGuard,
  AuthGuardFunc,
  LoginGuard,
  PermissionGuardFunc,
  RedirectGuard,
} from '@core/guards';
import { NavbarComponent } from '@core/modules/navbar/pages/navbar.component';
import { AllDocumentsAcceptedGuard } from '@features/auth/login';
import { CustomRoutes } from '@models';
import {
  startPollingServerStatus,
  stopPollingServerStatus,
} from '@store/server-status/server-status.actions';
import { CustomRouteReuseStrategy } from './app-routing-reuse-strategy';
import { dispatchAction } from './utils/store.utils';

const routes: CustomRoutes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    loadComponent: () =>
      import('./shared/components/landing/landing.component').then(
        m => m.LandingComponent
      ),
    canActivateChild: [LoginGuard, ActiveServerGuard],
    children: [
      {
        title: 'Login - Rosetta',
        path: 'login',
        loadComponent: () =>
          import(
            './features/auth/login/components/login/login-page.component'
          ).then(m => m.LoginPageComponent),
      },
      {
        title: 'Password Reset - Rosetta',
        path: 'password-reset',
        loadComponent: () =>
          import(
            './features/auth/password-reset/components/password-reset/password-reset.component'
          ).then(m => m.PasswordResetComponent),
      },
      {
        title: 'Register - Rosetta',
        path: 'register',
        loadComponent: () =>
          import(
            './features/auth/register/pages/registration/registration.component'
          ).then(m => m.RegistrationComponent),
      },
      {
        path: 'error/:errorCode',
        canActivate: [dispatchAction(startPollingServerStatus())],
        canDeactivate: [dispatchAction(stopPollingServerStatus())],
        loadComponent: () =>
          import('./features/errors/components/error/error.component').then(
            m => m.ErrorComponent
          ),
      },
    ],
  },

  // Public
  {
    path: 'redirect',
    title: 'Redirect - Rosetta',
    canActivate: [LoginGuard, RedirectGuard],
    loadComponent: () =>
      import('./shared/components/landing/landing.component').then(
        m => m.LandingComponent
      ),
  },
  {
    path: 'documents',
    title: 'Documents - Rosetta',
    loadChildren: () =>
      import('./features/documents/documents.module').then(
        m => m.DocumentsModule
      ),
  },

  // Authenticated Routes
  {
    path: '',
    canLoad: [AuthGuardFunc],
    canActivateChild: [AllDocumentsAcceptedGuard],
    component: NavbarComponent,
    children: [
      {
        path: 'support',
        title: 'Rosetta',
        canLoad: [PermissionGuardFunc],
        data: {
          abilityName: SUPPORT_VIEW,
          ignoreLastUsedWorkspace: true,
        },
        loadChildren: () =>
          import('./features/support/support.module').then(
            m => m.SupportModule
          ),
      },
      {
        path: 'settings',
        title: 'Settings - Rosetta',
        data: { ignoreLastUsedWorkspace: true },
        canLoad: [AuthGuardFunc],
        loadChildren: () =>
          import('./features/settings/settings.module').then(
            m => m.SettingsModule
          ),
      },
      {
        path: 'workspace-manager',
        title: 'Workspace Manager - Rosetta',
        data: { ignoreLastUsedWorkspace: true },
        canLoad: [AuthGuardFunc],
        pathMatch: 'full',
        loadChildren: () =>
          import('./features/workspace-manager/workspace-manager.module').then(
            m => m.WorkspaceManagerModule
          ),
      },
      {
        path: 'workspaces/:workspaceName',
        title: 'Workspace - Rosetta',
        data: { ignoreLastUsedWorkspace: true },
        canLoad: [AuthGuardFunc],
        loadChildren: () =>
          import('./features/workspace/workspace.module').then(
            m => m.WorkspaceModule
          ),
      },
    ],
  },

  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

export const provideAppRoutingConfig = (): (
  | EnvironmentProviders
  | Provider
)[] => [
  provideRouter(
    routes,
    withViewTransitions({ skipInitialTransition: true }),
    withPreloading(PreloadAllModules),
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
    }),
    withRouterConfig({
      paramsInheritanceStrategy: 'always',
    })
  ),
  { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
];
