import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-collapsible-panel-body',
  standalone: true,
  template: `<ng-content />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'collapsible-panel-body',
  },
  styles: [
    `
      .collapsible-panel-body {
        flex: 1;
        overflow-y: auto;
      }
    `,
  ],
})
export class CollapsiblePanelBodyComponent {}
