import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';
import { FontsModule } from '@app/fonts/fonts.module';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { EngineFeatureTabList } from '@features/workspace/modules/engine/engine-config';
import { QueryParams } from '@models';
import { Store } from '@ngrx/store';
import { TextBadgeComponent } from '@shared/components/text-badge/text-badge.component';
import * as RouterSelectors from '@store/router/router.selector';
import { filterNotNulls } from '@utils/operators';
import { map, shareReplay } from 'rxjs';

@Component({
  selector: 'app-rosetta-panel',
  standalone: true,
  imports: [AsyncPipe, FontsModule, TextBadgeComponent, MatButtonModule],
  templateUrl: './rosetta-panel.component.html',
  styleUrls: ['./rosetta-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'theme-bg-2 theme-border-bottom small-button-wrapper',
  },
})
export class RosettaPanelComponent {
  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _navigate: RosettaNavigationService
  ) {}

  currentTab$ = this._store
    .select(RouterSelectors.selectRouterPathSegments)
    .pipe(
      map(urlSegments => urlSegments.at(2)),
      filterNotNulls(),
      map(({ path }) => EngineFeatureTabList.find(tab => tab.url === path))
    );

  isExpanded$ = this._route.queryParams.pipe(
    map(params => JSON.parse(params[QueryParams.TOP_PANEL])),
    shareReplay<boolean>(1)
  );

  close = () => this._navigate.closeBottomPanel();
  toggle = (isExpanded: boolean) =>
    isExpanded
      ? this._navigate.maximiseBottomPanel()
      : this._navigate.openTopAndBottom();
}
