/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { FormConfig } from '@configs';
import { BaseDeferDialogService } from '@core/services/base-defer-dialog.service';
import { IProjectLicenseDialogData } from '@models';
import { tap } from 'rxjs';
import { ConfirmationData } from './confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CoreDialogService extends BaseDeferDialogService {
  openIssueTracker() {
    return this.lazyOpenDialog(() =>
      import('./issue-tracker/issue-tracker.component').then(
        m => m.IssueTrackerComponent
      )
    );
  }

  openReleaseNotes() {
    return this.lazyOpenDialog(() =>
      import(
        './release-notes-viewer-dialog/release-notes-viewer-dialog.component'
      ).then(m => m.ReleaseNotesViewerDialogComponent)
    );
  }

  openContribute() {
    return this.lazyOpenDialog(() =>
      import('./contribute-dialog/contribute-dialog.component').then(
        m => m.ContributeDialogComponent
      )
    );
  }

  openDownloadWorkspace() {
    return this.lazyOpenDialog(() =>
      import(
        './download-workspace-dialog/download-workspace-dialog.component'
      ).then(m => m.DownloadWorkspaceDialogComponent)
    );
  }

  openUpgradeWorkspace() {
    return this.lazyOpenDialog(() =>
      import('./upgrade-dialog/upgrade-dialog.component').then(
        m => m.UpgradeDialogComponent
      )
    );
  }

  openUpgradePlan(data: FormConfig & { offer: boolean }) {
    return this.lazyOpenDialog(
      () =>
        import('./upgrade-plan-dialog/upgrade-plan-dialog.component').then(
          m => m.UpgradePlanDialogComponent
        ),
      data
    );
  }

  openCreateNamespace() {
    return this.lazyOpenDialog(() =>
      import(
        './create-namespace-dialog/create-namespace-dialog.component'
      ).then(m => m.CreateNamespaceDialogComponent)
    );
  }

  openProjectLicense(data: IProjectLicenseDialogData) {
    return this.lazyOpenDialog(
      () =>
        import(
          './project-licensing-terms-dialog/project-licensing-terms-dialog.component'
        ).then(m => m.ProjectLicensingTermsDialogComponent),
      data
    );
  }

  openConfirmation(data: ConfirmationData, disableClose = false) {
    return this.lazyOpenDialog(
      () =>
        import('./confirm-dialog/confirm-dialog.component').then(
          m => m.ConfirmDialogComponent
        ),
      data
    ).pipe(
      tap(dialogRef => {
        dialogRef.disableClose = disableClose;
      })
    );
  }
}
