import { FormConfig, HBSPT_CONFIG } from '@configs';
import { PackagePlanDetailsDto } from '@features/auth/login';
import { futureDate } from '@utils/dates-utils';

export const getFormConfig = (
  packagePlanDetails: PackagePlanDetailsDto | null,
  config = HBSPT_CONFIG // This is here so we can override the config in our tests
): FormConfig & { offer: boolean } => {
  const standard = { formId: config.standardFormId, offer: false };
  const offer =
    config.offer?.[
      String(packagePlanDetails?.packagePlan + '-' + packagePlanDetails?.trial)
    ];

  if (!futureDate(offer?.endDate)) {
    return standard;
  }

  return { ...offer, offer: true };
};
