<fa-icon icon="check-circle" size="2xl" class="theme-color-success" />
<h2 class="font-weight-bold">No changes to show</h2>
@if (summaries$ | async; as summaries) {
  <p class="summary-wrapper theme-bg-light mb-0">
    <fa-icon icon="table-columns" class="mr-8" />
    <span class="mr-24">Hidden fields contain</span>
    @for (summary of summaries; track summary; let last = $last) {
      <span [class.mr-8]="!last">
        <fa-icon
          [icon]="summary.icon"
          class="mr-2"
          [class]="summary.className"
        />
        {{ summary.count }}
      </span>
    }
  </p>
}
