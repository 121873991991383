<div [appRosettaOverlayTarget]="overlay">
  @if (modelIds.length > 0) {
    <app-project-cell [modelIds]="modelIds" size="xs" />
  } @else {
    <small class="no-project-text mb-0 color-50"
      >Click to assign projects</small
    >
  }
  <fa-icon icon="chevron-down" />
</div>

<app-rosetta-overlay #overlay scrollStrategy="block">
  <app-rosetta-menu maxHeight="300">
    <mat-selection-list
      dense
      [ngModel]="modelIds"
      (ngModelChange)="setModelIds($event)"
      [compareWith]="compareWithFunc"
    >
      @for (item of projects$ | async | keyvalue; track item) {
        <mat-list-option [value]="item.value">
          <app-project-row [model]="item.value" showAccepted="false" />
        </mat-list-option>
      }
    </mat-selection-list>
  </app-rosetta-menu>
</app-rosetta-overlay>
