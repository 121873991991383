@if (actionIcon) {
  <button
    class="mr-16"
    mat-icon-button
    [matTooltip]="tooltip"
    (click)="action.emit()"
  >
    <fa-icon [icon]="actionIcon" />
  </button>
}
<div class="content" appStopPropagation>
  <ng-content />
</div>
