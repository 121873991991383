import { AppThemes } from '@models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as appReducer from '../reducers/app.reducer';

const selectAppFeature = createFeatureSelector<appReducer.State>(
  appReducer.featureKey
);

const selectAppVersion = createSelector(
  selectAppFeature,
  state => state.version
);

const selectAppTheme = createSelector(
  selectAppFeature,
  state => state.theme || AppThemes.LIGHT
);

const selectHideProprietaryModels = createSelector(
  selectAppFeature,
  state => state.hideProprietaryModels
);

const isDarkTheme = createSelector(
  selectAppTheme,
  theme => theme === AppThemes.DARK
);

const isMonacoReady = createSelector(
  selectAppFeature,
  state => state.monacoReady
);

const newReleaseNotes = createSelector(
  selectAppFeature,
  state => state.newReleaseNotes
);

const refreshRequired = createSelector(
  selectAppFeature,
  state => state.refreshRequired
);

export const AppSelectors = {
  isDarkTheme,
  isMonacoReady,
  newReleaseNotes,
  refreshRequired,
  selectAppFeature,
  selectAppTheme,
  selectAppVersion,
  selectHideProprietaryModels,
};
