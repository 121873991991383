import { deepEquals } from '../utils/object-utils';
import { removeTrailingNullsAndUndefined } from '../utils/array-utils';
import { Cacheable, globalCacheBusterNotifier } from 'ts-cacheable';

/*
HACK: This is a hack to set the global cache name as using the "GlobalCacheConfig" sets it too late
NOTE: LocalStorage storage limit around ~5MB, we might need to increase this limit by creating our own
storage strategy which uses IndexedDB
*/
/*class RosettaStorageStrategy extends LocalStorageStrategy {
  constructor() {
    super();
    this['masterCacheKey'] = StorageKey.workspacePrefix + ':api-cache';
  }
}
*/
export const ApiCacheConfig: Parameters<typeof Cacheable>[0] = {
  //storageStrategy: RosettaStorageStrategy,
  maxCacheCount: 50,
  cacheResolver: deepEquals,
  cacheHasher: removeTrailingNullsAndUndefined,
};

export const globalApiCacheClear = (): void => {
  globalCacheBusterNotifier.next();
};
