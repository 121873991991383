import { Injectable } from '@angular/core';
import { DomainModelApiService } from '@core/services';
import { ReleaseNote, ReleaseNoteMapper } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { Subject, map, zip } from 'rxjs';
import { ReleaseNotesApiService } from './release-notes-api.service';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNoteDialogService {
  constructor(
    private _domainModelApiService: DomainModelApiService,
    private _releaseNotesApiService: ReleaseNotesApiService
  ) {}

  releaseNotes$ = new Subject<ReleaseNote[]>();

  getModelReleaseNotes(modelId: ModelInstanceId): void {
    this._domainModelApiService
      .getAllModelReleaseNotes(modelId)
      .subscribe(releaseNotes => {
        this.releaseNotes$.next(releaseNotes);
      });
  }

  getCoreReleaseNotes(): void {
    zip(
      this._releaseNotesApiService.getReleaseNotes(),
      this._releaseNotesApiService.getLastSeenVersion()
    )
      .pipe(
        map(([notes, lastSeenVersion]) =>
          notes.map(ReleaseNoteMapper(lastSeenVersion))
        )
      )
      .subscribe(releaseNotes => {
        this.releaseNotes$.next(releaseNotes);
      });
  }
}
