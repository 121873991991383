import { APP_DOCUMENTS, RosettaConfig } from '@configs';
import { PackagePlanDetailsDto, UserLoginResponse } from '@features/auth/login';
import { IPageState, LoadingState, UserDocumentMap } from '@models';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as actions from '../actions/auth.actions';
import {
  getUserInitials,
  setPackagePlanDetails,
  setUserProjectLimit,
} from './auth.reducer.helper';

export const featureKey = 'auth';

export interface State {
  loginState: IPageState;
  registerState: IPageState;
  documentState: IPageState;
  documentAcceptState: IPageState;
  user: UserLoginResponse | null;
  userPackagePlanDetails: PackagePlanDetailsDto | null;
  userProjectLimit: number;
  ownedProjects: string[] | null;
  userInitials: string | null;
  userDocuments: UserDocumentMap;
}

export const initialState: Readonly<State> = {
  user: null,
  userPackagePlanDetails: null,
  userProjectLimit: 0,
  ownedProjects: null,
  userInitials: null,
  userDocuments: {},
  documentState: { state: LoadingState.INITIAL, errorMessage: null },
  loginState: { state: LoadingState.INITIAL, errorMessage: null },
  registerState: { state: LoadingState.INITIAL, errorMessage: null },
  documentAcceptState: { state: LoadingState.INITIAL, errorMessage: null },
};

export const reducer = createReducer(
  initialState,
  on(
    actions.login,
    actions.autoLogin,
    actions.loginWithToken,
    actions.refreshUser,
    state =>
      produce(state, draft => {
        draft.loginState.state = LoadingState.LOADING;
        draft.loginState.errorMessage = null;
      })
  ),
  on(
    actions.loginSuccess,
    actions.saveUpdatedPasswordSuccess,
    (state, { user }) =>
      produce(state, draft => {
        draft.loginState.state = LoadingState.LOADED;
        draft.user = user;
        draft.userPackagePlanDetails = setPackagePlanDetails(user);
        draft.userProjectLimit = setUserProjectLimit(
          user.rosettaAuth?.rosettaUser.userPermissions.packagePlan
        );
        draft.userInitials =
          (user && getUserInitials(user?.rosettaAuth?.rosettaUser.email)) ||
          null;
        draft.userDocuments =
          user?.rosettaAuth?.rosettaUser.additionalData || {};
      })
  ),
  on(actions.loginError, (state, { message }) =>
    produce(state, draft => {
      draft.loginState.state = LoadingState.INITIAL;
      draft.loginState.errorMessage = message;
      draft.user = null;
      draft.userInitials = null;
      draft.userPackagePlanDetails = null;
      draft.userProjectLimit = 0;
    })
  ),

  on(actions.register, state =>
    produce(state, draft => {
      draft.registerState.state = LoadingState.LOADING;
      draft.registerState.errorMessage = null;
    })
  ),
  on(actions.registerSuccess, state =>
    produce(state, draft => {
      draft.registerState.state = LoadingState.LOADED;
      draft.registerState.errorMessage = null;
    })
  ),
  on(actions.registerFailure, (state, { registerUserResponse }) =>
    produce(state, draft => {
      draft.registerState.state = LoadingState.INITIAL;
      draft.registerState.errorMessage = registerUserResponse.message || null;
    })
  ),
  on(actions.registerReset, state =>
    produce(state, draft => {
      draft.registerState.state = LoadingState.INITIAL;
      draft.registerState.errorMessage = null;
    })
  ),

  on(actions.checkInitNavigation, state =>
    produce(state, draft => {
      draft.documentState.state = LoadingState.LOADING;
    })
  ),
  on(actions.checkInitNavigationSuccess, state =>
    produce(state, draft => {
      draft.documentState.state = LoadingState.LOADED;
    })
  ),

  on(actions.loadOwnedModelsSuccess, (state, { modelIds }) => ({
    ...state,
    ownedProjects: modelIds,
  })),

  on(actions.acceptedUpdatedAppDocuments, state =>
    produce(state, draft => {
      draft.documentAcceptState.state = LoadingState.LOADING;
      draft.documentAcceptState.errorMessage = null;
    })
  ),
  on(actions.acceptedUpdatedAppDocumentsSuccess, state =>
    produce(state, draft => {
      draft.documentAcceptState.state = LoadingState.LOADED;
      RosettaConfig.appDocuments.forEach(doc => {
        if (!draft.userDocuments[APP_DOCUMENTS]) {
          draft.userDocuments[APP_DOCUMENTS] = {};
        }
        draft.userDocuments[APP_DOCUMENTS][doc] = true;
      });
    })
  ),
  on(actions.acceptedUpdatedAppDocumentsFailure, (state, { errorMessage }) =>
    produce(state, draft => {
      draft.documentAcceptState.state = LoadingState.INITIAL;
      draft.documentAcceptState.errorMessage = errorMessage;
    })
  ),
  on(actions.acceptModelDocumentsSuccess, (state, { documents }) =>
    produce(state, draft => {
      documents.forEach(doc => {
        if (!draft.userDocuments[doc.modelId]) {
          draft.userDocuments[doc.modelId] = {};
        }
        draft.userDocuments[doc.modelId][doc.documentId] = true;
      });
    })
  ),

  on(actions.cleanupSessionState, _ => initialState)
);
