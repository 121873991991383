import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { COLOR_DARK, COLOR_WHITE } from '@configs';
import { HEX } from '@models';
import { contrast } from '@utils/string-utils';

const LIGHT_GREY: HEX = '#808080';

@Component({
  selector: 'app-chip-label',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '{{ value() }}',
  styles: [
    `
      :host {
        display: inline;
        padding: 0 1rem;
        border-radius: 0.75rem;
        vertical-align: middle;

        font: 0.75rem;
        line-height: 1.125rem;
        font-weight: bold;
      }
    `,
  ],
  host: {
    class: 'chip-label',
    '[style.background]': 'color()',
    '[style.color]': 'colorStyle()',
  },
})
export class ChipLabelComponent {
  value = input.required<string | number>();
  color = input<HEX>(LIGHT_GREY);
  useLightForeground = input<boolean | undefined>(undefined);

  colorStyle = computed(() => {
    if (typeof this.useLightForeground() === 'boolean') {
      return this.useLightForeground() ? COLOR_WHITE : COLOR_DARK;
    }
    return contrast(this.color());
  });
}
