import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-text',
  template: `<h3>{{ text() }}</h3>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent {
  text = input<string>();
}
