import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import {
  IDomainModelDetails,
  IDomainModelResponse,
  ReleaseNote,
  WorkspaceGroup,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@store/selectors';
import {
  domainModelMapper,
  mapToWorkspaceGroups,
} from '@store/workspace/effects/workspace.effects.helper';
import { first, map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomainModelApiService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getAll(): Observable<IDomainModelDetails[]> {
    return this._store.select(AuthSelectors.selectUserDocuments).pipe(
      first(),
      switchMap(userDocuments =>
        this._http
          .get<IDomainModelResponse[]>(`${this._config.resourcePaths.models}`)
          .pipe(
            map(domainModels => domainModelMapper(domainModels, userDocuments))
          )
      )
    );
  }

  getOwned(): Observable<string[]> {
    return this._http.get<string[]>(
      `${this._config.resourcePaths.models}/owned`
    );
  }

  getProjects(): Observable<WorkspaceGroup[]> {
    return this._http
      .get<IDomainModelResponse[]>(this._config.resourcePaths.models)
      .pipe(map(domainModels => mapToWorkspaceGroups(domainModels)));
  }

  getAppDocument(documentName: string): Observable<string> {
    return this._http
      .get<{
        payload: string;
      }>(`${this._config.resourcePaths.userServer}/document/${documentName}`)
      .pipe(map(data => data.payload));
  }

  getModelDocument(
    modelId: ModelInstanceId,
    documentName: string
  ): Observable<string> {
    return this._http
      .get<{
        payload: string;
      }>(
        `${this._config.resourcePaths.models}/${modelId}/document/${documentName}`
      )
      .pipe(map(data => data.payload));
  }

  getAllModelReleaseNotes(modelId: ModelInstanceId): Observable<ReleaseNote[]> {
    return this._getModelLatestVersion(modelId).pipe(
      switchMap(version => this._getModelReleaseNotes(modelId, version))
    );
  }

  // TODO: Handle use case where version is undefined
  private _getModelReleaseNotes(
    modelId: ModelInstanceId,
    version?: string
  ): Observable<ReleaseNote[]> {
    return this._http.get<ReleaseNote[]>(
      `${this._config.resourcePaths.models}/releases/notes/${modelId}/${version}`
    );
  }

  private _getModelLatestVersion(
    modelId: ModelInstanceId
  ): Observable<string | undefined> {
    return this._getModelVersions(modelId).pipe(
      map(versions => versions.pop())
    );
  }

  private _getModelVersions(modelId: ModelInstanceId): Observable<string[]> {
    return this._http.get<string[]>(
      `${this._config.resourcePaths.models}/${modelId}/versions`
    );
  }
}
