import { Injectable } from '@angular/core';
import { ALL_MODELS } from '@configs';
import { SettingsDialogService } from '@features/settings/dialog/settings-dialog.service';
import { SettingsApiService } from '@features/settings/services/settings-api.service';
import { SettingsActions } from '@features/settings/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppActions, AuthActions } from '@store/.';
import { saveAs } from 'file-saver';
import { catchError, concatMap, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class SettingsEffects {
  constructor(
    private _actions$: Actions,
    private _api: SettingsApiService,
    private _dialog: SettingsDialogService
  ) {}

  saveUserProfile$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(SettingsActions.saveUserProfile),
      switchMap(({ userRegistration }) =>
        this._api.updateUserProfile(userRegistration).pipe(
          map(() => SettingsActions.saveUserProfileSuccess()),
          catchError(message =>
            of(
              SettingsActions.saveUserProfileError({
                message,
              })
            )
          )
        )
      )
    );
  });

  openUpdatePasswordDialog$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(SettingsActions.openUpdatePasswordDialog),
        switchMap(() => this._dialog.openUpdatePassword())
      );
    },
    {
      dispatch: false,
    }
  );

  downloadModelUsers$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(SettingsActions.downloadModelUsers),
      concatMap(({ modelId }) => {
        const key = modelId || ALL_MODELS;
        return this._api.downloadModelUsers(key).pipe(
          tap(data => saveAs(data, `users.csv`)),
          map(() =>
            SettingsActions.downloadModelUsersSuccess({ modelId: key })
          ),
          catchError(() => {
            const message = 'An error occurred while downloading';
            return of(
              AppActions.showBasicErrorMsg({
                message,
              }),
              SettingsActions.downloadModelUsersError({
                modelId: key,
                message,
              })
            );
          })
        );
      })
    );
  });

  cleanupSessionState$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.cleanupSessionState),
      map(() => SettingsActions.cleanupSessionState())
    );
  });
}
