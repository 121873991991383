import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
  signal,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';

@Component({
  standalone: true,
  selector: 'app-rosetta-file-drop',
  imports: [FontsModule],
  templateUrl: './rosetta-file-drop.component.html',
  styleUrls: ['./rosetta-file-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaFileDropComponent {
  @Input({ transform: booleanAttribute })
  disabled = false;

  @Output()
  filesDropped = new EventEmitter<FileList>();

  private _dragCounter = 0;
  isDragging = signal(false);

  onDragEnter(event: DragEvent): void {
    if (this.disabled) return;

    this._preventAndStop(event);
    this._dragCounter++;
    this.isDragging.set(true);
  }

  onDragOver(event: DragEvent): void {
    if (this.disabled) return;

    this._preventAndStop(event);

    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
    }
  }

  onDragLeave(event: DragEvent): void {
    if (this.disabled) return;

    this._preventAndStop(event);

    this._dragCounter--;
    if (this._dragCounter === 0) {
      this.isDragging.set(false);
    }
  }

  onDrop(event: DragEvent): void {
    if (this.disabled) return;

    this._preventAndStop(event);
    this._dragCounter = 0;
    this.isDragging.set(false);

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.filesDropped.emit(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  }

  private _preventAndStop(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
