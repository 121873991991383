import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FlatTreeControl } from '@angular/cdk/tree';
import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
  MatTreeModule,
} from '@angular/material/tree';
import { FontsModule } from '@app/fonts/fonts.module';
import { CodeViewDTO } from '@models/dto';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { RosettaMenuComponent } from '@shared/components/rosetta-menu.component';
import {
  PersistExpandStateDirective,
  ScrollPositionDirective,
  StopPropagationDirective,
} from '@shared/directives';
import * as AppAction from '@store/actions';
import { addUnique } from '@utils/array-utils';
import { filterNotNulls } from '@utils/operators';
import { isEmptyString } from '@utils/string-utils';
import { saveAs } from 'file-saver';
import { NgScrollbarModule } from 'ngx-scrollbar';
import objectHash from 'object-hash';
import { BehaviorSubject, Subject, Subscription, map } from 'rxjs';
import {
  RosettaCodeEditorComponent,
  RosettaEditorOptions,
} from '../../components/rosetta-code-editor/rosetta-code-editor.component';
import { RosettaOverlayModule } from '../rosetta-overlay/rosetta-overlay.module';
import { CodeViewSearchComponent } from './code-view-search/code-view-search.component';
import { CodeViewSearchService } from './code-view-search/code-view-search.service';
import { CodeViewSelectedComponent } from './components/code-view-selected/code-view-selected.component';
import {
  CodeView,
  CodeViewNode,
  FlatCodeViewNode,
  NodeNameType,
} from './models/code-view.model';

@Component({
  selector: 'app-code-view',
  standalone: true,
  imports: [
    AsyncPipe,
    CodeViewSearchComponent,
    CodeViewSelectedComponent,
    FontsModule,
    LetDirective,
    MatButtonModule,
    MatTreeModule,
    NgScrollbarModule,
    PersistExpandStateDirective,
    RosettaCodeEditorComponent,
    RosettaMenuComponent,
    RosettaOverlayModule,
    ScrollPositionDirective,
    StopPropagationDirective,
  ],
  templateUrl: './code-view.component.html',
  styleUrls: ['./code-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CodeViewSearchService],
  host: {
    class: 'code-view',
  },
})
export class CodeViewComponent implements OnDestroy, OnChanges, AfterViewInit {
  constructor(
    private _searchService: CodeViewSearchService,
    private _cdr: ChangeDetectorRef,
    private _store: Store
  ) {}

  @ViewChildren('nodeId')
  private _nodeIds!: QueryList<ElementRef>;

  @ViewChild(ScrollPositionDirective)
  private _scrollPosition!: ScrollPositionDirective;

  @ViewChild(PersistExpandStateDirective)
  private _expandState!: PersistExpandStateDirective;

  @Input() name = '';
  @Input() codeView: CodeViewDTO | null = null;
  @Input() showSelectedCodeView = true;
  @Input() language: 'xml' | 'json' = 'json';
  @Input() generateId = true;
  @Input() modelContent = false;
  @Input()
  set showNative(value: boolean) {
    this._isShowNative = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }
  get showNative(): boolean {
    return this.isReady ? this._isShowNative : true;
  }
  get hideNative(): boolean {
    return this.isReady ? !this._isShowNative : true;
  }
  private _isShowNative = false;

  @Output()
  clickLinkId = new EventEmitter<string>();

  selectedNodeId$ = new Subject<string | null>();
  selectedNode$ = this.selectedNodeId$.pipe(
    filterNotNulls(),
    map(nodeId => this._getNode(nodeId)),
    map(node => {
      if (!node?.parent) {
        const nodePath = this._createNodePath(node?.id);
        return nodePath && { ...node, path: nodePath };
      }
      const path = this._createNodePath(node?.id);
      const types: string[] = ['', ...path.map(p => p.type)];
      path.forEach(p => {
        p.parentType = types[path.indexOf(p)];
      });
      return path && { ...node, path };
    })
  );
  isReady = false;
  codeView$ = new BehaviorSubject(this.codeView);

  readonly editorOptions: monaco.editor.IStandaloneEditorConstructionOptions = {
    readOnly: true,
  };
  readonly options: RosettaEditorOptions = {
    disposeModelOnClose: true,
  };

  private _sub = new Subscription();

  private _getLinkId = (node: CodeViewNode): string | undefined => {
    const mapped = node.annotations.find(a => a.name === 'mapped');
    return mapped?.linkId?.toString();
  };

  private _getErrors = (node: CodeViewNode): string[] => {
    const annotations = node.annotations;
    const errorList: string[] = [];
    if (annotations) {
      annotations.forEach(a =>
        a.errors.forEach(e => {
          const splitErrors = e.split(';');
          errorList.push(...splitErrors);
        })
      );
    }
    return errorList;
  };

  private _setParents = (node: CodeViewNode): void => {
    node.children.forEach(n => {
      n.parent = node.id;
      if (n.children && n.children.length > 0) {
        this._setParents(n);
      }
    });
  };

  private _treeTransform = (
    node: CodeViewNode,
    level: number
  ): FlatCodeViewNode => {
    const searchTerm = (node?.name || '') + (node?.value || '');
    const id = this._getId(node);

    this._searchService.add(searchTerm, id);
    return {
      expandable: !!node.children && node.children.length > 0,
      level,
      id,
      name: node.name,
      linkId: this._getLinkId(node),
      errors: this._getErrors(node),
      nodeClass: this._getClasses(node),
      childClass: this._getClasses(node, true),
      reference: node.reference,
      value: node.value,
      parent: node.parent ? node.parent.toString() : undefined,
    };
  };

  treeFlattener = new MatTreeFlattener<CodeViewNode, FlatCodeViewNode, number>(
    this._treeTransform,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  treeControl = new FlatTreeControl<FlatCodeViewNode, number>(
    node => node.level,
    node => node.expandable
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngAfterViewInit(): void {
    this._scrollPosition.applySavedScroll();
  }

  ngOnChanges({ codeView }: SimpleChanges): void {
    this.isReady = !!codeView?.currentValue;

    if (codeView?.currentValue?.codeViewNode) {
      this.showNative = true;
      this._setupCodeView(codeView.currentValue);
    } else {
      this._clearTree();
    }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  hasChild(_: number, node: FlatCodeViewNode): boolean {
    return node.expandable;
  }

  onLinkId(node: FlatCodeViewNode): void {
    this.selectedNodeId$.next(node.id);
    this.clickLinkId.emit(node.linkId);
  }

  scrollTo(linkId: string): void {
    this.selectedNodeId$.next(linkId);
    this._isShowNative = true;

    const targetNode = this._getNode(linkId);

    if (!targetNode) {
      this._store.dispatch(
        AppAction.showBasicErrorMsg({ message: 'Unable to find mapping!' })
      );
      return;
    }

    this._expandAscendant(linkId);

    this._nodeIds.forEach(element => {
      if (element.nativeElement.id === linkId) {
        element.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    });

    this._cdr.markForCheck();
  }

  selectNode(nodeId: string): void {
    this.selectedNodeId$.next(nodeId);
  }

  downloadNativeCode(): void {
    const filename =
      this.name?.replace(/ /g, '-').replace(/\.xml|\.json/g, '') +
      '.' +
      this.language;
    const text = this.codeView ? [this.codeView.nativeCode] : undefined;

    const blob = new Blob(text, { type: 'text/plain;charset=utf-8' });
    saveAs(blob, filename);
  }

  private _getId(node: CodeViewNode): string {
    if (this.generateId || !node?.id) {
      return objectHash(this.codeView, { encoding: 'base64' });
    }
    return node.id.toString();
  }

  private _getNode(nodeId?: string): FlatCodeViewNode | undefined {
    if (isEmptyString(nodeId)) {
      return undefined;
    }
    return this.treeControl.dataNodes.find(({ id }) => id === nodeId);
  }

  private _createNodePath(linkId?: string): NodeNameType[] {
    const node = this._getNode(linkId);
    const type = node.reference?.name || node.name;

    if (!node?.parent) {
      return [{ name: node.name, type }];
    }

    return [...this._createNodePath(node.parent), { name: node.name, type }];
  }

  private _getClasses(node: CodeViewNode, forChildren?: boolean): string[] {
    let cssClasses: string[] = [];
    const annotations = node.annotations;

    if (forChildren) {
      cssClasses = this._crawlNodeTree(node);
    } else if (annotations) {
      cssClasses = annotations.map(a => a.name);
    }

    return addUnique(cssClasses);
  }

  private _crawlNodeTree(node: CodeViewNode): string[] {
    const nodeStack: CodeViewNode[] = [node];
    const cssClasses: string[] = [];

    while (nodeStack.length > 0) {
      const currentNode = nodeStack.pop();

      if (currentNode?.annotations && currentNode.annotations.length > 0) {
        cssClasses.push(...currentNode.annotations.map(a => a.name));
      }

      if (currentNode?.children && currentNode.children.length > 0) {
        nodeStack.push(...currentNode.children);
      }
    }

    return cssClasses;
  }

  private _expandAscendant(linkId: string): void {
    const node = this._getNode(linkId);

    if (node && !this.treeControl.isExpanded(node)) {
      this.treeControl.expand(node);

      if (node.parent) {
        this._expandAscendant(node.parent);
      }
    }
  }

  private _setupCodeView(codeViewData: CodeView): void {
    this.selectedNodeId$.next(null);
    const codeViewNode = codeViewData.codeViewNode;

    if (codeViewNode) {
      this._setParents(codeViewNode);
      this.dataSource.data = [codeViewNode];
      this._expandState?.resetState() || this._expandRootNode();
    }

    this._cdr.markForCheck();
    this._scrollPosition?.applySavedScroll();
  }

  private _expandRootNode(): void {
    this.treeControl.expand(this.treeControl.dataNodes[0]);
  }

  private _clearTree(): void {
    if (this.dataSource) {
      this.dataSource.data = [];
      this._scrollPosition?.clear();
    }
  }
}
