import { EnvironmentProviders, Provider } from '@angular/core';
import { provideRollbar } from '@core/modules/rollbar/rollbar.module';
import { Environment } from '@models';

export const provideEnvironmentProviders = (): (
  | EnvironmentProviders
  | Provider
)[] => [provideRollbar()];

/*
Used for RC and Prod environments
*/
export const environment: Environment = {
  production: true,
  intercomConfig: {
    appId: 'ka2x4boq',
    enabled: true,
  },
  ga: 'G-BHX42Z7TQM',
};
