import {
  ChangeDetectionStrategy,
  Component,
  booleanAttribute,
  input,
  numberAttribute,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontsModule } from '@app/fonts/fonts.module';
import { InputValueDirective } from '@shared/directives';

@Component({
  standalone: true,
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FontsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    InputValueDirective,
  ],
})
export class TextInputComponent {
  value = input<string>('');
  label = input<string>('Filter');
  maxWidth = input<number, unknown>(null, { transform: numberAttribute });
  disabled = input<boolean, unknown>(false, {
    transform: booleanAttribute,
  });

  valueChange = output<string>();

  onValueChange(value = ''): void {
    this.valueChange.emit(this._normaliseString(value));
  }

  private _normaliseString(value: string): string {
    return value
      .normalize('NFC')
      .replace(/[^0-9a-zA-Z.,?@!#$%^&*()\-_+=\[\]{}:;'"<>\\/|`~ ]/g, '');
  }
}
