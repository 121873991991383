import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { LanguageServerService } from '@core/services/language-server.service';
import { LoadingBannerComponent } from '@features/workspace/components/loading-banner/loading-banner.component';
import { RosettaPanelComponent } from '@features/workspace/components/rosetta-panel/rosetta-panel.component';
import { WorkspaceContentComponent } from '@features/workspace/components/workspace-content/workspace-content.component';
import { WorkspaceFooterNavComponent } from '@features/workspace/components/workspace-footer-nav/workspace-footer-nav.component';
import { WorkspaceHeaderComponent } from '@features/workspace/components/workspace-header/workspace-header.component';
import { IntegrateIntercomDirective } from '@features/workspace/directives/integrate-intercom.directive';
import { editorTourSteps } from '@features/workspace/editor.tour';
import { regulationTourSteps } from '@features/workspace/regulation.tour';
import { SupportedFeatureService } from '@features/workspace/services/supported-feature.service';
import { QueryParams } from '@models';
import { WA_WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { InOutAnimation } from '@shared';
import { HeartbeatDirective, SplitSaveSizeDirective } from '@shared/directives';
import { RosettaTourModule } from '@shared/modules';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { AngularSplitModule } from 'angular-split';
import { Subscription, map, startWith } from 'rxjs';

@Component({
  selector: 'app-workspace-container',
  standalone: true,
  imports: [
    AngularSplitModule,
    AsyncPipe,
    HeartbeatDirective,
    IntegrateIntercomDirective,
    LoadingBannerComponent,
    RosettaPanelComponent,
    RosettaTourModule,
    RouterOutlet,
    SplitSaveSizeDirective,
    WorkspaceContentComponent,
    WorkspaceFooterNavComponent,
    WorkspaceHeaderComponent,
  ],
  templateUrl: './workspace-container.component.html',
  styleUrls: ['./workspace-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation],
})
export class WorkspaceContainerComponent implements OnDestroy {
  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _languageServerService: LanguageServerService,
    private _supportFeatureService: SupportedFeatureService,
    @Inject(WA_WINDOW) private _window: Window
  ) {}

  private _routerParams$ = this._route.queryParamMap;
  private _sub = new Subscription();

  isLoading$ = this._store.select(WorkspaceSelectors.isConnectionNotReady);
  topPanel$ = this._routerParams$.pipe(
    map(paramMap => coerceBooleanProperty(paramMap.get(QueryParams.TOP_PANEL))),
    startWith(true)
  );
  bottomPanel$ = this._routerParams$.pipe(
    map(paramMap =>
      coerceBooleanProperty(paramMap.get(QueryParams.BOTTOM_PANEL))
    ),
    startWith(false)
  );

  tourSteps$ = this._supportFeatureService
    .checkSupportFor('regulation')
    .pipe(
      map(showTour => [
        ...(showTour ? regulationTourSteps : []),
        ...editorTourSteps,
      ])
    );

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    this._languageServerService.closeSocket();
  }

  fireSplitUpdateEvent(): void {
    this._window.dispatchEvent(new Event('resize'));
  }
}
