@if (canHide || canReorder) {
  <button
    mat-stroked-button
    *ngrxLet="numberHiddenColumns() as numberHiddenColumns"
    [disabled]="disabled || editableCols().length === 0"
    [appRosettaOverlayTarget]="columnsPopover"
    [ngClass]="{ 'hidden-column-count theme-bg-primary': numberHiddenColumns }"
    class="button-wrapper"
  >
    <fa-icon
      [ngClass]="{ 'theme-bg-primary': numberHiddenColumns }"
      icon="table-columns"
    />
    <span>Fields</span>
    @if (numberHiddenColumns) {
      <span @slideIn class="ml-16 button-wrapper__label">
        <strong>{{ numberHiddenColumns }}</strong> hidden
      </span>
    }
  </button>
}

<app-rosetta-overlay #columnsPopover>
  <app-filtered-list
    #filterInput
    inputPlaceholder="Find in Fields"
    [data]="editableCols() ?? []"
    [filterFunc]="filterFunc"
    [itemSize]="itemSize()"
    [width]="overlayWidth()"
    [height]="400"
  >
    <ng-template #staticContent>
      <div
        [style.height.px]="itemSize()"
        class="table-column__row theme-bg-lighter-hover"
        [hidden]="filterInput.filterString"
        (click)="masterToggle()"
        matRipple
      >
        <mat-checkbox
          #checkboxAll
          [checked]="isAllSelected()"
          [indeterminate]="isAnySelected()"
        />
        <h4 class="mb-0">All</h4>
      </div>
    </ng-template>
    <ng-template #rowContent let-column>
      <div
        class="table-column__row theme-bg-lighter-hover"
        (click)="checkbox.toggle(); columnToggle(column)"
        matRipple
      >
        <mat-checkbox #checkbox [checked]="!column.hide" />
        <div class="table-column__row-item">
          <h4 class="mb-0">{{ column.headerName }}</h4>
          <!-- description -->
          @if (column?.description) {
            <small
              class="text-ellipsis opacity-50"
              [matTooltip]="column.description"
              matTooltipShowDelay="1000"
              [ngClass]="{
                'text-rtl text-align-left':
                  columnMenuDescriptionDirection === 'rtl',
              }"
              >{{ column.description }}</small
            >
          }
        </div>
      </div>
    </ng-template>
  </app-filtered-list>
</app-rosetta-overlay>
