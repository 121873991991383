@if ({ value: stream$ | async, error: streamError$ | async }; as data) {
  @switch (spinnerOnLoading) {
    @case (true) {
      @if (data.value) {
        <ng-container
          *ngTemplateOutlet="mainTemplate; context: { $implicit: data.value }"
        />
      } @else {
        @if (!data.error) {
          <app-loading-spinner [flexCentre]="true" />
        }
        @if (data.error) {
          <ng-container
            *ngTemplateOutlet="
              errorTemplate;
              context: { $implicit: data.error }
            "
          />
        }
      }
    }
    @case (false) {
      @if (!data.error) {
        <ng-container
          *ngTemplateOutlet="mainTemplate; context: { $implicit: data.value }"
        />
      } @else {
        <ng-container
          *ngTemplateOutlet="errorTemplate; context: { $implicit: data.error }"
        />
      }
      <ng-template #error>
        <ng-container
          *ngTemplateOutlet="errorTemplate; context: { $implicit: data.error }"
        />
      </ng-template>
    }
  }
}
