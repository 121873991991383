import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { TransformStatus } from '../../models';

@Component({
  selector: 'app-transform-status',
  standalone: true,
  imports: [FontsModule],
  template: `
    @switch (status()) {
      @case (sampleRowStatus.Removed) {
        <fa-icon icon="circle-minus" class="theme-color-warn" />
      }
      @case (sampleRowStatus.Added) {
        <fa-icon [icon]="addIcon()" class="theme-color-success" />
      }
      @case (sampleRowStatus.AddedUpstream) {
        <fa-icon icon="arrow-left" class="theme-color-success" />
        <fa-icon [icon]="addIcon()" class="theme-color-success" />
      }
    }
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'inline-block',
  },
})
export class TransformStatusComponent {
  status = input.required<TransformStatus>();
  storedOnClient = input.required<boolean>();

  addIcon = computed(() => (this.storedOnClient() ? 'circle' : 'circle-plus'));

  readonly sampleRowStatus = TransformStatus;
}
