import { NgModule } from '@angular/core';
import { environment } from '@env/environment';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { GaService } from './ga.service';

@NgModule({
  imports: [
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule.forRoot({ exclude: [/.*\/redirect\?.*/] }),
  ],
  providers: [GaService],
})
export class GaModule {}
