<h3>
  <span>{{ heading }}</span>
  <span>
    @for (group of groups(); track group) {
      <app-chip-label
        [value]="group.actual"
        [color]="group.color"
        [useLightForeground]="group.useLightTextColor"
      />
    }
  </span>
</h3>

@for (group of groups(); track group; let idx = $index) {
  @if (groupHasItems(group)) {
    <section [style.--border-color]="group.color">
      <h4 matRipple (click)="closedGroup[idx] = !closedGroup[idx]">
        <span>{{ group.name }}</span>
        <fa-icon
          size="sm"
          [icon]="closedGroup[idx] ? 'chevron-down' : 'chevron-right'"
        />
      </h4>
      <div [@openCloseAnimation]="closedGroup[idx] ? 'open' : 'closed'">
        @for (section of group.sections; track section) {
          <div class="group">
            <h5>{{ section.category }}</h5>
            <ul>
              @for (item of section.items; track item) {
                <li (click)="onClick(item)">
                  <ng-container
                    *ngTemplateOutlet="
                      templates | diagnosticTemplate: group.def;
                      context: { $implicit: item }
                    "
                  />
                </li>
              }
            </ul>
          </div>
        }
      </div>
    </section>
  }
}
