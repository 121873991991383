import { WORKSPACE_READY_TASKS } from '@configs';
import { TaskStatus, TaskStatusKey } from '@models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { addUnique } from '@utils/array-utils';
import * as taskReducer from '../reducers/task.reducer';
import { AuthSelectors } from './auth.selector';

const selectTaskFeature = createFeatureSelector<taskReducer.State>(
  taskReducer.featureKey
);

const selectLastStatusChangeTimestamp = createSelector(
  selectTaskFeature,
  state => state.lastStatusChange
);

const selectTaskStatus = (task: TaskStatusKey) =>
  createSelector(
    selectTaskFeature,
    (state: taskReducer.State) => state.statusMap[task] || TaskStatus.Pending
  );

const selectTaskPayload = (task: TaskStatusKey) =>
  createSelector(
    selectTaskFeature,
    (state: taskReducer.State) => state.taskMap[task] || null
  );

const selectUniqueWorkspaceTaskStatuses = createSelector(
  selectTaskFeature,
  state =>
    addUnique(
      WORKSPACE_READY_TASKS.map(
        task => state.statusMap[task] || TaskStatus.Pending
      )
    )
);

const getWorkspaceAggregateStatus = createSelector(
  AuthSelectors.isCommunityLicense,
  selectUniqueWorkspaceTaskStatuses,
  (isCommunityLicense: boolean, tasks: TaskStatus[]) => {
    if (tasks.some(task => task === TaskStatus.Error)) {
      return TaskStatus.Error;
    }

    if (isCommunityLicense) {
      // "staticCompile" and "pojo" do not run for community licenses
      // so we can set the status to finished
      return TaskStatus.Finished;
    }

    if (tasks.length > 1) {
      // When the task statuses are not the same we can
      // assume something has started or is about to start
      return TaskStatus.Started;
    }

    return tasks[0];
  }
);

const hasErrors = createSelector(
  getWorkspaceAggregateStatus,
  taskStatus => taskStatus === TaskStatus.Error
);

export const TaskSelectors = {
  selectTaskFeature,
  selectLastStatusChangeTimestamp,
  selectTaskStatus,
  selectTaskPayload,
  selectUniqueWorkspaceTaskStatuses,
  getWorkspaceAggregateStatus,
  hasErrors,
};
