import { NgTemplateOutlet } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  QueryList,
  input,
  output,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { ChipLabelComponent } from '@shared/components/chip-label.component';
import { OpenCloseAnimation } from '../../animations/open-close.animation';
import { DiagnosticPanelGroupDefDirective } from '../../directives/diagnostic-panel-def.directive';
import { DiagnosticGroup, DiagnosticItem } from '../../models/diagnostic.model';
import { DiagnosticPanelGroupTemplatePipe } from '../../pipes/diagnostic-panel-template.pipe';

@Component({
  standalone: true,
  imports: [
    ChipLabelComponent,
    DiagnosticPanelGroupTemplatePipe,
    FontsModule,
    MatRippleModule,
    NgTemplateOutlet,
  ],
  selector: 'app-diagnostic-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './diagnostic-panel.component.html',
  styleUrls: [
    '../../diagnostic-panel.theme.scss',
    './diagnostic-panel.component.scss',
  ],
  animations: [OpenCloseAnimation],
  host: {
    class: 'diagnostic-panel',
  },
})
export class DiagnosticPanelComponent {
  constructor(@Attribute('heading') public heading: string) {}

  groups = input.required<DiagnosticGroup[]>();
  clicked = output<DiagnosticItem>();

  @ContentChildren(DiagnosticPanelGroupDefDirective)
  templates!: QueryList<DiagnosticPanelGroupDefDirective>;

  // Keep track of which group is open or closed
  closedGroup: boolean[] = [];

  onClick(panel: DiagnosticItem): void {
    this.clicked.emit(panel);
  }

  groupHasItems(group: DiagnosticGroup): boolean {
    return group.sections.some(section => section.items.length > 0);
  }
}
