<app-rosetta-tour-template />

<ng-container
  *appShowSpinner="transformService.transformReady$ | async; flexCentre: true"
>
  @if (showDataViewer$ | async) {
    <app-transform-data-viewer
      [dataViewerId]="dataViewerId"
      [dataViewerSource$]="transformService.resultSource$"
      [getFileName]="transformService.getFileName"
      [options]="options"
      (rowClicked)="onRowClicked($event)"
      (openUploadDialog)="openAddSampleDialog()"
      (events)="onRowEvent($event)"
      [appRosettaTourStart]="tourSteps"
    >
      <app-transform-data-viewer-selectors />
      <app-run-status
        class="ml-16 mr-16"
        [runResultState]="transformService.runResultState$ | async"
        autoRun="true"
      />
    </app-transform-data-viewer>
  } @else {
    <div class="flex-align-center-center flex-1">
      <fa-icon
        icon="exclamation-circle"
        size="2xl"
        class="theme-color-yellow-alt"
      />
      <h2>
        <strong>{{
          transformService.transformTypeName | rosettaTitleCase
        }}</strong>
        is not available on the current model version
      </h2>
    </div>
  }
</ng-container>
