<ng-template #originalValueTemplate let-value="value" let-label="label">
  @if (value !== null && value !== undefined) {
    <small class="text-ellipsis color-50 block"
      >{{ label }} <strong>{{ value }}</strong></small
    >
  }
</ng-template>

<ng-template
  #iconAndTooltipTemplate
  let-icon="icon"
  let-showIconClass="showIconClass"
  let-title="title"
  let-para="para"
  let-wasValue="wasValue"
  let-originalValue="originalValue"
>
  <fa-icon
    [ngClass]="showIconClass"
    [icon]="icon"
    [appRosettaOverlayHoverTarget]="overlay"
  />
  <app-rosetta-overlay #overlay>
    <app-rosetta-menu maxWidth="200">
      <h4 class="font-weight-semi-bold">{{ title }}</h4>
      <p>{{ para }}</p>
      <ng-container
        *ngTemplateOutlet="
          originalValueTemplate;
          context: { value: wasValue, label: 'Was' }
        "
      />
      <ng-container
        *ngTemplateOutlet="
          originalValueTemplate;
          context: { value: originalValue, label: 'Original' }
        "
      />
    </app-rosetta-menu>
  </app-rosetta-overlay>
</ng-template>

<div class="outer-wrapper">
  @if (cell?.hasChanges) {
    <div class="text-wrapper">
      <p class="text-ellipsis mb-0">{{ cell.value | emptyString }}</p>
      <ng-container
        *ngTemplateOutlet="
          originalValueTemplate;
          context: { value: previousCellValue, label: previousCellLabel }
        "
      />
    </div>
    @switch (cell.cellState) {
      @case (sampleCellState.New) {
        <ng-container
          [ngTemplateOutlet]="iconAndTooltipTemplate"
          [ngTemplateOutletContext]="{
            icon: 'exclamation-circle',
            showIconClass: 'theme-color-yellow',
            title: 'No Expectation',
            para: 'No expectation has been set for this field',
            wasValue: cell.expectedBaseValue,
            originalValue: null,
          }"
        />
      }
      @case (sampleCellState.Diff || sampleCellState.Removed) {
        <ng-container
          [ngTemplateOutlet]="iconAndTooltipTemplate"
          [ngTemplateOutletContext]="{
            icon: 'exclamation-triangle',
            showIconClass: 'theme-color-warn',
            title: 'Expectation mismatch',
            para: 'The generated value doesn\'t match the expectation',
            wasValue: cell.expectedBaseValue,
            originalValue: cell.expectedUpdatedValue,
          }"
        />
      }
    }
  } @else {
    <p class="text-ellipsis mb-0">{{ cell?.value | emptyString }}</p>
  }
</div>
