import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LanguageServerMiddlewareService } from '@core/services/language-server-middleware.service';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { round } from '@utils/number-utils';
import { ConnectionState } from '@workspace-design/textual/models/rosetta-editor.model';
import { combineLatest, map, scan, startWith } from 'rxjs';

@Component({
  selector: 'app-loading-banner',
  standalone: true,
  imports: [AsyncPipe, MatProgressBarModule],
  templateUrl: './loading-banner.component.html',
  styleUrls: ['./loading-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBannerComponent {
  constructor(
    private _store: Store,
    private _languageServerMiddlewareService: LanguageServerMiddlewareService
  ) {}

  private _diagnosticsWeighting = 80;
  private _connectionWeighting = 100 - this._diagnosticsWeighting;

  diagnosticPercentage$ =
    this._languageServerMiddlewareService.percentage$.pipe(
      map(percent => round(percent * this._diagnosticsWeighting))
    );

  loadingMsg$ = this._store
    .select(WorkspaceSelectors.selectConnectionState)
    .pipe(
      startWith(0),
      map(s => {
        const weight = this._connectionWeighting / 2;
        switch (s) {
          case ConnectionState.Connected:
          case ConnectionState.AwaitingDiagnostics:
            return weight;
          default:
            return 0;
        }
      }),
      scan((acc, one) => {
        const result = acc + (one || 0);
        return result < this._connectionWeighting
          ? result
          : this._connectionWeighting;
      }, 0)
    );

  percentage$ = combineLatest([
    this.diagnosticPercentage$,
    this.loadingMsg$,
  ]).pipe(map(([a, b]) => a + b));
}
