import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { FontsModule } from '@app/fonts/fonts.module';
import { ExternalLink, InternalLink } from '@configs';
import { PackagePlan } from '@models';
import { Store } from '@ngrx/store';
import { ThemeToggleComponent } from '@shared/components/theme-toggle/theme-toggle.component';
import { UpgradePlanButtonComponent } from '@shared/components/upgrade-plan-button.component';
import {
  ClickToOpenUpgradeDialogDirective,
  HasAbilityDirective,
} from '@shared/directives';
import { AuthActions } from '@store/.';
import { AppSelectors, AuthSelectors } from '@store/selectors';
import { contrast } from '@utils/string-utils';
import { map } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    RouterLink,
    FontsModule,
    MatMenuModule,
    MatDividerModule,
    MatButtonModule,
    ThemeToggleComponent,
    UpgradePlanButtonComponent,
    HasAbilityDirective,
    ClickToOpenUpgradeDialogDirective,
  ],
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent {
  userInitials$ = this._store.select(AuthSelectors.selectUserInitials);
  userColor$ = this._store.select(AuthSelectors.generateUserColour);
  textColor$ = this.userColor$.pipe(map(colour => contrast(colour)));
  coreVersion$ = this._store.select(AppSelectors.selectAppVersion);
  showUpgrade$ = this._store
    .select(AuthSelectors.getPackagePlan)
    .pipe(map(packagePlan => packagePlan !== PackagePlan.Enterprise));

  readonly internalLink = InternalLink;
  readonly externalLink = ExternalLink;

  constructor(private _store: Store) {}

  logout(): void {
    this._store.dispatch(AuthActions.logout());
  }
}
