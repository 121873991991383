import { RosettaSelectorBase } from '@shared/components/rosetta-selector/rosetta-selector.base.service';
import { Observable, of } from 'rxjs';

import { TestPack } from '../../models';
import { PipelineDef } from '../../models/pipeline-def.model';
import { ITransformConfig } from '../../models/transform-config.model';
import { TransformStateSyncService } from '../transform-state-sync.service';
import { TransformStorageService } from '../transform-storage.service';

export class TestPackSelector extends RosettaSelectorBase<TestPack> {
  constructor(
    private _transformStorage: TransformStorageService,
    private _transformStateSync: TransformStateSyncService,
    private _transformConfig: ITransformConfig
  ) {
    super(_transformConfig.testPackSelector);
  }

  override getListItemValue = ({ testPackDef }: TestPack): string =>
    testPackDef.id;
  override getListItemLabel = ({ testPackDef }: TestPack): string =>
    testPackDef.name;

  get value(): string {
    return this._transformStateSync.get(
      this._transformConfig.type,
      this._transformConfig.testPackSelector.key
    );
  }
  set value(_value: string) {
    // Value is set by pipeline and stored in the State Sync Service
  }

  protected fetchData(pipelineDef: PipelineDef): Observable<TestPack[]> {
    return pipelineDef
      ? this._transformStorage.getTestPacks(pipelineDef)
      : of([]);
  }
}
