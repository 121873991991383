import { Injectable } from '@angular/core';
import { BaseStorageService } from '@core/services/base-storage.service';
import { deepCopy } from '@utils/object-utils';
import { mapToVoid } from '@utils/operators';
import { addNumericSuffixToMakeUnique } from '@utils/string-utils';
import { uniqBy } from 'lodash-es';
import objectHash from 'object-hash';
import { Observable, concatMap, forkJoin, map, of, switchMap } from 'rxjs';
import { CacheBuster } from 'ts-cacheable';
import {
  JsonSample,
  TestPack,
  TestPackSampleId,
  TransformStatus,
  TransientSample,
} from '../models';
import { runPipelineBuster$ } from '../models/run-pipeline-buster.subject';
import { TestPackGridSelection } from '../models/test-pack-grid-selection.model';
import { TransientTestPack } from '../models/transient-test-pack.model';

export type TransactionSample = TransientSample & {
  pipelineId?: string;
  transactionId?: number;
};

@Injectable()
export class TransformLocalStorageService extends BaseStorageService<TransactionSample> {
  override readonly namespace = 'transform';

  /*
  Find all samples and return all unique testPackDefs
  */
  getTestPacks(pipelineId: string): Observable<TestPack[]> {
    return this.getAll(pipelineId).pipe(
      map(samples =>
        uniqBy(
          samples.map(sample => sample.testPack),
          'testPackDef.id'
        )
      )
    );
  }

  /*
  Find all samples for a pipeline/testPack
   */
  getSamples({
    pipelineDef,
    testPackDef,
  }: TestPackGridSelection): Observable<TransientTestPack | undefined> {
    return this.getAll(
      this._createStorageKey(pipelineDef.id, testPackDef.id)
    ).pipe(
      map(samples => {
        if (samples.length === 0) {
          return undefined;
        }
        return {
          testPackName: testPackDef.name,
          samples: samples.map(({ sample }) => sample),
        };
      })
    );
  }

  /*
  Retrieve a single sample from a pipeline/testPack by its ID
  */
  getSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<TransactionSample | undefined> {
    return this.get(
      this._createStorageKey(pipelineDef.id, testPackDef.id, sampleId)
    ).pipe(
      map(sample => (sample ? { ...sample, storedOnClient: true } : undefined))
    );
  }

  /*
  Add a single sample to pipeline/testPack
   */
  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  addSample(
    selection: TestPackGridSelection,
    sampleJson: JsonSample,
    transactionId?: number
  ): Observable<TestPackSampleId> {
    const { pipelineDef, testPackDef } = selection;
    return this._uniqueSampleJson(selection, sampleJson).pipe(
      switchMap(uniqueSampleJson =>
        this._set(
          this._createStorageKey(
            pipelineDef.id,
            testPackDef.id,
            uniqueSampleJson.sampleDef.id
          ),
          {
            pipelineId: pipelineDef.id,
            storedOnClient: true,
            testPack: {
              testPackDef,
              status: TransformStatus.Added,
              storedOnClient: true,
            },
            sample: uniqueSampleJson,
            transactionId,
          }
        ).pipe(
          map(() => ({
            selection,
            sampleDef: uniqueSampleJson.sampleDef,
          }))
        )
      )
    );
  }

  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  updateSample(
    selection: TestPackGridSelection,
    transientSample: TransientSample
  ): Observable<void> {
    const { pipelineDef, testPackDef } = selection;
    const sampleId = transientSample.sample.sampleDef.id;

    return this.getSample(selection, sampleId).pipe(
      switchMap(localSample => {
        const updatedSample = {
          ...localSample,
          sample: {
            ...localSample.sample,
            json: transientSample.sample.json,
          },
        };

        return this._set(
          this._createStorageKey(pipelineDef.id, testPackDef.id, sampleId),
          updatedSample
        );
      }),
      mapToVoid()
    );
  }

  /*
  Delete single sample from pipeline/testPack
  */
  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  deleteSample(
    { pipelineDef, testPackDef }: TestPackGridSelection,
    sampleId: string
  ): Observable<void> {
    return this.delete(
      this._createStorageKey(pipelineDef.id, testPackDef.id, sampleId)
    );
  }

  deleteDownstreamSamples(
    selection: TestPackGridSelection,
    sampleId: string
  ): Observable<void> {
    return this.getSample(selection, sampleId).pipe(
      switchMap(sample => this._getTransactionGroup(sample?.transactionId)),
      switchMap(samples => {
        if (!samples) {
          return of(undefined);
        }
        return forkJoin(
          samples.map(({ sample }) => this.delete(sample.sampleDef.id))
        );
      }),
      mapToVoid()
    );
  }

  @CacheBuster({
    cacheBusterNotifier: runPipelineBuster$,
  })
  deleteTestPack({
    pipelineDef,
    testPackDef,
  }: TestPackGridSelection): Observable<void> {
    return this.getAll(
      this._createStorageKey(pipelineDef.id, testPackDef.id)
    ).pipe(
      concatMap(transactionSamples => {
        return forkJoin(
          transactionSamples.map(transactionSample =>
            this.getAll().pipe(
              map(samples => {
                return samples.filter(
                  sample =>
                    sample.transactionId === transactionSample.transactionId
                );
              })
            )
          )
        );
      }),
      map(samples => samples.flat()),
      switchMap(samples => {
        return forkJoin(
          samples.map(sample =>
            this.delete(
              this._createStorageKey(
                sample.pipelineId,
                sample.testPack.testPackDef.id,
                sample.sample.sampleDef.id
              )
            )
          )
        );
      }),
      mapToVoid()
    );
  }

  private _getTransactionGroup(
    transactionId?: number
  ): Observable<TransactionSample[] | undefined> {
    if (!transactionId) {
      return of(undefined);
    }
    return this.getAll().pipe(
      map(samples =>
        samples.filter(sample => sample.transactionId === transactionId)
      )
    );
  }

  private _uniqueSampleJson(
    testPackGridSelection: TestPackGridSelection,
    sampleJson: JsonSample
  ): Observable<JsonSample> {
    return this.getSamples(testPackGridSelection).pipe(
      map(transientTestPacks => {
        const sampleJsonCopy = deepCopy(sampleJson);
        const uniqueName = addNumericSuffixToMakeUnique(
          sampleJsonCopy.sampleDef.name,
          name =>
            !transientTestPacks
              ? false
              : transientTestPacks.samples.some(
                  sample => sample.sampleDef.name === name
                )
        );

        sampleJsonCopy.sampleDef = {
          // TODO: Fix this so that the backend uses the front ID
          id: `client:${objectHash(uniqueName)}`,
          name: uniqueName,
        };

        return sampleJsonCopy;
      })
    );
  }

  private _createStorageKey(
    pipelineDefId: string,
    testPackDefId: string,
    sampleId?: string
  ): string {
    return `${pipelineDefId}-${testPackDefId}${sampleId ? `:${sampleId}` : ''}`
      .replace(/\s+/g, '-')
      .toLowerCase();
  }
}
