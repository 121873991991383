import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
  Provider,
} from '@angular/core';
import { ROLLBAR_CONFIG } from '@configs';
import Rollbar from 'rollbar';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private _rollbar: Rollbar) {}

  handleError(err: any): void {
    this._rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory(): Rollbar {
  return new Rollbar(ROLLBAR_CONFIG);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export const provideRollbar = (): Provider[] => [
  { provide: ErrorHandler, useClass: RollbarErrorHandler },
  { provide: RollbarService, useFactory: rollbarFactory },
];
