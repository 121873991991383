<mat-form-field [style.maxWidth.px]="maxWidth()">
  <mat-label>{{ label() }}</mat-label>
  <input
    #input
    matInput
    autocomplete="off"
    [value]="value() || ''"
    [disabled]="disabled()"
    (appInputValue)="onValueChange($event)"
  />
  @if (input.value.length) {
    <button
      matIconSuffix
      mat-icon-button
      class="mr-8"
      [disabled]="disabled()"
      (click)="onValueChange()"
    >
      <fa-icon icon="times" />
    </button>
  }
  <div matIconPrefix>
    <ng-content select="[textInputPrefix]" />
  </div>
</mat-form-field>
