<button appStopPropagation mat-icon-button (click)="toggle()">
  @switch (showNative) {
    @case (true) {
      <fa-icon icon="laptop-code" matTooltip="View as code" />
    }
    @default {
      <fa-icon icon="align-justify" matTooltip="View as formatted document" />
    }
  }
</button>

<button appStopPropagation mat-icon-button (click)="download.emit()">
  <fa-icon icon="download" matTooltip="Download" />
</button>
