import { HttpErrorResponse } from '@angular/common/http';
import { isString } from '@utils/string-utils';

export const getServerErrorMessage = ({
  status,
  error,
}: HttpErrorResponse): string => {
  switch (status) {
    case 400: {
      return isString(error) ? error : `${error.message}`;
    }
    case 401: {
      return 'You are not authorized to view this content. Please log in.';
    }
    case 403: {
      return 'You do not have permission to perform this action.';
    }
    case 404: {
      return 'The requested resource was not found.';
    }
    case 500: {
      return 'The server encountered an error. Please try again later.';
    }
    default: {
      return 'An unexpected error occurred. Please try again later.';
    }
  }
};
