import { READ_WRITE_SERVER_KEY } from '@configs';
import { UserLoginResponse } from '@features/auth/login';
import * as ProjectsSelector from '@features/workspace-manager/store/projects/projects.selector';
import {
  IDomainModelDetails,
  IMenuItems,
  IModelDocumentDialog,
  LoadingState,
  ServerStatusMap,
  TaskStatus,
  UserDocumentMap,
  Workspace,
  WorkspaceGroup,
  WorkspaceGroupDetails,
  WorkspaceId,
  WorkspaceInfo,
  WorkspaceItemState,
  WorkspaceMenuItem,
  WorkspaceType,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import {
  createFeatureSelector,
  createSelector,
  createSelectorFactory,
  defaultMemoize,
  select,
} from '@ngrx/store';
import { AppSelectors, AuthSelectors } from '@store/selectors';
import { TaskSelectors } from '@store/selectors/task.selector';
import * as ServerStatusSelectors from '@store/server-status/server-status.selectors';
import { getDocumentLink } from '@store/workspace/selectors/workspace.selector.helper';
import { deepEquals } from '@utils/object-utils';
import { filterNotNulls } from '@utils/operators';
import { ConnectionState } from '@workspace-design/textual/models/rosetta-editor.model';
import { pipe } from 'rxjs';
import * as workspaceReducer from '../reducers/workspace.reducers';

const createSelectorWithDeepEquals = <T>() =>
  createSelectorFactory<object, T>(projectorFn =>
    defaultMemoize(projectorFn, undefined, deepEquals)
  );

const selectWorkspaceFeature = createFeatureSelector<workspaceReducer.State>(
  workspaceReducer.featureKey
);

const selectWorkspaceId = createSelectorWithDeepEquals<WorkspaceId | null>()(
  selectWorkspaceFeature,
  (state: workspaceReducer.State) => state?.workspaceId || null
);

const selectWorkspaceItems = createSelectorWithDeepEquals<
  WorkspaceItemState[]
>()(selectWorkspaceFeature, (state: workspaceReducer.State) =>
  state.workspaceItems.map<WorkspaceItemState>(item => ({
    ...item,
    modified: item.contents !== item.originalContents,
    info: {
      ...item.info,
      readOnly: state.workspaceInfo?.readOnly || item.info.readOnly,
    },
  }))
);

const selectWorkspaceItemFromUri = (uri: string) =>
  createSelector(selectWorkspaceFeature, state =>
    state.workspaceItems.find(item => item.info.uri === uri)
  );

const selectCurrentWorkspaceItemUri = createSelector(
  selectWorkspaceFeature,
  state => state.selectedWorkspaceItemId?.uri
);

const selectCurrentWorkspaceItemId = createSelector(
  selectWorkspaceFeature,
  state => state.selectedWorkspaceItemId
);

const selectDiffEditorOpen = createSelector(
  selectWorkspaceFeature,
  state => state.isDiffEditorOpen
);

const selectFirstLoad = createSelector(
  selectWorkspaceFeature,
  state => state.firstLoad
);

const selectWorkspaceMenuItems = createSelector(
  selectWorkspaceFeature,
  state => state.workspaceItems
);

const selectMenuItem = createSelector(
  selectWorkspaceFeature,
  state => state.menuItems
);

const selectMenuItemState = createSelector(
  selectWorkspaceFeature,
  state => state.menuItems.state
);

const selectMenuItems = createSelector(
  selectWorkspaceFeature,
  state => state.menuItems.items
);

const selectProjects = createSelector(selectMenuItems, items =>
  items.filter(item => item.workspaceType === WorkspaceType.Project)
);

const selectDomainModels = createSelector(
  selectWorkspaceFeature,
  state => state.domainModels
);

const selectLoadingState = createSelector(
  selectWorkspaceFeature,
  state => state.loadingState
);

const selectDocumentState = createSelector(
  selectWorkspaceFeature,
  state => state.documentState.state
);

const selectOpenDialogId = createSelector(
  selectWorkspaceFeature,
  state => state.dialogId
);

const selectDomainModelState = createSelector(
  selectWorkspaceFeature,
  state => state.domainState
);

const selectConnectionState = createSelector(
  selectWorkspaceFeature,
  state => state.connectionState
);

const selectDirty = createSelector(
  selectWorkspaceFeature,
  state => state.dirtyWorkspaceItems
);

const selectSaveError = createSelector(
  selectWorkspaceFeature,
  state => state.saveErrorWorkspaceItems
);

const selectDiagnostics = createSelector(
  selectWorkspaceFeature,
  state => state.erroredWorkspaceItems
);

const selectWorkspaceInfo = createSelector(
  selectWorkspaceFeature,
  state => state.workspaceInfo
);

const selectActiveDomainModels = createSelector(
  selectDomainModels,
  domainModels =>
    domainModels.filter(
      domainModel => domainModel.deprecationMessage === undefined
    )
);

const selectDomainModelsMap = createSelector(
  selectDomainModels,
  domainModels => {
    const modelMap = new Map<ModelInstanceId, IDomainModelDetails>();
    domainModels.forEach(m => modelMap.set(m.id, m));
    return modelMap;
  }
);

const selectOwnedDomainModelMap = createSelector(
  selectDomainModelsMap,
  AuthSelectors.selectOwnedModelIds,
  (domainModelMap, ownedModelIds) => {
    return new Map(
      ownedModelIds.map(modelId => [modelId, domainModelMap.get(modelId)])
    );
  }
);

const isConnectionDisconnected = createSelector(
  selectConnectionState,
  connectionState => connectionState === ConnectionState.Disconnected
);

const isConnectionReady = createSelector(
  selectConnectionState,
  connectionState => connectionState === ConnectionState.Ready
);

const isConnectionNotReady = createSelector(
  selectConnectionState,
  connectionState => connectionState !== ConnectionState.Ready
);

const isReadonlyWorkspace = createSelector(
  selectWorkspaceInfo,
  info => info?.readOnly ?? true
);

const selectCurrentWorkspaceItem = createSelector(
  selectWorkspaceItems,
  selectCurrentWorkspaceItemUri,
  (workspaceItems, selectedWorkspaceItemUri) =>
    workspaceItems.find(item => item.info.uri === selectedWorkspaceItemUri) ||
    null
);

const selectReadOnlyMessage = createSelector(
  isConnectionNotReady,
  isReadonlyWorkspace,
  selectCurrentWorkspaceItem,
  (isLoading, isReadOnlyWorkspace, currentWorkspaceItem) => {
    if (isLoading) {
      return 'Cannot edit while loading';
    }

    if (
      !isReadOnlyWorkspace &&
      currentWorkspaceItem?.info?.readOnly &&
      currentWorkspaceItem?.info?.canOverrideParent
    ) {
      return 'Unlock to edit';
    }

    return 'Cannot edit in read-only editor';
  }
);

const selectWorkspaceMenuItemsReadWrite = createSelectorWithDeepEquals<
  IMenuItems<WorkspaceMenuItem & { state: LoadingState }>
>()(
  selectMenuItem,
  ServerStatusSelectors.selectServerStatus,
  AppSelectors.selectHideProprietaryModels,
  (
    menuItems: workspaceReducer.State['menuItems'],
    status: ServerStatusMap,
    presenting: boolean
  ) => ({
    ...menuItems,
    items: menuItems.items
      .filter(
        item =>
          item.workspaceType === WorkspaceType.User &&
          (presenting ? !item.disabled : true)
      )
      .map(item => ({ ...item, state: status[READ_WRITE_SERVER_KEY] })),
  })
);

const canCreateNewWorkspace = createSelector(
  selectWorkspaceMenuItemsReadWrite,
  AuthSelectors.getUserProjectLimit,
  (item, projectLimit) => item.items.length < projectLimit
);

const workspaceLimitSummary = createSelector(
  selectWorkspaceMenuItemsReadWrite,
  AuthSelectors.getUserProjectLimit,
  (item, projectLimit) => `${item.items.length}/${projectLimit}`
);

const getAllWorkspacesForModel = (modelName: string) =>
  createSelector(selectMenuItems, items =>
    items
      .filter(w => !w.readOnly && w.modelName === modelName)
      .map(workspace => workspace.id.name)
  );

const isLoading = createSelector(
  selectLoadingState,
  loadingState => loadingState === LoadingState.LOADING
);

const isWorkspaceLoading = createSelector(
  isLoading,
  isConnectionNotReady,
  (workspaceLoading, connectionNotReady) =>
    workspaceLoading || connectionNotReady
);

const selectWorkspaceReady = createSelector(
  AppSelectors.isMonacoReady,
  selectLoadingState,
  (monacoReady, loadingState) =>
    monacoReady && loadingState === LoadingState.LOADED
);

const selectWorkspaceItemReadOnly = createSelectorWithDeepEquals<boolean>()(
  isConnectionNotReady,
  selectCurrentWorkspaceItem,
  selectWorkspaceInfo,
  (
    connectionNotReady: boolean,
    currentWorkspaceItem: WorkspaceItemState,
    workspaceInfo: WorkspaceInfo
  ) =>
    connectionNotReady ||
    workspaceInfo?.readOnly ||
    currentWorkspaceItem?.info?.readOnly ||
    false
);

const selectWorkspaceItemEditorState = createSelector(
  isConnectionNotReady,
  selectCurrentWorkspaceItem,
  selectWorkspaceInfo,
  (
    connectionNotReady: boolean,
    currentWorkspaceItem: WorkspaceItemState,
    workspaceInfo: WorkspaceInfo
  ) => {
    return {
      readOnly:
        connectionNotReady ||
        workspaceInfo?.readOnly ||
        currentWorkspaceItem?.info?.readOnly ||
        false,
    };
  }
);

const selectWorkspaceItemOverrideParent =
  createSelectorWithDeepEquals<boolean>()(
    isConnectionNotReady,
    selectCurrentWorkspaceItem,
    (connectionNotReady: boolean, currentWorkspaceItem: WorkspaceItemState) =>
      connectionNotReady || currentWorkspaceItem?.info?.overridesParent || false
  );

const selectCurrentWorkspaceModel = createSelector(
  selectWorkspaceInfo,
  selectDomainModels,
  (workspaceInfo: WorkspaceInfo | null, models: IDomainModelDetails[]) =>
    models.find(m => m.id === workspaceInfo?.modelId)
);

const hasWorkspaceItemsError = createSelector(
  selectDiagnostics,
  diagnosticsMap => Object.keys(diagnosticsMap).length > 0
);

const hasFilesToSave = createSelector(
  selectDirty,
  dirty => Object.keys(dirty).length > 0
);

const hasSaveErrors = createSelector(
  selectSaveError,
  saveErrors => saveErrors.length > 0
);

const canRecompilePojo = createSelector(
  selectWorkspaceFeature,
  state => !state.hasRecompiledInvalidPojo
);

const selectWorkspaceItemsToSave = createSelector(
  selectWorkspaceItems,
  selectDirty,
  selectSaveError,
  (workspaceItems, dirtyUriList, saveErrorUriList) =>
    [...dirtyUriList, ...saveErrorUriList].reduce<WorkspaceItemState[]>(
      (acc, uri) => {
        const item = workspaceItems.find(i => i.info.uri === uri);
        return acc.concat(item || []);
      },
      []
    )
);

const selectWorkspace = createSelector(
  selectWorkspaceInfo,
  selectWorkspaceItems,
  (workspaceInfo, workspaceItems) => {
    if (!workspaceInfo) {
      return null;
    }
    return {
      info: workspaceInfo,
      items: workspaceItems,
    } as Workspace;
  }
);

const selectedModifiedWorkspaceItems = createSelector(
  selectWorkspace,
  workspace => workspace?.items.filter(item => item.modified) || []
);

const getDomainModel = (modelId: ModelInstanceId) =>
  createSelector(selectDomainModels, (models: IDomainModelDetails[]) =>
    models.find(m => m.id === modelId)
  );

const getProjectsWithStateWithModel = createSelectorWithDeepEquals<
  WorkspaceGroupDetails[]
>()(
  ProjectsSelector.selectProjects,
  selectProjects,
  selectDomainModels,
  ServerStatusSelectors.selectAggregatedReadOnlyServerStatus,
  AuthSelectors.selectOwnedModelIds,
  (
    groups: WorkspaceGroup[],
    workspaceMenuItems: WorkspaceMenuItem[],
    models: IDomainModelDetails[],
    status: ServerStatusMap,
    ownedModelIds: ModelInstanceId[]
  ) =>
    groups.reduce<WorkspaceGroupDetails[]>((acc, group) => {
      const model = models.find(model => model.id === group.modelId);
      const workspaces = group.workspaces
        .map(workspaceId => {
          const workspace = workspaceMenuItems.find(
            w => w.id.name === workspaceId.name
          );
          return (
            workspace && {
              ...workspace,
              status: status[workspace.id.name] || LoadingState.LOADING,
            }
          );
        })
        .filter(Boolean);
      return !model
        ? acc
        : acc.concat({
            model,
            workspaces,
            owner: (ownedModelIds || []).some(id => id === model.id),
          });
    }, [])
);

const getWorkspaceId = pipe(select(selectWorkspaceId), filterNotNulls());

const isProjectTypeWorkspace = createSelector(
  selectWorkspaceInfo,
  info => info?.workspaceType === WorkspaceType.Project
);

const isContributionTypeWorkspace = createSelector(
  selectWorkspaceInfo,
  info => info?.workspaceType === WorkspaceType.Contribution
);

const selectCurrentWorkspaceAvailability = createSelector(
  ServerStatusSelectors.selectReadWriteServerStatus,
  ServerStatusSelectors.selectAggregatedReadOnlyServerStatus,
  selectWorkspaceInfo,
  (readWriteServerStatus, readOnlyServerStatus, workspaceInfo) => {
    return {
      currentMenuItem: workspaceInfo,
      loadingState: !workspaceInfo
        ? LoadingState.INITIAL
        : workspaceInfo.readOnly
          ? readOnlyServerStatus[workspaceInfo.id.name]
          : readWriteServerStatus,
    };
  }
);

const checkCanCreate = (modelId: ModelInstanceId) =>
  createSelector(
    selectMenuItems,
    AuthSelectors.selectAuthUser,
    AuthSelectors.getUserProjectLimit,
    (
      items: WorkspaceMenuItem[],
      user: UserLoginResponse | null,
      getUserProjectLimit: number
    ) => {
      return (
        getUserProjectLimit === -1 ||
        items.filter(
          w => w.owner === user?.rosettaAuth?.name && w.modelId === modelId
        ).length < getUserProjectLimit
      );
    }
  );

const getWorkspaceItemByUri = (uri: string) =>
  createSelector(
    selectWorkspaceItems,
    (workspaceItems: WorkspaceItemState[]) => {
      return workspaceItems.find(w => w.info.uri === uri);
    }
  );

const getModelDocumentDialogData = (
  datum: null | ModelInstanceId | [ModelInstanceId, boolean]
) =>
  createSelector(
    AuthSelectors.selectUserDocuments,
    selectDomainModels,
    (
      userDocuments: UserDocumentMap,
      domainModels: IDomainModelDetails[]
    ): IModelDocumentDialog | undefined => {
      let modelId: ModelInstanceId | null;
      let showAll = false;

      if (Array.isArray(datum)) {
        modelId = datum[0];
        showAll = datum[1];
      } else {
        modelId = datum;
      }

      const domainModel = domainModels.find(d => d.id === modelId);

      if (!domainModel) {
        return undefined;
      }

      const documentList = Object.values(domainModel.documents);

      return {
        isFirst: documentList.some(documents =>
          documents.some(
            doc =>
              !userDocuments[doc.modelId] ||
              userDocuments[doc.modelId][doc.documentId] === undefined
          )
        ),
        modelId,
        modelName: domainModel.name,
        documents: documentList
          .map(documents =>
            documents
              .filter(
                doc =>
                  showAll ||
                  !userDocuments[doc.modelId] ||
                  !userDocuments[doc.modelId][doc.documentId]
              )
              .map(doc => {
                const documentDomainModel = domainModels.find(
                  d => d.id === doc.modelId
                );

                return {
                  ...doc,
                  documentLink: getDocumentLink(doc.documentId, doc.modelId),
                  modelName: documentDomainModel?.name || doc.modelId,
                  privacyStatementLink:
                    documentDomainModel?.privacyStatementLink,
                };
              })
          )
          .flat(),
      };
    }
  );

const getWorkspaceTaskStatus = createSelector(
  selectLoadingState,
  selectConnectionState,
  hasWorkspaceItemsError,
  TaskSelectors.getWorkspaceAggregateStatus,
  (loadingState, connectionState, workspaceItemsError, workspaceStatus) => {
    if (
      connectionState !== ConnectionState.Ready ||
      loadingState !== LoadingState.LOADED
    ) {
      return TaskStatus.Pending;
    }

    if (workspaceItemsError) {
      return TaskStatus.Error;
    }

    return workspaceStatus;
  }
);

export const WorkspaceSelectors = {
  canCreateNewWorkspace,
  canRecompilePojo,
  checkCanCreate,
  getAllWorkspacesForModel,
  getDomainModel,
  getModelDocumentDialogData,
  getProjectsWithStateWithModel,
  getWorkspaceId,
  getWorkspaceItemByUri,
  getWorkspaceTaskStatus,
  hasFilesToSave,
  hasSaveErrors,
  hasWorkspaceItemsError,
  isConnectionDisconnected,
  isConnectionNotReady,
  isConnectionReady,
  isContributionTypeWorkspace,
  isLoading,
  isProjectTypeWorkspace,
  isReadonlyWorkspace,
  isWorkspaceLoading,
  selectActiveDomainModels,
  selectConnectionState,
  selectCurrentWorkspaceAvailability,
  selectCurrentWorkspaceItem,
  selectCurrentWorkspaceItemId,
  selectCurrentWorkspaceItemUri,
  selectCurrentWorkspaceModel,
  selectDiagnostics,
  selectDiffEditorOpen,
  selectDocumentState,
  selectDomainModels,
  selectDomainModelsMap,
  selectDomainModelState,
  selectedModifiedWorkspaceItems,
  selectFirstLoad,
  selectMenuItem,
  selectMenuItems,
  selectMenuItemState,
  selectOpenDialogId,
  selectOwnedDomainModelMap,
  selectProjects,
  selectReadOnlyMessage,
  selectWorkspace,
  selectWorkspaceId,
  selectWorkspaceInfo,
  selectWorkspaceItemEditorState,
  selectWorkspaceItemFromUri,
  selectWorkspaceItemOverrideParent,
  selectWorkspaceItemReadOnly,
  selectWorkspaceItems,
  selectWorkspaceItemsToSave,
  selectWorkspaceMenuItems,
  selectWorkspaceMenuItemsReadWrite,
  selectWorkspaceReady,
  workspaceLimitSummary,
};
