/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  computed,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

export type ButtonVariation = 'primary' | 'default';

@Component({
  selector: 'app-rosetta-button',
  standalone: true,
  imports: [MatButtonModule, FontsModule, LoadingSpinnerComponent],
  templateUrl: './rosetta-button.component.html',
  styleUrl: './rosetta-button.component.scss',
  host: {
    '[class.ros-btn-no-icon]': '!icon()',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaButtonComponent {
  label = input.required<string>();
  isLoading = input<boolean>();
  disabled = input<boolean>();
  icon = input<IconProp>();
  btnClick = output<void>();
  variation = input<ButtonVariation>('default');

  protected disabledState = computed(() => this.disabled() || this.isLoading());
}
