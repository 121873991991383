import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { IDomainModelDetails } from '@models';
import { ProjectLogoComponent } from '../project-logo/project-logo.component';

@Component({
  selector: 'app-project-row',
  standalone: true,
  imports: [FontsModule, ProjectLogoComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <app-project-logo [data]="model" size="xs" />
    {{ model?.name || 'Unknown Project' }}
    @if (showAccepted && model?.accepted) {
      <fa-icon icon="check" size="xs" />
    }
  `,
})
export class ProjectRowComponent {
  @Input({ transform: booleanAttribute }) showAccepted = false;
  @Input() model?: IDomainModelDetails;
}
