<button
  mat-stroked-button
  type="button"
  (click)="btnClick.emit()"
  [disabled]="disabledState()"
  [class.primary]="variation() === 'primary'"
>
  @if (isLoading() && !disabled()) {
    <app-loading-spinner class="mr-8" diameter="16" />
  } @else if (icon()) {
    <fa-icon [icon]="icon()" size="sm" [fixedWidth]="true" />
  }
  <span>{{ label() }}</span>
</button>
