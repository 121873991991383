import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { WorkspaceMenuItem } from '@models';
import { Store } from '@ngrx/store';
import { ProjectLogoComponent } from '@shared/components/project-logo/project-logo.component';
import { ProjectTypeComponent } from '@shared/components/project-type/project-type.component';
import { UpgradeIconComponent } from '@shared/components/upgrade-icon/upgrade-icon.component';
import { ModelPipe } from '@shared/pipes';
import { selectRecentWorkspaces } from '@store/recent-workspaces/recent-workspaces.selectors';
import * as RouterActions from '@store/router/router.actions';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    MatTableModule,
    ProjectLogoComponent,
    ProjectTypeComponent,
    UpgradeIconComponent,
    ModelPipe,
  ],
  selector: 'app-workspace-recent-list',
  templateUrl: './workspace-recent-list.component.html',
  styleUrls: ['./workspace-recent-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceRecentListComponent {
  constructor(private _store: Store) {}

  readonly displayedColumns = ['logo', 'type', 'displayName', 'version'];

  recentWorkspaces$ = this._store.select(selectRecentWorkspaces);

  open(item: WorkspaceMenuItem): void {
    this._store.dispatch(RouterActions.gotoWorkspace({ workspaceId: item.id }));
  }
}
