<button
  mat-icon-button
  matTooltip="Copy to clipboard"
  [cdkCopyToClipboard]="path"
  (cdkCopyToClipboardCopied)="onCopied($event)"
>
  @if (iconData$ | async; as data) {
    <fa-icon [style.color]="data.color" [icon]="data.icon" />
  }
</button>
