import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { StopPropagationDirective } from '@shared/directives';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-rosetta-tour-template',
  standalone: true,
  imports: [
    FontsModule,
    MatButtonModule,
    StopPropagationDirective,
    TourMatMenuModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './rosetta-tour-template.component.html',
  styles: `
    .rosetta-tour-wrapper {
      padding: 1rem 1.5rem;
      max-width: 25vh;
    }

    h4 {
      font-size: 0.75rem;
      line-height: 1;
      font-weight: bold;
      margin: 0 0 1rem;
    }
  `,
})
export class RosettaTourTemplateComponent {
  constructor(public tourService: TourService) {}

  prev(): void {
    this.tourService.prev();
  }

  next(): void {
    this.tourService.next();
  }

  end(): void {
    this.tourService.end();
  }
}
