import { inject } from '@angular/core';
import type { CanActivateChildFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppActions } from '@store/.';
import { MONACO_LOADER } from '../modules/design/textual/services/monaco-loader';

export const monacoReadyGuard: CanActivateChildFn = async () => {
  const loader = inject(MONACO_LOADER);
  const store = inject(Store);
  await loader.loadMonaco();
  store.dispatch(AppActions.monacoReady());
  return true;
};
