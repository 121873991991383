<header mat-dialog-title>
  <h2>{{ title() || options().title }}</h2>

  <div class="spacer flex-row-center">
    <ng-content select="[rosetta-dialog-title]" />
  </div>

  <button
    mat-icon-button
    tabindex="-1"
    [disabled]="showSpinnerAndDisableAll()"
    (click)="onClose()"
  >
    <fa-icon icon="times" />
  </button>
</header>

<mat-dialog-content class="pt-24">
  <ng-content />
</mat-dialog-content>

<mat-dialog-actions>
  <div class="spacer">
    <ng-content select="[rosetta-dialog-actions]" />
  </div>

  <button
    mat-button
    class="ml-16"
    (click)="onClose()"
    [disabled]="showSpinnerAndDisableAll()"
  >
    {{ options().closeLabel || 'Cancel' }}
  </button>
  @if (!options().hideSuccessAction) {
    <app-rosetta-button
      class="ml-8"
      [label]="options().successLabel || 'Ok'"
      [icon]="options().successIcon || 'circle-check'"
      [disabled]="disableSuccessAction()"
      [isLoading]="showSpinnerAndDisableAll()"
      (btnClick)="onSuccess()"
    />
  }
</mat-dialog-actions>
