import equal from 'fast-deep-equal/es6';
import { isEmpty, merge } from 'lodash-es';

export const isObjectEmpty = isEmpty;
export const deepEquals = equal;

export const deepMerge = <R = any>(...objs: Record<any, any>[]): R =>
  merge({}, ...objs);

export const deepCopy = <T extends object>(obj: T): T => structuredClone(obj);

export const encodeObjectToString = <T extends object>(obj: T): string =>
  obj === undefined || obj === null ? '' : btoa(JSON.stringify(obj));

export const decodeStringToObject = <T extends object>(
  encodedStr: string
): T | null => (!encodedStr ? null : JSON.parse(atob(encodedStr)));

export const wrapIdFields = <T, R>(obj: T): R => {
  const innerFunc = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(innerFunc);
    } else if (obj !== null && typeof obj === 'object') {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (key === 'id') {
          acc[key] = { id: value };
        } else {
          acc[key] = innerFunc(value);
        }
        return acc;
      }, {} as any);
    }
    return obj;
  };
  return innerFunc(obj);
};
