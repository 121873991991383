import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import {
  Diagnostic,
  DiagnosticItem,
} from '@shared/components/diagnostic-panel/models/diagnostic.model';
import { RosettaPathComponent } from '@shared/components/rosetta-path/rosetta-path.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DiagnosticPanelComponent } from './components/diagnostic-panel/diagnostic-panel.component';
import { DiagnosticPanelGroupDefDirective } from './directives/diagnostic-panel-def.directive';

@Component({
  standalone: true,
  imports: [
    DiagnosticPanelComponent,
    DiagnosticPanelGroupDefDirective,
    NgScrollbarModule,
    NgTemplateOutlet,
    RosettaPathComponent,
  ],
  selector: 'app-diagnostic',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './diagnostic.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  host: {
    class: 'diagnostic',
  },
})
export class DiagnosticComponent {
  data = input.required<Diagnostic>();
  panelClicked = output<DiagnosticItem>();
}
