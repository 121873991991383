import { Injectable } from '@angular/core';
import { CoreDialogService } from '@app/dialogs/core-dialog.service';
import { ReleaseNoteDialogService } from '@core/services/release-notes/release-notes-dialog.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as DialogActions from '@store/actions/dialog.actions';
import { map, switchMap, tap } from 'rxjs';

@Injectable()
export class DialogEffects {
  constructor(
    private _actions$: Actions,
    private _releaseNoteDialogService: ReleaseNoteDialogService,
    private _coreDialogService: CoreDialogService
  ) {}

  openIssueTracker$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(DialogActions.openIssueTrackerDialog),
        switchMap(() => this._coreDialogService.openIssueTracker())
      );
    },
    { dispatch: false }
  );

  openModelReleaseNoteDialog$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DialogActions.openModelReleaseNoteDialog),
      tap(({ modelId }) =>
        this._releaseNoteDialogService.getModelReleaseNotes(modelId)
      ),
      map(() => DialogActions.openReleaseNoteDialog())
    );
  });

  openAppReleaseNoteDialog$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(DialogActions.openAppReleaseNoteDialog),
      tap(() => this._releaseNoteDialogService.getCoreReleaseNotes()),
      map(() => DialogActions.openReleaseNoteDialog())
    );
  });

  openReleaseNoteDialog$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(DialogActions.openReleaseNoteDialog),
        switchMap(() => this._coreDialogService.openReleaseNotes())
      );
    },
    { dispatch: false }
  );
}
