import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { RosettaOverlayModule } from '@shared/modules';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { EngineSvgAnimation } from './engine-svg.animation';

@Component({
  standalone: true,
  selector: 'app-engine-svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './engine-svg.component.html',
  styleUrls: ['./engine-svg.component.scss'],
  animations: [EngineSvgAnimation],
  imports: [FontsModule, RosettaOverlayModule, RosettaMenuComponent],
})
export class EngineSvgComponent {
  @Input({ transform: booleanAttribute }) disabled = false;

  @HostBinding('attr.disabled')
  get isDisabled() {
    return this.disabled || null;
  }
  @HostBinding('@.disabled')
  get disabledAnimation() {
    return this.disabled || null;
  }

  @HostBinding('@animate') spin: string | null = null;

  @HostListener('@animate.done')
  onAnimationEnd(): void {
    this.spin = null;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.spin = 'animate';
  }
}
