import { PipeTransform, Pipe } from '@angular/core';
import { EN_DASH } from '@configs';
import { isEmptyString } from '@utils/string-utils';

@Pipe({
  standalone: true,
  name: 'emptyString',
})
export class EmptyStringPipe implements PipeTransform {
  transform(str: string | undefined | null, placeholder = EN_DASH): string {
    if (str === undefined || str === null) {
      return placeholder;
    }
    if (isEmptyString(str)) {
      return '""';
    }
    return str;
  }
}
