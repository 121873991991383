import {
  BLANK_MODEL_URI,
  OVERRIDE_NAMESPACE_SYNTAX,
  READ_ONLY_PREFIX,
  READ_WRITE_PREFIX,
} from '@configs';
import {
  IDomainModelDetails,
  IDomainModelResponse,
  SelectedWorkspaceItemId,
  WorkspaceInfo,
  WorkspaceItem,
  WorkspaceItemInfo,
  WorkspaceItemState,
  WorkspaceMenuItem,
  WorkspaceType,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { WorkspaceInfoDto } from '@models/dto';
import { setUpgradeWarning } from '../workspace.helper';

export const generateModelNameFromResponse = (
  modelInstanceId: ModelInstanceId,
  fixedVersionId?: string
): ModelInstanceId => {
  return (fixedVersionId ? `${fixedVersionId}-` : '') + modelInstanceId;
};

const splitFileName = (info: WorkspaceItemInfo): string[] => {
  return info.name
    .replace('.rosetta', '')
    .replace(new RegExp(/_/, 'g'), ' ')
    .split('-');
};

const getNamespaceList = (info: WorkspaceItemInfo): string[] => {
  // Split the filename into an array of strings and take the last one
  return splitFileName(info).slice(0, -1);
};

const getNamespaceString = (
  info: WorkspaceItemInfo,
  shortname: string
): string => {
  const splitFileName = info.name.replace('.rosetta', '').split('-');
  const removeType = splitFileName.slice(0, -1);
  return [shortname, ...removeType].join('.');
};

const getCategory = (info: WorkspaceItemInfo): string => {
  return splitFileName(info).at(-1) || '';
};

const getTreePath = (info: WorkspaceItemInfo, shortname: string): string[] => {
  return [shortname, ...getNamespaceList(info), getCategory(info)];
};

const generateWebsocketUrlPath = ({
  workspaceType,
  fixedVersionId,
  modelId,
  id,
}: WorkspaceInfoDto): string => {
  if (workspaceType === WorkspaceType.Project) {
    const fixedVersionPath = fixedVersionId ? `${fixedVersionId}-` : '';
    return `${READ_ONLY_PREFIX}-${fixedVersionPath}${modelId}/${id.name}`;
  }

  return `${READ_WRITE_PREFIX}/${id.name}`;
};

const getItemOriginalContents = (item: WorkspaceItem): string => {
  let originalContents = item.originalContents || '';
  if (
    item.info.overridesParent &&
    !originalContents.startsWith(OVERRIDE_NAMESPACE_SYNTAX)
  ) {
    originalContents = `${OVERRIDE_NAMESPACE_SYNTAX} ${originalContents}`;
  }
  return originalContents;
};

export const domainModelToWorkspaceMapper = (
  domainModels: IDomainModelResponse[]
): WorkspaceMenuItem[] =>
  domainModels
    .filter(model => model.readOnlyAvailable)
    .map<WorkspaceMenuItem>(domain => ({
      id: {
        name: `${READ_ONLY_PREFIX}-${generateModelNameFromResponse(domain.id, domain.fixedVersionId)}`,
        workspaceUserPath: 'system',
      },
      displayName: domain.name,
      modelId: domain.id,
      modelName: domain.name,
      modelVersion: domain.versions[0],
      modelShortname:
        domain.shortname || domain.id.substring(0, 3).toUpperCase(),
      developmentVersion: domain.developmentVersion,
      // Hardcoded fields
      owner: 'system',

      name: 'system',
      readOnly: true,
      corrupt: false,
      upgradeWarning: 'none',
      isDeleted: false,
      disabled: false,
      creationDate: '',
      fixedVersionId: undefined,
      workspaceType: WorkspaceType.Project,
      ...(domain.fixedVersionId && { fixedVersionId: domain.fixedVersionId }),
    }));

export const workspaceInfoFactory = (
  workspaceInfoDto: WorkspaceInfoDto,
  model?: IDomainModelDetails
): WorkspaceInfo => {
  const { upgradeWarning, upgradeVersion, isProdVersion } = setUpgradeWarning(
    workspaceInfoDto.modelVersion,
    model
  );

  return {
    ...workspaceInfoDto,
    id: {
      name:
        workspaceInfoDto.workspaceType === WorkspaceType.Project
          ? `${READ_ONLY_PREFIX}-${generateModelNameFromResponse(
              workspaceInfoDto.modelId,
              workspaceInfoDto.fixedVersionId
            )}`
          : workspaceInfoDto.id.name,
      workspaceUserPath: workspaceInfoDto.id.workspaceUserPath,
    },
    modelShortname: model?.shortname || workspaceInfoDto.modelId,
    upgradeWarning: workspaceInfoDto.readOnly ? 'none' : upgradeWarning,
    isDeleted: false,
    disabled: workspaceInfoDto.corrupt,
    urlPath: generateWebsocketUrlPath(workspaceInfoDto),
    developmentVersion: !isProdVersion,
    ...(!workspaceInfoDto.readOnly && upgradeVersion
      ? { canUpgrade: upgradeVersion }
      : {}),
  };
};

export const getItemModelName = (
  item: WorkspaceItem,
  domainModels: IDomainModelDetails[]
): string =>
  domainModels.find(model => model.id === item.info.modelName)?.shortname ||
  item.info.modelName;

export const createWorkspaceItemState = (
  item: WorkspaceItem,
  modelName: string
): WorkspaceItemState => {
  const contents = item.contents || '';
  const originalContents = getItemOriginalContents(item);
  const modified = contents !== originalContents;

  return {
    ...item,
    contents,
    originalContents,
    modified,
    category: getCategory(item.info),
    namespace: getNamespaceString(item.info, modelName.toLowerCase()),
    treePath: getTreePath(item.info, modelName.toLowerCase()),
    info: {
      ...item.info,
      originalUri: item.info.originalUri || BLANK_MODEL_URI,
    },
    lastModified: Date.now(),
  };
};

export const createWorkspaceItemId = (
  itemInfo: WorkspaceItemInfo
): SelectedWorkspaceItemId | undefined => {
  return itemInfo
    ? {
        modelName: itemInfo.modelName,
        uri: itemInfo.uri,
      }
    : undefined;
};
