import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml',
  standalone: true,
})
export class StripHtmlPipe implements PipeTransform {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  transform(value: string): string {
    const tmp = this._document.createElement('DIV');
    tmp.innerHTML = value;
    return (tmp.textContent || tmp.innerText || '').replace(/\s+/g, ' ').trim();
  }
}
