import { PackagePlan } from '@models';

// TODO: This should be moved to the backend
export const PACKAGE_PLAN_CONFIG = {
  workspaceLimitDefault: 3,
  workspaceLimits: new Map([
    [PackagePlan.Community, 3],
    [PackagePlan.Essentials, 5],
    [PackagePlan.Pro, 10],
    [PackagePlan.Enterprise, 50],
  ]),
} as const;
