<app-rosetta-menu
  [style.width.px]="width"
  [style.--item-size.px]="itemSize"
  *ngrxLet="
    data
      | filter: filterString : filterFunc
      | sortBy: sortByIteratees as dataFiltered
  "
>
  @if (data?.length) {
    <app-text-input
      class="mb-8"
      [label]="inputPlaceholder"
      [(value)]="filterString"
    />
    <div class="filtered-list__static-content">
      <ng-container
        [ngTemplateOutlet]="staticContentTemplate"
        [ngTemplateOutletContext]="{ $implicit: dataFiltered }"
      />
    </div>
    <div
      class="filtered-list__content"
      [style.height.px]="
        dataFiltered?.length | filteredListHeight: itemSize : height
      "
    >
      @if (dataFiltered?.length) {
        <ng-scrollbar
          #scrollbar
          externalViewport=".cdk-virtual-scroll-viewport"
          (afterUpdate)="cdkVirtualScrollViewport.checkViewportSize()"
        >
          <!--
            FIX: cdkVirtualScrollViewport calculates viewport incorrectly
            on init, so as a workaround we are calling checkViewportSize() on update
          -->
          <cdk-virtual-scroll-viewport
            #cdkVirtualScrollViewport
            [itemSize]="itemSize"
            [maxBufferPx]="itemSize * 5"
            [minBufferPx]="itemSize * 2"
          >
            <div
              *cdkVirtualFor="
                let datum of dataFiltered;
                template: rowContentTemplate
              "
            ></div>
          </cdk-virtual-scroll-viewport>
        </ng-scrollbar>
      } @else {
        <h4 class="mb-0 font-weight-bold">No matches</h4>
      }
    </div>
  } @else {
    <h4 class="mb-0 font-weight-bold">Empty list</h4>
  }
</app-rosetta-menu>
