import { TransformDataViewerComponent } from '@shared/components/transform/components/transform-data-viewer/transform-data-viewer.component';
import * as transform from '@shared/components/transform/models/data-viewer';
import { ReplaySubject } from 'rxjs';

export class DataViewerContext {
  constructor(parentTable: TransformDataViewerComponent) {
    this.parentTable = parentTable;
  }

  summarySubject = new ReplaySubject<transform.DataViewerSummary>(1);
  readySubject = new ReplaySubject<void>(1);
  initialDataLoaded = false;
  hasSampleNameBeenResized = false;
  selectableRowCount = 0;
  dataState: transform.DateViewState = transform.DateViewState.Empty;
  selectedActionRows: transform.DataViewerRow[] = [];
  dataViewer: transform.DataViewer | undefined = undefined;
  parentTable: TransformDataViewerComponent;
  showChanges: boolean;

  /*
   * Store RegReportCol objects in a map for easy retrieval,
   * and store references in two lists so that the UI can
   * quickly toggle between the two states
   */
  dataViewerColMap: Record<string, transform.DataViewerCol> = {};
  dataViewerCols: transform.DataViewerCol[] = [];
  colDefsWithChanges: transform.DataViewerCol[] = [];

  colDefs(): transform.DataViewerCol[] {
    return this.showChanges ? this.colDefsWithChanges : this.dataViewerCols;
  }

  get canPerformActions(): boolean {
    return this.dataViewer.canPerformActions;
  }
}
