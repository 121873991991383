import { ModelInstanceId } from '@models/domain-models';
import { createAction, props } from '@ngrx/store';

export const openReleaseNoteDialog = createAction(
  '[Dialog] Open Release Notes Dialog'
);

export const openModelReleaseNoteDialog = createAction(
  '[Dialog] Open Model Release Notes Dialog',
  props<{ modelId: ModelInstanceId }>()
);

export const openAppReleaseNoteDialog = createAction(
  '[Dialog] Open App Release Notes Dialog'
);

export const openIssueTrackerDialog = createAction(
  '[Dialog] Open Issue Tracker Dialog'
);
