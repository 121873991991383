import { Directive, OnInit } from '@angular/core';
import { DAYS_REMAINING_THRESHOLD } from '@configs';
import { PackagePlanDetailsDto } from '@features/auth/login';
import { Store } from '@ngrx/store';
import { RenderTemplateDirective } from '@shared/directives';
import { AuthSelectors } from '@store/selectors';
import { getDaysRemaining } from '@utils/dates-utils';
import { firstNotNullAndComplete } from '@utils/operators';
import { tap } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appTrialEndingSoon]',
})
export class TrialEndingSoonDirective
  extends RenderTemplateDirective
  implements OnInit
{
  constructor(private _store: Store) {
    super();
  }

  ngOnInit(): void {
    this._store
      .select(AuthSelectors.getPackagePlanDetails)
      .pipe(
        firstNotNullAndComplete(),
        tap(packagePlanDetails =>
          this.render(this._isTrialEndingSoon(packagePlanDetails))
        )
      )
      // eslint-disable-next-line @ngrx/no-store-subscription
      .subscribe();
  }

  private _isTrialEndingSoon(
    packagePlanDetails: PackagePlanDetailsDto
  ): boolean {
    return (
      packagePlanDetails.trial &&
      getDaysRemaining(packagePlanDetails.endDate) <= DAYS_REMAINING_THRESHOLD
    );
  }
}
