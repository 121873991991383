import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { COLOR_WHITE } from '@configs';
import { Store } from '@ngrx/store';
import { SlideInAnimation } from '@shared/animations';
import { CurveSvgComponent } from '@shared/components/curve-svg.component';
import { ProjectLogoComponent } from '@shared/components/project-logo/project-logo.component';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { contrast } from '@utils/string-utils';

@Component({
  selector: 'app-model-options',
  standalone: true,
  imports: [CurveSvgComponent, ProjectLogoComponent],
  templateUrl: './model-options.component.html',
  styleUrls: ['./model-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SlideInAnimation],
})
export class ModelOptionsComponent {
  constructor(private _store: Store) {}

  $currentModel = toSignal(
    this._store.select(WorkspaceSelectors.selectCurrentWorkspaceModel)
  );

  $backgroundColor = computed(
    () => this.$currentModel()?.primaryColor || COLOR_WHITE
  );
  $color = computed(() => contrast(this.$backgroundColor()));
}
