import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import {
  ICellActions,
  ICellActionsButton,
} from '@shared/components/rosetta-table/models/rosetta-table.model';
import { StopPropagationDirective } from '@shared/directives/stop-propagation/stop-propagation.directive';

@Component({
  standalone: true,
  imports: [FontsModule, MatButtonModule, StopPropagationDirective],
  selector: 'app-action-cell',
  templateUrl: './action-cell.component.html',
  styleUrls: ['./action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCellComponent implements ICellRendererAngularComp {
  actions!: ICellActionsButton[];
  rowData: unknown;
  context!: any;

  agInit({ actions, data, context }: ICellRendererParams & ICellActions): void {
    this.actions = actions;
    this.rowData = data;
    this.context = context;
  }

  refresh(): boolean {
    return false;
  }

  onActionClick(action: ICellActionsButton<any>): void {
    if (action?.action) {
      action.action(this.rowData, this.context);
    }
  }
}
