/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { BaseDeferDialogService } from '@core/services/base-defer-dialog.service';

@Injectable()
export class SettingsDialogService extends BaseDeferDialogService {
  openUpdatePassword() {
    return this.lazyOpenDialog(() =>
      import('./update-password-dialog/update-password-dialog.component').then(
        m => m.UpdatePasswordDialogComponent
      )
    );
  }
}
