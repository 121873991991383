import { wrapIdFields } from '@utils/object-utils';
import { map, OperatorFunction } from 'rxjs';
import { TransformDTO } from '../transform-dto.model';
import { TransientTestPack } from '../transient-test-pack.model';

export const toTransientTestPackDTO = (
  transientTestPack?: TransientTestPack
): TransformDTO.TransientTestPack | null => {
  return !transientTestPack
    ? null
    : wrapIdFields({
        ...transientTestPack,
        samples: transientTestPack.samples.map(sample => ({
          sampleDef: sample.sampleDef,
          ...(sample.json ? { json: sample.json } : {}),
          ...(sample.runtimeError ? { runtimeError: sample.runtimeError } : {}),
        })),
      });
};

export const fromTransientTestPackDTO = (): OperatorFunction<
  TransformDTO.TransientTestPack,
  TransientTestPack
> => {
  return map(transientTestPackDTO => ({
    ...transientTestPackDTO,
    samples: transientTestPackDTO.samples.map(sample => ({
      sampleDef: {
        ...sample.sampleDef,
        id: sample.sampleDef.id.id,
      },
      ...(sample.json ? { json: sample.json } : {}),
      ...(sample.runtimeError ? { runtimeError: sample.runtimeError } : {}),
    })),
  }));
};
