import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { dialogResultOperator } from '@core/services/base-defer-dialog.service';
import { TransformDialogService } from '@shared/components/transform/dialogs/transform-dialog.service';
import { TransformSelectorManagerService } from '@shared/components/transform/services/selectors/transform-selector-manager.service';
import { TransformService } from '@shared/components/transform/services/transform.service';

@Component({
  selector: 'app-no-data-message',
  standalone: true,
  imports: [MatButtonModule, FontsModule],
  template: `
    <fa-icon
      icon="exclamation-circle"
      size="2xl"
      [class.theme-color-yellow-alt]="!showAddSampleAction()"
      [class.theme-color-highlight]="showAddSampleAction()"
    />

    @if (showAddSampleAction()) {
      <h2>Test Pack is empty</h2>
      <button mat-stroked-button (click)="openAddSample()">
        <fa-icon icon="plus" />
        <span>Add Samples</span>
      </button>
    } @else {
      <h2>Select data to display</h2>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex-1 flex-align-center-center',
  },
})
export class NoDataMessageComponent {
  constructor(
    private _dialogService: TransformDialogService,
    private _transformService: TransformService,
    private _transformSelectorManager: TransformSelectorManagerService
  ) {}

  showAddSampleAction = input.required<boolean>();

  openAddSample(): void {
    this._dialogService
      .openAddSample(this._transformSelectorManager.getCurrentSelection())
      .pipe(dialogResultOperator())
      .subscribe(() => {
        this._transformService.rerun();
      });
  }
}
