import { Directive } from '@angular/core';
import { RosettaShowHide } from './rosetta-show-hide.model';

@Directive({
  selector: '[appRosettaHideItem]',
  standalone: true,
})
export class RosettaHideItemDirective {}

@Directive({
  selector: '[appRosettaShowItem]',
  standalone: true,
})
export class RosettaShowItemDirective {}

@Directive({
  selector: '[appRosettaShowHideTrigger]',
  standalone: true,
  host: {
    '(mouseenter)': 'onMouseEnter()',
    '(mouseleave)': 'onMouseLeave()',
  },
})
export class RosettaShowHideTriggerDirective {
  main?: RosettaShowHide;

  register(element: RosettaShowHide): void {
    this.main = element;
  }

  onMouseEnter(): void {
    if (this.main) {
      this.main.setShowState(false);
    }
  }

  onMouseLeave(): void {
    if (this.main) {
      this.main.setShowState(true);
    }
  }
}
