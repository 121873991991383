import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { UserAbilities } from '@configs';
import { AuthService } from '@core/services';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@store/selectors';
import { firstNotNullAndComplete } from '@utils/operators';
import { map } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'hasAbility',
})
export class HasAbilityPipe implements PipeTransform {
  constructor(
    private _store: Store,
    private _authService: AuthService,
    private _cdr: ChangeDetectorRef
  ) {}

  private _asyncPipe = new AsyncPipe(this._cdr);

  transform(ability: UserAbilities) {
    return this._asyncPipe.transform(
      this._store.select(AuthSelectors.selectAuthUser).pipe(
        firstNotNullAndComplete(),
        map(() => this._authService.has(ability))
      )
    );
  }
}
