import { MAX_FILE_UPLOAD_MB, MAX_FILE_UPLOAD_WS_MB } from '@configs';
import { UploadType } from '@models/domain-models';
import { convertMBToBytes } from '@utils/file-utils';
import { ValidationFunc } from './validator.model';

export const validateFileSize: ValidationFunc<
  Partial<{ uploadType: UploadType }>
> = (options = {}) => {
  const { uploadType } = options;
  const maxSizeInMB =
    uploadType == 'WEBSOCKET' ? MAX_FILE_UPLOAD_WS_MB : MAX_FILE_UPLOAD_MB;
  return file => {
    if (file.size > convertMBToBytes(maxSizeInMB)) {
      return {
        fileName: file.name,
        reason: `File size exceeds the maximum limit of ${maxSizeInMB.toFixed(2)} MB.`,
      };
    }

    return null;
  };
};
