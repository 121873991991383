import { NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { HEX, WorkspaceType, WorkspaceTypeIconMap } from '@models';
import { useDarkForeground } from '@utils/string-utils';

@Component({
  standalone: true,
  imports: [NgStyle, FontsModule],
  selector: 'app-project-type',
  templateUrl: './project-type.component.html',
  styleUrls: ['./project-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTypeComponent {
  iconConfig: IconProp = ['fas', 'building'];
  backgroundStyle: Record<string, string> = {};
  iconClass = true;

  @Input()
  set color(value: HEX) {
    if (!value) {
      return;
    }

    this.iconClass = false;
    this.backgroundStyle = {
      backgroundColor: value,
      color: useDarkForeground(value)
        ? 'rgba(0,0,0, 0.60)'
        : 'rgba(255,255,255, 0.75)',
    };
  }

  @Input() size?: SizeProp;
  @Input() set data(workspaceType: WorkspaceType) {
    this.iconConfig = WorkspaceTypeIconMap[workspaceType];
  }
}
