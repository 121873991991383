import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Color } from '@models';

@Component({
  standalone: true,
  selector: 'app-curve-svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<svg width="156" height="60" viewBox="0 0 156 60">
    <path
      d="M127.781 60H156V0H0.0976562C7.66016 3.13428 15.6328 6.65869 24.0742 10.6094C47.3945 21.5513 84.2578 39.1313 127.781 60Z"
      [attr.fill]="color()"
      fill-opacity="0.4"
    />
    <path d="M155.568 60H156V0H23.6211L155.568 60Z" [attr.fill]="color()" />
  </svg>`,
  styles: [
    `
      :host {
        display: inline-block;
      }
    `,
  ],
})
export class CurveSvgComponent {
  color = input.required<Color>();
}
