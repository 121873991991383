import { OperatorFunction, filter, first } from 'rxjs';
import { isNotNull } from '../ts-helpers';

/*
Helper operator to only complete once it has non-null value
*/
export function firstNotNullAndComplete<T>(): OperatorFunction<
  T,
  NonNullable<T>
> {
  return first(isNotNull);
}

/*
Helper operator to filter all non-null values
*/
export function filterNotNulls<T>(): OperatorFunction<T, NonNullable<T>> {
  return filter(isNotNull);
}
