<ng-container *ngrxLet="numberResult$ | async as resultMsg">
  <button
    mat-icon-button
    class="small-button-icon"
    (click)="scrollToCurrent()"
    [disabled]="!resultMsg"
  >
    <fa-icon icon="search" [fixedWidth]="true" />
  </button>
  <input
    matInput
    class="theme-color"
    placeholder="Find"
    [(ngModel)]="searchTerm"
    (input)="search(searchInput.value)"
    (keydown.enter)="next()"
    (keydown.arrowup)="prev()"
    (keydown.arrowdown)="next()"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    #searchInput
  />

  <p>{{ resultMsg }}</p>

  @if (resultMsg) {
    <div class="small-button-wrapper">
      <button mat-icon-button (click)="next()">
        <fa-icon icon="arrow-down" [fixedWidth]="true" />
      </button>
      <button mat-icon-button (click)="prev()">
        <fa-icon icon="arrow-up" [fixedWidth]="true" />
      </button>
    </div>
  }
  @if (searchTerm.length) {
    <button mat-icon-button class="small-button-icon" (click)="clear()">
      <fa-icon icon="times" [fixedWidth]="true" />
    </button>
  }
</ng-container>
