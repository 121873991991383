import { select } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { map, Observable, OperatorFunction } from 'rxjs';
import { firstNotNullAndComplete } from './is-not-null.operator';

export function WorkspaceUrlPrefixOperator(
  resourcePath: string,
  suffix?: string
): OperatorFunction<object, string> {
  return (source$: Observable<object>) => {
    return source$.pipe(
      select(WorkspaceSelectors.selectWorkspaceInfo),
      firstNotNullAndComplete(),
      map(
        ({ id: { name } }) =>
          `${resourcePath}/${name}${suffix ? `/${suffix.replace(/^\/+/, '')}` : ''}`
      )
    );
  };
}
