import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarDismiss,
} from '@angular/material/snack-bar';
import {
  SNACKBAR_DEFAULT_DURATION,
  SNACKBAR_ERROR_DURATION,
  SNACKBAR_SUCCESS_DURATION,
  SNACKBAR_WARNING_DURATION,
} from '@configs';
import { SnackBarConfig } from '@models';
import { Observable } from 'rxjs';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

@Injectable()
export class NotificationService {
  constructor(private _snackBar: MatSnackBar) {}

  showSuccess(data: SnackBarConfig): Observable<MatSnackBarDismiss> {
    return this._open({
      panelClass: ['snack-bar-success'],
      duration: SNACKBAR_SUCCESS_DURATION,
      data,
    });
  }

  showWarning(data: SnackBarConfig): Observable<MatSnackBarDismiss> {
    return this._open({
      panelClass: ['snack-bar-warning'],
      duration: SNACKBAR_WARNING_DURATION,
      data,
    });
  }

  showError(data: SnackBarConfig): Observable<MatSnackBarDismiss> {
    return this._open({
      panelClass: ['snack-bar-error'],
      duration: SNACKBAR_ERROR_DURATION,
      data,
    });
  }

  dismiss(): void {
    this._snackBar.dismiss();
  }

  private _open(
    overrides: MatSnackBarConfig<SnackBarConfig> = {}
  ): Observable<MatSnackBarDismiss> {
    const config: MatSnackBarConfig<SnackBarConfig> = {
      duration: SNACKBAR_DEFAULT_DURATION,
      verticalPosition: 'top',
      panelClass: ['snack-bar'],
      ...overrides,
    };

    return this._snackBar
      .openFromComponent(SnackBarComponent, config)
      .afterDismissed();
  }
}
