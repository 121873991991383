<button mat-icon-button [matMenuTriggerFor]="userMenu">
  <fa-layers>
    <fa-icon
      icon="circle"
      size="lg"
      [style.color]="userColor$ | async"
      transform="grow-2 up-2"
    />
    <fa-layers-text
      [content]="userInitials$ | async"
      [style.color]="textColor$ | async"
      transform="shrink-4 up-2"
    />
  </fa-layers>
</button>

<mat-menu #userMenu="matMenu" xPosition="before" class="user-menu">
  <ng-template matMenuContent>
    <app-theme-toggle />

    <mat-divider />

    <a mat-menu-item [routerLink]="internalLink.WORKSPACE_MANAGER">
      <fa-icon icon="layer-group" [fixedWidth]="true" />
      <span>Workspace Manager</span>
    </a>

    <a mat-menu-item [matMenuTriggerFor]="legal">
      <fa-icon icon="file-alt" [fixedWidth]="true" />
      <span>Legal</span>
    </a>

    <a mat-menu-item [routerLink]="internalLink.USER_PROFILE">
      <fa-icon icon="user" [fixedWidth]="true" />
      <span>User Profile</span>
    </a>

    <a mat-menu-item (click)="logout()">
      <fa-icon icon="sign-out-alt" [fixedWidth]="true" />
      <span>Sign Out</span>
    </a>

    @if (showUpgrade$ | async) {
      <div class="mb-8 pl-16 pr-16 flex-align-center">
        <app-upgrade-plan-button
          appClickToOpenUpgradeDialog
          class="width-full"
        />
      </div>
    }

    <a
      *appHasAbility="'ros.support.access'"
      mat-menu-item
      class="bg-10 bg-15-hover mb-1"
      [routerLink]="internalLink.SUPPORT"
    >
      <fa-icon icon="tools" [fixedWidth]="true" />
      <span>Support Tools</span>
    </a>

    <a
      *appHasAbility="'ros.user-admin.access'"
      mat-menu-item
      class="bg-10 bg-15-hover mb-1"
      [routerLink]="internalLink.ADMIN_USER"
    >
      <fa-icon icon="user-gear" [fixedWidth]="true" />
      <span>User Administration</span>
    </a>

    <div class="user-menu__app-version color-25 bg-5">
      <small>Rosetta: {{ (coreVersion$ | async) || '-' }}</small>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #legal="matMenu">
  <a mat-menu-item [routerLink]="internalLink.TERMS_OF_SERVICE" target="_blank">
    <fa-icon icon="external-link-alt" [fixedWidth]="true" /><span
      >Terms Of Service</span
    >
  </a>
  <a mat-menu-item [href]="externalLink.PRIVACY_STATEMENT" target="_blank">
    <fa-icon icon="external-link-alt" [fixedWidth]="true" /><span
      >Privacy Statement</span
    >
  </a>
  <a
    mat-menu-item
    [routerLink]="internalLink.SUBSCRIPTION_FORM"
    target="_blank"
  >
    <fa-icon icon="external-link-alt" [fixedWidth]="true" /><span
      >Subscription Form</span
    >
  </a>
  <a
    mat-menu-item
    [routerLink]="internalLink.AVAILABILITY_AND_SUPPORT"
    target="_blank"
  >
    <fa-icon icon="external-link-alt" [fixedWidth]="true" /><span
      >Availability and Support</span
    >
  </a>
</mat-menu>
