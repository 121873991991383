import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { WorkspaceApiService } from '@core/services';
import { isEmptyString, isString } from '@utils/string-utils';
import { firstValueFrom } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'modelLogo',
})
export class ModelLogoPipe implements PipeTransform {
  constructor(private _api: WorkspaceApiService) {}

  transform(modelId: string | undefined | null): Promise<SafeUrl> {
    // returns a Promise, so the value will be updated asynchronously
    return (
      (isString(modelId) &&
        !isEmptyString(modelId) &&
        firstValueFrom(this._api.getAsset(modelId))) ||
      null
    );
  }
}
