import { ColDef } from '@ag-grid-community/core';
import * as transform from '@shared/components/transform/models/data-viewer';
import { SampleCellState } from '@shared/components/transform/models/sample-cell-state.enum';
import { CellDataComponent } from '../components/cell-data/cell-data.component';

export const dataColDef = (): ColDef<
  transform.DataViewerRow,
  transform.DataViewerCell
> => ({
  cellRenderer: CellDataComponent,
  valueGetter: ({ data, column }) => data?.cellMap.get(column.getId()),
  valueFormatter: ({ value }) => value?.value || '',
  useValueFormatterForExport: true,
  cellClass: ({ value }) => {
    const cell = value;

    if (!cell) {
      return undefined;
    }

    if (
      cell.cellState === SampleCellState.Removed ||
      cell.cellState === SampleCellState.Diff
    ) {
      return 'ros-cell-diff';
    }

    if (cell.cellState === SampleCellState.New) {
      return 'ros-cell-warning';
    }

    if (cell.cellState === SampleCellState.Updated) {
      return 'ros-cell-updated';
    }

    return undefined;
  },
  comparator: (cellA, cellB) => {
    const valueA = cellA?.value ?? '';
    const valueB = cellB?.value ?? '';
    return valueA.localeCompare(valueB);
  },
});
