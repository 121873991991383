import { Injectable, NgZone } from '@angular/core';
import { WorkspaceItemDiagnostic } from '@features/workspace/models/rosetta-core.model';
import * as WorkspaceActions from '@store/workspace/actions';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { HandleDiagnosticsSignature, Middleware } from 'monaco-languageclient';
import { ReplaySubject } from 'rxjs';
import {
  Diagnostic,
  DiagnosticSeverity,
  TextDocumentChangeEvent,
  Uri,
} from 'vscode';
import { debounce } from '@utils/decorators';

const ECLIPSE_E_CORE_4 = 'org.eclipse.emf.ecore.4';

@Injectable()
export class LanguageServerMiddlewareService {
  constructor(
    private _store: Store,
    private _ngZone: NgZone
  ) {}

  private _loading = false;
  private _diagnosticBuffer = new Map<string, WorkspaceItemDiagnostic>();
  private _changeBuffer: { uri: string; contents: string }[] = [];
  private _percentageSubject$ = new ReplaySubject<number>(1);
  private _itemsToLoad = 0;

  percentage$ = this._percentageSubject$.asObservable();

  getMiddleware(numItems: number): Middleware {
    this._itemsToLoad = numItems;
    this._loading = true;
    this._diagnosticBuffer.clear();
    this._percentageSubject$.next(0);
    this._clearBuffer();

    return {
      handleDiagnostics: (
        uri: Uri,
        diagnostics: Diagnostic[],
        next: HandleDiagnosticsSignature
      ) => {
        next(uri, this._update(uri, diagnostics));
      },
      didChange: (
        data: TextDocumentChangeEvent,
        next: (data: TextDocumentChangeEvent) => Promise<void>
      ) => {
        this._saveChanges(data);
        this._sendChanges();
        return next(data);
      },
    };
  }

  private _update(uri: Uri, diagnostics: Diagnostic[]): Diagnostic[] {
    this._diagnosticBuffer.set(uri.toString(), {
      uri: uri.toString(),
      problems: this._filterDiagnostics(diagnostics, DiagnosticSeverity.Error),
    });

    this._updateLoadingPercentage();
    this._sendDiagnostics();

    return diagnostics.filter(({ code }) => code !== ECLIPSE_E_CORE_4);
  }

  private _filterDiagnostics(
    diagnostics: Diagnostic[],
    diagnosticSeverity: DiagnosticSeverity
  ): Pick<Diagnostic, 'message'>[] {
    return diagnostics
      .filter(({ severity }) => severity === diagnosticSeverity)
      .map(({ message }) => ({ message }));
  }

  private _updateLoadingPercentage(): void {
    //When all diagnostic items have been received there's no need to update the percentage
    if (!this._loading) {
      return;
    }

    this._percentageSubject$.next(
      this._diagnosticBuffer.size / this._itemsToLoad
    );

    // Check diagnostic load complete
    if (this._diagnosticBuffer.size >= this._itemsToLoad) {
      this._loading = false;
      this._percentageSubject$.next(0);
      this._dispatch(WorkspaceActions.workspaceReady());
    }
  }

  @debounce(500)
  private _sendDiagnostics(): void {
    // Only send diagnostics after load complete
    if (this._loading) {
      return;
    }

    this._dispatch(
      WorkspaceActions.updateDiagnostics({
        diagnostics: Array.from(this._diagnosticBuffer.values()),
      })
    );

    // Clear buffer after sending data
    this._diagnosticBuffer.clear();
  }

  @debounce(500)
  private _sendChanges(): void {
    this._dispatch(
      WorkspaceActions.updateWorkspaceItemContent({
        items: [...this._changeBuffer],
      })
    );

    this._clearBuffer();
  }

  private _saveChanges(data: TextDocumentChangeEvent): void {
    this._changeBuffer.push({
      uri: data.document.uri.toString(),
      contents: data.document.getText(),
    });
  }

  private _clearBuffer(): void {
    this._changeBuffer = [];
  }

  private _dispatch<T extends string>(typedAction: TypedAction<T>): void {
    this._ngZone.run(() => {
      this._store.dispatch(typedAction);
    });
  }
}
