<div>
  @if (!editMode) {
    <button mat-icon-button class="edit" (click)="editRow()">
      <fa-icon size="sm" icon="pen" />
    </button>
  } @else {
    <button mat-icon-button class="submit" (click)="submit()">
      <fa-icon size="sm" icon="check" />
    </button>
    <button mat-icon-button class="cancel" (click)="cancel()">
      <fa-icon size="sm" icon="times" />
    </button>
  }
</div>
