@if (isClosed$ | async) {
  <div class="collapsible-panel-closed" (click)="toggle()">
    <app-collapsible-panel-toggle-button
      [isOpen]="true"
      (panelClick)="toggle()"
      class="small-panel-area-button"
    />
    <small class="collapsible-panel-closed-title mb-0">{{ panelTitle }}</small>
  </div>
}
<div class="collapsible-panel-opened" [@fadeIn]="isOpen$ | async">
  <ng-content select="app-collapsible-panel-header" />
  <ng-content select="app-collapsible-panel-body" />
</div>
