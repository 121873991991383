import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  input,
  output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';

@Component({
  selector: 'app-collapsible-panel-toggle-button',
  standalone: true,
  imports: [FontsModule, MatButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `<button mat-icon-button>
    <fa-icon [icon]="['fas', isOpen() ? 'compress-alt' : 'expand-alt']" />
  </button>`,
  host: {
    '(click)': 'onClick($event)',
  },
})
export class CollapsiblePanelToggleButtonComponent {
  isOpen = input.required<boolean>();
  panelClick = output<void>();

  onClick(event: Event): void {
    event.stopPropagation();
    this.panelClick.emit();
  }
}
