import { Directive, input } from '@angular/core';
import {
  IRosettaSelector,
  IRosettaSelectorTemplate,
} from './rosetta-selector.model';

@Directive({
  selector: 'ng-template[appRosettaSelectorOptions]',
  standalone: true,
})
export class RosettaSelectorOptionsDirective<TData> {
  type = input<IRosettaSelector<TData>>(null, {
    alias: 'appRosettaSelectorOptions',
  });

  static ngTemplateContextGuard<TContext>(
    directive: RosettaSelectorOptionsDirective<TContext>,
    context: unknown
  ): context is IRosettaSelectorTemplate<TContext> {
    return true;
  }
}
