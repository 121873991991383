import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
  standalone: true,
  selector: 'app-link-with-arrow',
  templateUrl: './link-with-arrow.component.html',
  styleUrls: ['./link-with-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LoadingSpinnerComponent],
})
export class LinkWithArrowComponent {
  @Input() disabled = false;
  @Input() isLoading = false;
}
