import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  booleanAttribute,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { filter, merge, skipWhile } from 'rxjs';
import { RosettaButtonComponent } from '../rosetta-button/rosetta-button.component';

export interface RosettaDialogOptions {
  title?: string;
  successIcon?: IconProp;
  successLabel?: string;
  closeLabel?: string;
  closeDialogOnActionClick?: boolean;
  hideSuccessAction?: boolean;
}

@Component({
  selector: 'app-rosetta-dialog',
  standalone: true,
  imports: [
    FontsModule,
    MatButtonModule,
    MatDialogModule,
    RosettaButtonComponent,
  ],
  templateUrl: './rosetta-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RosettaDialogComponent>,
    private _destroyRef: DestroyRef
  ) {}

  options = input.required<RosettaDialogOptions>();
  title = input<string | null>(null);
  disableSuccessAction = input(false, { transform: booleanAttribute });
  showSpinnerAndDisableAll = input(false, { transform: booleanAttribute });

  successAction = output<void>();
  closeAction = output<void>();

  ngOnInit(): void {
    merge(
      this.dialogRef.backdropClick(),
      this.dialogRef
        .keydownEvents()
        .pipe(filter(event => event.key === 'Escape'))
    )
      .pipe(
        skipWhile(() => this.showSpinnerAndDisableAll()),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(() => this.onClose());
  }

  onClose(): void {
    this.closeAction.emit();
    this._closeDialog();
  }

  onSuccess(): void {
    this.successAction.emit();
    if (this.options().closeDialogOnActionClick) {
      this.onClose();
    }
  }

  private _closeDialog(): void {
    this.dialogRef.close();
  }
}
