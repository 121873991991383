export type Path = (string | number)[];
type IndexedPath = readonly [Path, number];

export const computeLastUniquePathElementBackwardIndices = (
  paths: Path[]
): number[] => {
  const indexedPaths = paths.map((path, index) => [path, index] as const);
  const result = paths.map(() => 0);
  computeLastUniquePathElementBackwardIndicesRecursively(
    indexedPaths,
    0,
    result
  );
  return result;
};
const computeLastUniquePathElementBackwardIndicesRecursively = (
  indexedPaths: IndexedPath[],
  numberOfElementsAlreadyChecked: number,
  result: number[]
): void => {
  groupPathsByEndingElement(indexedPaths, numberOfElementsAlreadyChecked).map(
    group => {
      if (group.length === 1) {
        // We found a unique path element
        const indexedPath = group[0];
        result[indexedPath[1]] = Math.min(
          numberOfElementsAlreadyChecked,
          indexedPath[0].length - 1
        );
      } else {
        computeLastUniquePathElementBackwardIndicesRecursively(
          group,
          numberOfElementsAlreadyChecked + 1,
          result
        );
      }
    }
  );
};
const groupPathsByEndingElement = (
  indexedPaths: IndexedPath[],
  backwardIndex: number
): IndexedPath[][] => {
  const exhaustedPaths: IndexedPath[] = [];
  const groupedPaths = indexedPaths.reduce<
    Record<string | number, IndexedPath[]>
  >((acc, indexedPath) => {
    const elementIndex = indexedPath[0].length - 1 - backwardIndex;
    if (elementIndex < 0) {
      exhaustedPaths.push(indexedPath);
      return acc;
    }
    const element = indexedPath[0][elementIndex];
    if (!acc[element]) {
      acc[element] = [];
    }
    acc[element].push(indexedPath);
    return acc;
  }, {});
  const pathGroups = Object.keys(groupedPaths).map(k => groupedPaths[k]);
  exhaustedPaths.forEach(exhaustedPath => pathGroups.push([exhaustedPath]));
  return pathGroups;
};
