import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  booleanAttribute,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontsModule } from '@app/fonts/fonts.module';
import { IDomainModelDetails } from '@models';
import { Store } from '@ngrx/store';
import { openModelReleaseNoteDialog } from '@store/actions/dialog.actions';
import * as RouterActions from '@store/router/router.actions';
import * as WorkspaceActions from '@store/workspace/actions';
import { isObjectEmpty } from '@utils/object-utils';
import { contrast } from '@utils/string-utils';

@Component({
  standalone: true,
  imports: [MatButtonModule, FontsModule, MatMenuModule],
  selector: 'app-project-card-menu',
  templateUrl: './project-card-menu.component.html',
  styleUrls: ['./project-card-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'project-card-menu',
  },
})
export class ProjectCardMenuComponent {
  constructor(private _store: Store) {}

  @Input({ required: true }) model!: IDomainModelDetails;
  @Input({ transform: booleanAttribute }) showProjectAdmin = false;

  @HostBinding('style.color')
  get setColour(): string {
    return contrast(this.model.primaryColor);
  }

  gotoProjectAdmin(): void {
    this.model.id &&
      this._store.dispatch(
        RouterActions.gotoProjectAdmin({ modelId: this.model.id })
      );
  }

  viewLicence(): void {
    this.model.id &&
      this._store.dispatch(
        WorkspaceActions.viewAcceptedModelDocuments({ modelId: this.model.id })
      );
  }

  allDataPresent(): boolean {
    return this.model.accepted && !isObjectEmpty(this.model.documents);
  }

  openReleaseNoteDialog(): void {
    this.model.id &&
      this._store.dispatch(
        openModelReleaseNoteDialog({ modelId: this.model.id })
      );
  }
}
