import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAppRoutingConfig } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { LOCALE } from '@configs';
import { httpInterceptorProviders } from '@core/interceptors';
import { CoreModule } from '@core/modules';
import { environment, provideEnvironmentProviders } from '@env/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(CoreModule),
    provideEnvironmentProviders(),
    provideAppRoutingConfig(),
    provideHttpClient(withInterceptorsFromDi()),
    httpInterceptorProviders,
    { provide: LOCALE_ID, useValue: LOCALE },
  ],
})
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }

    /*
    Prevent file drop globally apart from designated drop zones
    */
    const types = ['drop', 'dragover'] as const;
    types.forEach(type => {
      document.addEventListener(type, event => {
        event.preventDefault();
      });
    });
  })
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
