import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@core/services';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/.';
import { AuthSelectors } from '@store/selectors';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    private _authService: AuthService,
    private _store: Store
  ) {}

  /*
   * Check if user is already authenticated and auto login
   * or if access token provided to login with token
   */

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.canActivateChild(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
    let accessToken = childRoute?.queryParams['accessToken'];

    if (accessToken) {
      this._store.dispatch(AuthActions.loginWithToken({ accessToken }));
      return true;
    }

    if (this._authService.userIsAuthenticated()) {
      const userLoginResponse = this._authService.getLoginDetails();
      accessToken = userLoginResponse?.rosettaAuth.accessToken;
      if (accessToken) {
        this._store.dispatch(AuthActions.autoLogin({ accessToken }));
        return false;
      }
    }

    this._logout();
    return true;
  }

  private _logout(): void {
    this._store
      .select(AuthSelectors.isLoggedIn)
      .pipe(first())
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this._store.dispatch(AuthActions.logout());
        }
      });
  }
}
