import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  TemplateRef,
  computed,
  contentChild,
  input,
  signal,
} from '@angular/core';
import {
  RosettaHideItemDirective,
  RosettaShowHideTriggerDirective,
  RosettaShowItemDirective,
} from './rosetta-show-hide-trigger.directive';
import { RosettaShowHide } from './rosetta-show-hide.model';

@Component({
  selector: 'app-rosetta-show-hide',
  standalone: true,
  imports: [NgTemplateOutlet],
  template: `
    <div [style.opacity]="isComponentVisible() ? 1 : 0">
      <ng-container [ngTemplateOutlet]="shownItem()" />
    </div>
    <div [style.opacity]="isComponentVisible() ? 0 : 1">
      <ng-container [ngTemplateOutlet]="hiddenItem()" />
    </div>
  `,
  styleUrl: './rosetta-show-hide.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaShowHideComponent implements RosettaShowHide {
  constructor(@Optional() _parent: RosettaShowHideTriggerDirective) {
    if (_parent) {
      _parent.register(this);
    }
  }

  disabled = input(false);
  shownItem = contentChild(RosettaShowItemDirective, { read: TemplateRef });
  hiddenItem = contentChild(RosettaHideItemDirective, { read: TemplateRef });
  protected showState = signal(true);
  protected isComponentVisible = computed(
    () => this.disabled() || this.showState()
  );

  toggle(): void {
    this.setShowState(!this.showState());
  }

  setShowState(value: boolean): void {
    if (this.disabled()) {
      return;
    }
    this.showState.set(value);
  }
}
