import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { FontsModule } from '@app/fonts/fonts.module';
import { SnackBarActionType, SnackBarConfig } from '@models';
import { Store } from '@ngrx/store';
import { StripHtmlPipe } from '@shared/pipes/strip-html.pipe';

@Component({
  selector: 'app-snack-bar-component',
  standalone: true,
  imports: [FontsModule, MatButtonModule, StripHtmlPipe],
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  constructor(
    private _store: Store,
    private _snackBar: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarConfig
  ) {}

  readonly snackBarActionType = SnackBarActionType;

  close(type: SnackBarActionType): void {
    switch (type) {
      case SnackBarActionType.LEGACY:
        this._snackBar.dismissWithAction();
        break;
      case SnackBarActionType.ACTION:
        if (this.data.snackBarAction) {
          this._store.dispatch(this.data.snackBarAction.action());
        }
        this._snackBar.dismiss();
        break;
      default:
        this._snackBar.dismiss();
    }
  }
}
