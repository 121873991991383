import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, catchError, ignoreElements, of } from 'rxjs';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';

@Component({
  standalone: true,
  imports: [AsyncPipe, LoadingSpinnerComponent, NgTemplateOutlet],
  selector: 'app-async-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'stream-container' },
  templateUrl: './async-container.component.html',
})
export class AsyncContainerComponent<TData = unknown, TError = unknown> {
  @ContentChild('mainTemplate') mainTemplate!: TemplateRef<{
    $implicit: TData;
  }>;
  @ContentChild('errorTemplate') errorTemplate!: TemplateRef<{
    $implicit: TError;
  }>;

  @Input() set stream(value$: Observable<TData>) {
    if (value$) {
      this.stream$ = value$;
      this.streamError$ = this.stream$.pipe(
        ignoreElements(),
        catchError(err => of(err))
      );
    }
  }

  @Input()
  spinnerOnLoading = true;

  stream$?: Observable<TData>;
  streamError$?: Observable<TError>;
}
