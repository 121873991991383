<ng-template #defaultButton>
  <button mat-icon-button>
    <fa-icon icon="ellipsis-v" />
  </button>
</ng-template>

@if (menuItems()?.length) {
  <div [matMenuTriggerFor]="moreOptionsMenu">
    <ng-container [ngTemplateOutlet]="mainTemplate() || defaultButton" />
  </div>
}

<mat-menu #moreOptionsMenu="matMenu" xPosition="before">
  <ng-template matMenuContent>
    @for (item of menuItems(); track item.label) {
      @if (!(item?.$hiddenItem && item.$hiddenItem())) {
        <button
          mat-menu-item
          [tourAnchor]="
            item.tourAnchorId ? 'more-options.' + item.tourAnchorId : null
          "
          [class]="item.buttonClasses"
          [disabled]="item?.$disabled && item.$disabled()"
          (click)="item.onClick()"
        >
          @if (!!item.icon) {
            <fa-icon
              class="mr-8"
              [class]="item.iconClasses || 'theme-color-primary'"
              [icon]="item.icon"
            />
          }
          <span>{{ item.label }}</span>
        </button>
      }
    }
  </ng-template>
</mat-menu>
