<app-status [state]="state()" />
<div class="status-text">
  <strong class="color-75">{{ state().text }}</strong>
  @if (description(); as description) {
    <small @inOutAnimation class="color-50 text-ellipsis">
      {{ description }}
    </small>
  }
</div>

@if (canRerun()) {
  <button mat-stroked-button class="ml-16" @slideIn (click)="onRerun()">
    <fa-icon icon="arrow-rotate-right" />
    <span>Rerun</span>
  </button>
}
