/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  booleanAttribute,
  input,
  numberAttribute,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  imports: [MatProgressSpinnerModule],
  selector: 'app-loading-spinner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <mat-spinner
      mode="indeterminate"
      color="primary"
      [diameter]="diameter()"
      [strokeWidth]="strokeWidth()"
    />
  `,
  styles: `
    // We can't use :host here as there is no view encapsulation
    .loading-spinner {
      display: inline-block;
      vertical-align: middle;

      &-centre {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      .mat-mdc-progress-spinner {
        margin: auto;
      }
    }
  `,
  host: {
    class: 'loading-spinner',
    '[class.loading-spinner-centre]': 'flexCentre()',
    '[class]': 'classes()',
  },
})
export class LoadingSpinnerComponent {
  diameter = input(36, { transform: numberAttribute });
  strokeWidth = input(3, { transform: numberAttribute });
  flexCentre = input(false, { transform: booleanAttribute });
  classes = input<string>();
}
