import { toSignal } from '@angular/core/rxjs-interop';
import { arraysContainSameValues, naturalSort } from '@utils/array-utils';
import { catchErrorAndReturn, mapToVoid } from '@utils/operators';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  debounceTime,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { TransformSelectorConfig } from '../transform/models/transform-config.model';
import {
  IRosettaSelector,
  IRosettaSelectorData,
} from './rosetta-selector.model';

/*
 A service that is used with the Rosetta Selector component.

 The selector needs to define its own method for fetching data it needs to display and
 adaptors for mapping to a standardised internal object which the component can use.
 */
export abstract class RosettaSelectorBase<TData = any>
  implements IRosettaSelector<TData>
{
  constructor(protected _config: TransformSelectorConfig) {}

  /*
  Subject variables
   */
  private _optionsSubject = new BehaviorSubject<TData[]>([]);
  private _selectedIdSubject = new ReplaySubject<string | undefined>(1);
  private _dataLoadingSubject = new BehaviorSubject(false);
  private _refreshOnToggleSubject = new BehaviorSubject<void>(undefined);

  /*
   Getters
   */
  get label(): string {
    return this._config?.label;
  }

  get placeholder(): string {
    return this._config?.placeholder;
  }

  get key(): string {
    return this._config?.key;
  }

  abstract get value(): string;
  abstract set value(value: string);

  /*
  Public API used by selector.component and other IRosettaSelector
   */
  selectedId$ = this._selectedIdSubject.pipe(distinctUntilChanged());
  selectedValue$ = this.selectedId$.pipe(
    map(itemId => this._getItemFromValue(itemId))
  );
  dataLoading$ = this._dataLoadingSubject.pipe(
    // Debounce to prevent UI spinner from flashing
    debounceTime(500)
  );

  $selectedValue = toSignal(this.selectedValue$);
  tourAnchor = this._config?.tourAnchor;

  options$ = this._optionsSubject.pipe(
    map(options => naturalSort(this._convertResponse(options), 'label')),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  updateSelection(selectedItemId: string | null): void {
    this._selectedIdSubject.next(selectedItemId);
  }

  // TODO: refactor to remove this method and make it auto fetch
  fetchOptions(dataFromTrigger?: any): Observable<void> {
    return this._refreshOnToggleSubject.pipe(
      tap(() => this._dataLoadingSubject.next(true)),
      switchMap(() =>
        this.fetchData(dataFromTrigger).pipe(
          first(),
          catchErrorAndReturn([] as TData[]),
          map(data => this._updateOptionsIfNecessary(data)),
          mapToVoid()
        )
      ),
      tap(() => this._dataLoadingSubject.next(false))
    );
  }

  onToggle(isOpen: boolean): void {
    if (isOpen && this._config.refreshOnOpen) {
      this._refreshOnToggleSubject.next();
    }
  }

  getListItemValue = (item: TData): string => item as string;
  getListItemLabel = (item: TData): string => item as string;

  findDataItemFromValue = (
    value: string
  ): IRosettaSelectorData<TData> | undefined => {
    const item = this._getItemFromValue(value);
    if (!item) {
      return undefined;
    }
    const [dataItem] = this._convertResponse([item]);
    return dataItem;
  };

  resetSelection(): void {
    this.updateSelection(undefined);
    this._optionsSubject.next([]);
  }

  /*
   Abstract methods
   */
  /* Defines where the data is coming from and is used by the Rosetta select component */
  protected abstract fetchData(data: unknown): Observable<TData[]>;

  /*
  Protected Methods
   */

  /*
  Private Methods for internal use only
  */
  private _getItemFromValue(value: string | null): TData | undefined {
    const options = this._optionsSubject.getValue();
    return options.find(option => this.getListItemValue(option) === value);
  }

  private _restoreSelection(): void {
    this.updateSelection(this.value);
  }

  private _convertResponse(dataList: TData[]): IRosettaSelectorData<TData>[] {
    return dataList.map(data => ({
      value: this.getListItemValue(data),
      label: this.getListItemLabel(data),
      data,
    }));
  }

  private _updateOptionsIfNecessary(options: TData[]): void {
    const newOptions = options?.map(item => this.getListItemValue(item));
    const currentOptions = this._optionsSubject
      .getValue()
      ?.map(item => this.getListItemValue(item));

    // To prevent loading spinner from appearing unnecessarily in UI, only update options if different
    if (!arraysContainSameValues(newOptions, currentOptions)) {
      this._optionsSubject.next(options);
      this._restoreSelection();
    }
  }
}
