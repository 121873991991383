import { Inject, Injectable } from '@angular/core';
import {
  BaseWorkspaceStateService,
  WorkspaceChangeEventType,
} from '@core/services';
import { dialogResultOperator } from '@core/services/base-defer-dialog.service';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { TaskStatus } from '@models';
import * as transform from '@shared/components/transform/models/data-viewer';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { fileTypeToMimeType } from '@utils/file-utils';
import {
  catchErrorAndReturn,
  filterNotNulls,
  mapToVoid,
} from '@utils/operators';
import { kebabCase } from 'lodash-es';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  first,
  last,
  map,
  merge,
  share,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { TransformDialogService } from '../dialogs/transform-dialog.service';
import { TransformOpenSampleDialogData } from '../dialogs/transform-open-sample-dialog/transform-open-sample-dialog.component';
import { isLegacyIngest } from '../helpers/ingest.helpers';
import { pipelineCatchError } from '../helpers/pipeline-catch-error.helper';
import { JsonSample, NewSample, PipelineRunInfo } from '../models';
import { PipelineRunData } from '../models/pipeline-run-date.model';
import { SampleRowAction } from '../models/sample-action.enum';
import { TestPackGridSelection } from '../models/test-pack-grid-selection.model';
import { TestPackGrid } from '../models/test-pack-grid.model';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '../models/transform-config.model';
import { TransformType } from '../models/transform-type.enum';
import { TransformSelectorManagerService } from './selectors/transform-selector-manager.service';
import { TransformStorageService } from './transform-storage.service';

@Injectable()
export class TransformService extends BaseWorkspaceStateService {
  constructor(
    private _navigate: RosettaNavigationService,
    private _transformStorage: TransformStorageService,
    private _transformSelectorManager: TransformSelectorManagerService,
    private _transformDialogService: TransformDialogService,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {
    super({ changeEventType: WorkspaceChangeEventType.ENGINE });
    this._setTransformTypeName();
  }

  private _pipelineCurrentRunResult = new Subject<
    PipelineRunInfo<PipelineRunData>
  >();
  private _rerunSubject = new Subject<TestPackGridSelection | null>();
  private _fileName: string;

  private _completeSelection$ =
    this._transformSelectorManager.testPackGridSelection$.pipe(
      map(selection => this._handleSelection(selection)),
      share<TestPackGridSelection | null>()
    );

  private _runResult$ = merge(
    this._completeSelection$,
    this._rerunSubject
  ).pipe(
    filterNotNulls(),
    switchMap((testPackGridSelection: TestPackGridSelection) =>
      this.onChanged().pipe(
        switchMap(() =>
          this._runTestPackPipelineAndGetResult(testPackGridSelection)
        )
      )
    )
  );
  private _emptyResult$: Observable<PipelineRunInfo> =
    this._completeSelection$.pipe(
      filter(selection => selection === null),
      map(() => ({ result: null, errorMessage: null }))
    );

  markRunAsStale$ = this._store
    .select(WorkspaceSelectors.getWorkspaceTaskStatus)
    .pipe(
      distinctUntilChanged(),
      filter(status => status === TaskStatus.Started),
      map(() => {
        const data: PipelineRunInfo = {
          result: null,
          errorMessage: null,
          stale: true,
        };

        return data;
      })
    );

  resultSource$: Observable<PipelineRunInfo<TestPackGrid>> = merge(
    this._runResult$,
    this._emptyResult$
  );

  // On workspace switch and task finished to emit true
  // if not emit false
  // react to workspace switch and then listen for task finished and complete
  transformReady$ = this.workspaceSwitchAndReady();

  isSupported$ = this.workspaceSwitchAndReady().pipe(
    switchMap(() => this._transformStorage.getPipelines()),
    map(() => true),
    catchErrorAndReturn(false)
  );

  transformTypeName!: string;
  runResultState$ = merge(
    this._pipelineCurrentRunResult,
    this.markRunAsStale$
  ).pipe(share());

  getFileName = (): string => {
    return this._fileName || `${this.transformTypeName.toLowerCase()}-download`;
  };

  override onWorkspaceSwitch(): void {
    this._transformSelectorManager.resetAll();
    this._pipelineCurrentRunResult.next(null);
    this._rerunSubject.next(null);
  }

  rerun(): void {
    this._rerunSubject.next(
      this._transformSelectorManager.testPackGridSelection
    );
  }

  goToTransformDetails(
    testPackGridSelection: TestPackGridSelection,
    sampleId: string
  ): void {
    const { pipelineDef, testPackDef } = testPackGridSelection;
    this._navigate.updateBottomPanel([
      this._transformConfig.url,
      pipelineDef.id,
      testPackDef.id,
      sampleId,
    ]);
  }

  processTransformEvent(event: transform.DataViewerEvent): Observable<void> {
    const actionTypeMap = {
      [SampleRowAction.Accept]: this._confirmUpdateDiffs,
      [SampleRowAction.Revert]: this._confirmRevertDiffs,
      [SampleRowAction.Delete]: this._deleteSample,
      [SampleRowAction.Copy]: this._openSample('copy'),
      [SampleRowAction.Update]: this._openSample('edit'),
    } as const;

    if (!actionTypeMap[event.type]) {
      throw new Error('Unknown action type: ' + event.type);
    }

    return actionTypeMap[event.type].apply(this, [event]);
  }

  deleteTestPack(selection: TestPackGridSelection): Observable<void> {
    return this._transformStorage.deleteTestPack(selection);
  }

  private _setTransformTypeName(): void {
    if (this._transformConfig.type === TransformType.LegacyIngest) {
      this.transformTypeName = 'Ingest';
      return;
    }
    this.transformTypeName = this._transformConfig.type;
  }

  private _setFileName(selection: TestPackGridSelection): void {
    this._fileName = kebabCase(
      `${selection.pipelineDef.name}-${selection.testPackDef.name}`
    );
  }

  private _sampleIdsFromEvent(event: transform.DataViewerEvent): string[] {
    return event.rows.map(({ sampleId }) => sampleId);
  }

  private _firstSampleFromEvent(event: transform.DataViewerEvent): string {
    return event.rows[0].sampleId;
  }

  private _modifyConfirmationDialog<T>(
    event: transform.DataViewerEvent,
    stream$: Observable<T>
  ): Observable<T> {
    return this._transformDialogService.openModifyExpectations(event).pipe(
      dialogResultOperator(() => true),
      switchMap(result => (result ? stream$.pipe(first()) : EMPTY))
    );
  }

  private _confirmUpdateDiffs(
    event: transform.DataViewerEvent
  ): Observable<void> {
    return this._modifyConfirmationDialog(
      event,
      this._transformStorage
        .updateSampleDiffs(
          event.testPackGridSelection,
          this._sampleIdsFromEvent(event)
        )
        .pipe(
          tap(() => this.rerun()),
          catchError(() =>
            throwError(() => new Error('Samples could not be updated'))
          )
        )
    );
  }

  private _confirmRevertDiffs(
    event: transform.DataViewerEvent
  ): Observable<void> {
    return this._modifyConfirmationDialog(
      event,
      this._transformStorage
        .revertSample(
          event.testPackGridSelection,
          this._sampleIdsFromEvent(event)
        )
        .pipe(
          tap(() => this.rerun()),
          catchError(() =>
            throwError(() => new Error('Samples could not be reverted'))
          )
        )
    );
  }

  private _openSample(
    mode: TransformOpenSampleDialogData['mode']
  ): (event: transform.DataViewerEvent) => Observable<void> {
    return event => {
      return this._transformStorage.getSampleJson(event).pipe(
        switchMap(({ sample, storedOnClient }) => {
          const newSample = this._createNewSample(
            event.testPackGridSelection,
            sample
          );
          return this._transformDialogService
            .openSample({
              mode,
              sample: newSample,
              storedOnClient,
            })
            .pipe(dialogResultOperator());
        }),
        tap(() => this.rerun()),
        mapToVoid()
      );
    };
  }

  private _createNewSample(
    selection: TestPackGridSelection,
    sample: JsonSample
  ): NewSample {
    const language = fileTypeToMimeType(
      selection.pipelineDef.inputSerialisationFormat
    );
    return new NewSample(
      sample.sampleDef,
      {
        value: sample.json,
        language,
      },
      selection
    );
  }

  private _deleteSample(event: transform.DataViewerEvent): Observable<void> {
    if (event.rows.length > 1) {
      throw new Error(
        'Unable to delete due to multiple samples: ' + event.rows.length
      );
    }

    return this._modifyConfirmationDialog(
      event,
      this._transformStorage
        .deleteSample(
          event.testPackGridSelection,
          this._firstSampleFromEvent(event)
        )
        .pipe(
          tap(() => this.rerun()),
          catchError(error =>
            throwError(
              () =>
                new Error(
                  `Unable to delete sample from '${event.testPackGridSelection.pipelineDef.name}/${event.testPackGridSelection.testPackDef.name}' due to an unexpected error: ${error}`
                )
            )
          )
        )
    );
  }

  private _runTestPackPipelineAndGetResult(
    testPackGridSelection: TestPackGridSelection
  ): Observable<PipelineRunInfo<TestPackGrid>> {
    return this._getRunStream(testPackGridSelection).pipe(
      pipelineCatchError(),
      tap(runResult => this._pipelineCurrentRunResult.next(runResult)),
      last()
    );
  }

  private _getRunStream(
    testPackGridSelection: TestPackGridSelection
  ): Observable<PipelineRunInfo<PipelineRunData>> {
    if (isLegacyIngest(testPackGridSelection)) {
      return this._transformStorage.runIngest(testPackGridSelection);
    }
    return this._transformStorage.runTestPackPipeline(testPackGridSelection);
  }

  private _handleSelection(
    selection: TestPackGridSelection | null
  ): TestPackGridSelection | null {
    if (selection) {
      this._setFileName(selection);
    } else {
      this._pipelineCurrentRunResult.next(null);
    }
    return selection;
  }
}
