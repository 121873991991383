import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { FontsModule } from '@app/fonts/fonts.module';
import { AppThemes, RouteData } from '@models';
import { LogoComponent } from '@shared/components/logo/logo.component';
import {
  ClickToOpenUpgradeDialogDirective,
  ShowUpgradeOfferDirective,
} from '@shared/directives';
import { startWith } from 'rxjs';
import { HelpMenuComponent } from '../components/help-menu/help-menu.component';
import { NotificationMenuComponent } from '../components/notification-menu/notification-menu.component';
import { RefreshButtonComponent } from '../components/refresh-button/refresh-button.component';
import { TextComponent } from '../components/text/text.component';
import { UserMenuComponent } from '../components/user-menu/user-menu.component';
import { ViewContainerRefDirective } from '../directives/view-container-ref.directive';

type RouteDataTypes = undefined | null | string | Type<any>;

@Component({
  standalone: true,
  imports: [
    MatToolbarModule,
    LogoComponent,
    RefreshButtonComponent,
    FontsModule,
    RouterOutlet,
    HelpMenuComponent,
    NotificationMenuComponent,
    UserMenuComponent,
    ViewContainerRefDirective,
    ClickToOpenUpgradeDialogDirective,
    ShowUpgradeOfferDirective,
  ],
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  @ViewChild(ViewContainerRefDirective, { static: true })
  view!: ViewContainerRefDirective;

  private _currentData: RouteDataTypes;
  readonly appThemes = AppThemes;

  ngOnInit(): void {
    this._router.events
      .pipe(startWith(new NavigationEnd(0, '', '')))
      .subscribe(event => {
        const data = this._getFirstRouteData(this._route.snapshot);

        if (data !== this._currentData && event instanceof NavigationEnd) {
          this.view.viewContainerRef.clear();
          this._currentData = data;
          this._setTitle(data);
        }
      });
  }

  private _setTitle(data: RouteDataTypes): void {
    if (!data) {
      return;
    }

    if (typeof data === 'string') {
      this._loadComponent(TextComponent, data);
    } else {
      this._loadComponent(data);
    }
  }

  private _getFirstRouteData(
    snapshot: ActivatedRouteSnapshot | null
  ): RouteDataTypes {
    let currentSnapshot = snapshot;
    while (currentSnapshot) {
      const navBarTitle = (currentSnapshot.data as RouteData)?.navBarTitle;
      if (navBarTitle) {
        return navBarTitle;
      }
      currentSnapshot = currentSnapshot.firstChild;
    }
    return null;
  }

  private _loadComponent(component: any, data?: any): void {
    const componentRef =
      this.view.viewContainerRef.createComponent<any>(component);

    if (data) {
      componentRef.setInput('text', data);
    }
  }
}
