/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { BaseDeferDialogService } from '@core/services/base-defer-dialog.service';
import * as transform from '@shared/components/transform/models/data-viewer';
import { PipelineDef } from '../models';
import { TestPackGridSelection } from '../models/test-pack-grid-selection.model';
import { TransformOpenSampleDialogData } from './transform-open-sample-dialog/transform-open-sample-dialog.component';

@Injectable()
export class TransformDialogService extends BaseDeferDialogService {
  openSample(data: TransformOpenSampleDialogData) {
    return this.lazyOpenDialog(
      () =>
        import(
          './transform-open-sample-dialog/transform-open-sample-dialog.component'
        ).then(m => m.TransformOpenSampleDialogComponent),
      data
    );
  }

  openAddSample(data: Partial<TestPackGridSelection>) {
    return this.lazyOpenDialog(
      () =>
        import(
          './transform-add-sample-dialog/transform-add-sample-dialog.component'
        ).then(m => m.TransformAddSampleDialogComponent),
      data
    );
  }

  openAddTestPack(data: PipelineDef) {
    return this.lazyOpenDialog(
      () =>
        import(
          './transform-add-test-pack-dialog/transform-add-test-pack-dialog.component'
        ).then(m => m.TransformAddTestPackDialogComponent),
      data
    );
  }

  openModifyExpectations(data: transform.DataViewerEvent) {
    return this.lazyOpenDialog(
      () =>
        import(
          './modify-expectation-dialog/modify-expectation-dialog.component'
        ).then(m => m.ModifyExpectationDialogComponent),
      data
    );
  }
}
