import { deepMerge } from '@utils/object-utils';
import { map, OperatorFunction } from 'rxjs';
import { TestPack } from '../test-pack.model';
import { TransformDTO } from '../transform-dto.model';

const innerFromTestPackDefDTO = (dto: TransformDTO.TestPack): TestPack =>
  deepMerge({}, dto, {
    testPackDef: { id: dto.testPackDef.id.id },
    storedOnClient: false,
  });

export const fromTestPacksDTO = (): OperatorFunction<
  TransformDTO.TestPack[],
  TestPack[]
> => {
  return map(dtoList => dtoList.map(innerFromTestPackDefDTO));
};

export const fromTestPackDTO = (): OperatorFunction<
  TransformDTO.TestPack,
  TestPack
> => {
  return map(innerFromTestPackDefDTO);
};
