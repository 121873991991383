<ng-container *ngrxLet="pipelineRunInfo$ | async as value">
  @if (value?.errorMessage) {
    <app-rosetta-info-message type="error">{{
      value.errorMessage
    }}</app-rosetta-info-message>
  }

  <app-rosetta-table
    [tableId]="dataViewerId"
    [rowData]="value?.result.data?.rows || []"
    [colDefs]="cols()"
    [selectionColDef]="selectionCol()"
    [options]="tableOptions"
    [gridOptions]="gridOptions"
    (rowClicked)="rowClicked.emit($event)"
  >
    <header class="width-full">
      <!-- Content Project main slot -->
      <ng-content />

      <app-action-bar
        [selectedRows]="selectionChanged()"
        [loading]="loading()"
        [showChanges]="context.showChanges"
        (applyAction)="onActionBarEvent($event)"
      />

      <div class="spacer"></div>

      <mat-slide-toggle
        class="mr-16 color-50 white-space-nowrap"
        [disabled]="loading()"
        [checked]="context.showChanges"
        (change)="toggleShowChanges()"
        labelPosition="before"
        >Show Changes</mat-slide-toggle
      >

      <!-- Content project extra action buttons for the table -->
      <ng-content select="[dataViewerActions]" />
    </header>

    <app-no-data-message
      rosettaNoCols
      [showAddSampleAction]="showAddSampleBtn()"
    />

    <ng-container rosettaTableBanner>
      <ng-content select="[dataViewerBanner]" />
      @if (tabulatorUnsupported()) {
        <app-rosetta-info-message type="warning" class="mb-16" dataViewerBanner
          >Tabulated data cannot be displayed. You can still click on a sample
          row to view its details.</app-rosetta-info-message
        >
      }
    </ng-container>

    <app-summary summary [summaryData]="summary$ | async" />
  </app-rosetta-table>
</ng-container>
