import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PATH_JOIN_CHAR_WITH_SPACE } from '@configs';
import { CopyToClipboardComponent } from '@shared/components/copy-to-clipboard/copy-to-clipboard.component';
import { GoToSymbolDirective } from '@shared/directives';
import { FlatCodeViewNode, NodeNameType } from '../../models/code-view.model';

@Component({
  selector: 'app-code-view-selected',
  standalone: true,
  imports: [MatTooltipModule, GoToSymbolDirective, CopyToClipboardComponent],
  templateUrl: './code-view-selected.component.html',
  styleUrls: ['./code-view-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeViewSelectedComponent {
  modelContent = input(false);
  node = input<FlatCodeViewNode | null>(null);
  breadCrumbTrail = computed(() =>
    this.buildBreadCrumbTrail(this.node()?.path)
  );

  buildBreadCrumbTrail(path: NodeNameType[] = []): string {
    return path.map(p => p.name).join(PATH_JOIN_CHAR_WITH_SPACE);
  }
}
