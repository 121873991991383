import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { AuthService } from '@core/services';
import { Observable, map } from 'rxjs';
import { UserCredentials, UserLoginResponse } from '..';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  constructor(
    private _http: HttpClient,
    private _authService: AuthService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  login(credentials: UserCredentials): Observable<UserLoginResponse> {
    return this._http
      .post<UserLoginResponse>(
        `${this._config.resourcePaths.userServer}/auth/login`,
        credentials
      )
      .pipe(
        map(userLoginResponse =>
          this._authService.saveUserLoginResponse(userLoginResponse)
        )
      );
  }

  loginAccessToken(accessToken: string): Observable<UserLoginResponse> {
    return this._http
      .get<UserLoginResponse>(
        `${this._config.resourcePaths.userServer}/auth/login`,
        {
          params: new HttpParams({
            fromObject: {
              accessToken,
            },
          }),
        }
      )
      .pipe(
        map(userLoginResponse =>
          this._authService.saveUserLoginResponse(userLoginResponse)
        )
      );
  }

  logout(): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.userServer}/auth/logout`,
      null
    );
  }

  acceptDocuments(documents: Record<string, string[]>): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.userServer}/accept-documents`,
      documents
    );
  }

  resetPassword(userName: string): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.userServer}/auth/reset-password`,
      {
        userName,
      }
    );
  }
}
