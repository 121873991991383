@if (opened) {
  <div
    class="flex-row-center small-button-wrapper"
    @inOutAnimation
    (@inOutAnimation.done)="animationDone()"
  >
    <fa-icon class="fa-icon-button" icon="search" [fixedWidth]="true" />
    <input
      #searchInput
      matInput
      class="spacer"
      placeholder="{{ placeholder || 'Filter' }}"
      (appInputValue)="updateInputValue($event)"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
    <button
      class="mr-8"
      [style.visibility]="searchInput.value.length > 0 ? 'visible' : 'hidden'"
      mat-icon-button
      appStopPropagation
      (click)="clear()"
    >
      <fa-icon icon="times" />
    </button>
  </div>
}
