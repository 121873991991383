import { inject } from '@angular/core';
import { UrlTree } from '@angular/router';
import { NOT_AUTHENTICATED_FALLBACK } from '@configs';
import { AuthService } from '@core/services';
import { LoadingState } from '@models';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/.';
import { AuthSelectors } from '@store/selectors';
import { catchErrorAndReturn } from '@utils/operators';
import { Observable, filter, first, map, tap } from 'rxjs';

export const AuthGuardFunc = (): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const authService = inject(AuthService);
  const fallbackRoute = inject(NOT_AUTHENTICATED_FALLBACK);

  return store.select(AuthSelectors.selectAuthFeature).pipe(
    tap(state => {
      if (!authService.userIsAuthenticated()) {
        throw new Error('User not authenticated!');
      }
      if (state.loginState.state === LoadingState.INITIAL) {
        store.dispatch(AuthActions.refreshUser());
      }
    }),
    filter(state => state.loginState.state === LoadingState.LOADED),
    first(),
    map(state => {
      if (state.user && authService.userIsAuthenticated()) {
        return true;
      }
      store.dispatch(AuthActions.logout());
      return false;
    }),
    catchErrorAndReturn(fallbackRoute)
  );
};
