import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreDialogService } from '@app/dialogs/core-dialog.service';
import { FontsModule } from '@app/fonts/fonts.module';
import { dialogResultOperator } from '@core/services/base-defer-dialog.service';
import { RosettaSelectorOptionsDirective } from '@shared/components/rosetta-selector/rosetta-selector-options.directive';
import { RosettaSelectorTriggerDirective } from '@shared/components/rosetta-selector/rosetta-selector-trigger.directive';
import {
  RosettaGroupOptions,
  RosettaSelectorComponent,
} from '@shared/components/rosetta-selector/rosetta-selector.component';
import {
  RosettaHideItemDirective,
  RosettaShowHideTriggerDirective,
  RosettaShowItemDirective,
} from '@shared/components/rosetta-show-hide/rosetta-show-hide-trigger.directive';
import { RosettaShowHideComponent } from '@shared/components/rosetta-show-hide/rosetta-show-hide.component';
import {
  ShowWhenWorkspaceDirective,
  StopPropagationDirective,
} from '@shared/directives';
import { switchMap } from 'rxjs';
import { TransformDialogService } from '../../dialogs/transform-dialog.service';
import { TestPack, TestPackDef, TransformStatus } from '../../models';
import { TransformType } from '../../models/transform-type.enum';
import { TransformSelectorManagerService } from '../../services/selectors/transform-selector-manager.service';
import { TransformService } from '../../services/transform.service';
import { TransformStatusComponent } from '../transform-status/transform-status.component';

@Component({
  selector: 'app-transform-data-viewer-selectors',
  standalone: true,
  imports: [
    FontsModule,
    MatButtonModule,
    MatTooltipModule,
    RosettaHideItemDirective,
    RosettaSelectorComponent,
    RosettaSelectorOptionsDirective,
    RosettaSelectorTriggerDirective,
    RosettaShowHideComponent,
    RosettaShowHideTriggerDirective,
    RosettaShowItemDirective,
    ShowWhenWorkspaceDirective,
    StopPropagationDirective,
    TransformStatusComponent,
  ],
  templateUrl: './transform-data-viewer-selectors.component.html',
  styleUrl: './transform-data-viewer-selectors.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransformDataViewerSelectorsComponent {
  constructor(
    private _dialogService: TransformDialogService,
    private _coreDialogService: CoreDialogService,
    private _transformService: TransformService,
    private _transformSelectManagerService: TransformSelectorManagerService
  ) {}

  readonly addedTestPack = TransformStatus.Added;
  readonly existingTestPack = TransformStatus.Existing;
  readonly pipelineGroupOptions: RosettaGroupOptions = {
    groupBy: 'transformType',
    getGroupLabel: result => {
      const resultMap: Record<string, string> = {
        [TransformType.Ingest]: 'Functions',
        [TransformType.LegacyIngest]: 'Synonym Sources',
      };
      return resultMap[result] || result;
    },
  };

  pipelineSelector = this._transformSelectManagerService.pipelineSelector;
  testPackSelector = this._transformSelectManagerService.testPackSelector;
  addTestPackDisabled = computed(
    () =>
      this.pipelineSelector.$selectedValue()?.transformType ===
      TransformType.LegacyIngest
  );

  deleteTestPack(testPackDef: TestPackDef): void {
    const pipelineDef =
      this._transformSelectManagerService.pipelineSelector.$selectedValue();
    this._coreDialogService
      .openConfirmation({
        title: 'Delete Test Pack',
        message:
          'This will permanently delete all samples in the test pack and any associated test packs.',
        confirmButtonText: 'Delete',
        confirmButtonIcon: 'trash',
      })
      .pipe(
        dialogResultOperator(),
        switchMap(() =>
          this._transformService.deleteTestPack({
            testPackDef,
            pipelineDef,
          })
        )
      )
      .subscribe(() => {
        this._transformSelectManagerService.refreshTestPacks();
      });
  }

  openAddTestPackDialog(): void {
    this._dialogService
      .openAddTestPack(this.pipelineSelector.$selectedValue())
      .pipe(dialogResultOperator())
      .subscribe(newTestPack => {
        this._transformSelectManagerService.refreshTestPacks(
          (newTestPack as TestPack).testPackDef
        );
      });
  }
}
