import { DOCUMENT } from '@angular/common';
import {
  Directive,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { WINDOW_BLUR_HEARTBEAT_TIMEOUT } from '@configs';
import { WebSocketService } from '@core/services/websocket.service';
import { Subscription, timer } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appHeartbeat]',
})
export class HeartbeatDirective implements OnInit, OnDestroy {
  constructor(
    private _webSocketService: WebSocketService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  private _sub?: Subscription;

  @HostListener('window:focus')
  windowFocus(): void {
    this._stopBlurTimer();
    this._webSocketService.startHeartbeat();
  }

  @HostListener('window:blur')
  windowBlur(): void {
    if (!this._sub) {
      this._sub = timer(WINDOW_BLUR_HEARTBEAT_TIMEOUT).subscribe(() => {
        this._webSocketService.stopHeartbeat();
      });
    }
  }

  ngOnInit(): void {
    if (this._document.hasFocus()) {
      this._webSocketService.startHeartbeat();
    }
  }

  ngOnDestroy(): void {
    this._stopBlurTimer();
    this._webSocketService.stopHeartbeat();
  }

  private _stopBlurTimer(): void {
    if (this._sub) {
      this._sub.unsubscribe();
      this._sub = undefined;
    }
  }
}
