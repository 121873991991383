import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { NotificationMessagePayload, PackagePlan } from '@models';
import { catchErrorAndReturn } from '@utils/operators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  constructor(
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  load(): Observable<NotificationMessagePayload[]> {
    return this._getPackagePlanNotifications();
  }

  private _getPackagePlanNotifications(): Observable<
    NotificationMessagePayload[]
  > {
    return this._http
      .get<{
        currentPlan: PackagePlan;
        seen: boolean;
      }>(`${this._config.resourcePaths.projectPlan}/changed`)
      .pipe(
        map(response => {
          if (response.seen) {
            return [];
          }

          const result: NotificationMessagePayload[] = [
            {
              id: '1',
              title: 'Package Plan Update',
              content: `Your package plan has changed to ${response.currentPlan}`,
              dismissible: true,
            },
          ];
          return result;
        }),
        catchErrorAndReturn([])
      );
  }

  setProjectPlanToSeen(): Observable<HttpResponse<void>> {
    return this._http.post<void>(
      `${this._config.resourcePaths.projectPlan}/set-seen`,
      null,
      {
        observe: 'response',
      }
    );
  }
}
