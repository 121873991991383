import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { StopPropagationDirective } from '@shared/directives';

@Component({
  selector: 'app-code-view-switch',
  standalone: true,
  imports: [
    FontsModule,
    MatButtonModule,
    MatTooltipModule,
    StopPropagationDirective,
  ],
  templateUrl: './code-view-switch.component.html',
  styleUrls: ['./code-view-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeViewSwitchComponent {
  @Input() showNative = false;
  @Output() showNativeChange = new EventEmitter<boolean>();

  @Output()
  viewChange = new EventEmitter<boolean>();

  @Output()
  download = new EventEmitter<void>();

  toggle(): void {
    const toggled = !this.showNative;
    this.viewChange.emit(toggled);
    this.showNativeChange.emit(toggled);
  }
}
