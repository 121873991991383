import { EMPTY, Observable, catchError, of } from 'rxjs';

/*
This operator catches and error and creates an observable
that emits a single value, null, and then completes.
This is useful when you don't want an observable stream to
be terminated when an error occurs
*/
export function catchErrorAndComplete<T>() {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(catchError(() => EMPTY));
}

export function catchErrorAndReturn<T, D>(defaultValue: D) {
  return (source: Observable<T>): Observable<T | D> =>
    source.pipe(catchError(() => of(defaultValue)));
}
