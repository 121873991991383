import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { HEX } from '@models';
import { contrast, lightenDarkenColor } from '@utils/string-utils';

@Component({
  selector: 'app-dev-banner',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<small>Development</small>`,
  styles: [
    `
      :host {
        display: block;
        text-transform: uppercase;
        text-align: center;
      }
    `,
  ],
  host: {
    class: 'dev-banner',
    '[style.color]': 'styleColor()',
    '[style.background]': 'backgroundColor()',
  },
})
export class DevBannerComponent {
  color = input.required<HEX>();
  styleColor = computed(() => contrast(this.color()));
  backgroundColor = computed(() => lightenDarkenColor(this.color(), 20));
}
