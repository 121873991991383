<div
  class="table-actions"
  [ngClass]="{
    'table-actions-available': options.canHideColumns || options.filter,
  }"
>
  @if (options.filter) {
    <app-text-input
      class="filter"
      [disabled]="filterDisabled"
      maxWidth="300"
      [value]="quickFilterText"
      (valueChange)="updateFilter($event)"
    >
      <button mat-icon-button textInputPrefix disabled>
        <fa-icon icon="filter" />
      </button>
    </app-text-input>
  }

  <ng-content select="header" />

  <div class="table-actions-fixed">
    @if (options.canHideColumns) {
      <app-table-column-menu [disabled]="noData$ | async" />
    }

    <app-more-options-menu
      tourAnchor="rosetta-table.options"
      [menuItems]="options.actions"
    >
      <ng-template>
        <button mat-stroked-button>
          <fa-icon icon="ellipsis-v" />
          <span>Options</span>
        </button>
      </ng-template>
    </app-more-options-menu>
  </div>
</div>

<ng-content select="[rosettaTableBanner]" />

<ng-content select="[summary]" />

@if (initialised() && this.colDefs?.length) {
  <ag-grid-angular
    #agGrid
    [animateRows]="true"
    [ngClass]="classes$ | async"
    [editType]="options.editType"
    [domLayout]="options.domLayout"
    [rowHeight]="options.rowHeight"
    [rowClassRules]="options.rowClassRules"
    [readOnlyEdit]="options.readOnlyEdit || null"
    [getRowId]="options.getRowId"
    [suppressRowHoverHighlight]="options.suppressRowHoverHighlight || null"
    [components]="components"
    [gridOptions]="gridOptionsMerged"
    [columnDefs]="colDefs"
    [selectionColumnDef]="selectionColDef"
    [defaultColDef]="defaultColDefMerged"
    [quickFilterText]="quickFilterText"
    [doesExternalFilterPass]="options.doesExternalFilterPass"
    [isExternalFilterPresent]="options.isExternalFilterPresent"
    [autoSizeStrategy]="options.autoSizeStrategy"
    [initialState]="initialState"
    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)"
    (stateUpdated)="onStateUpdated($event)"
  />
} @else {
  <ng-content select="[rosettaNoCols]" />
}
