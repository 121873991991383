import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FontsModule } from '@app/fonts/fonts.module';
import { Store } from '@ngrx/store';
import { AppActions } from '@store/.';
import { AppSelectors } from '@store/selectors';

@Component({
  standalone: true,
  imports: [AsyncPipe, FontsModule, MatSlideToggleModule],
  selector: 'app-theme-toggle',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <fa-icon icon="sun" [fixedWidth]="true" />
    <mat-slide-toggle
      tabIndex="-1"
      (toggleChange)="toggleTheme()"
      [checked]="isDarkTheme$ | async"
    />
    <fa-icon icon="moon" [fixedWidth]="true" />`,
  styles: [
    `
      :host {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        padding: 0.375rem 1rem 0.875rem;
      }
    `,
  ],
  host: {
    class: 'theme-toggle color-75',
  },
})
export class ThemeToggleComponent {
  constructor(private _store: Store) {}

  isDarkTheme$ = this._store.select(AppSelectors.isDarkTheme);

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopPropagation();
  }

  toggleTheme(): void {
    this._store.dispatch(AppActions.toggleTheme());
  }
}
