<button
  mat-icon-button
  [appRosettaOverlayTarget]="overlay"
  *ngrxLet="numberNotifications$ as numberNotifications"
>
  <span
    [matBadge]="numberNotifications"
    [matBadgeHidden]="numberNotifications === 0"
    matBadgeSize="small"
    matBadgeColor="accent"
  >
    <fa-icon [icon]="['far', 'bell']" size="lg" />
  </span>
</button>

<app-rosetta-overlay #overlay>
  <app-rosetta-menu maxWidth="380">
    <div *appShowSpinner="hasLoaded$ | async">
      @if (notificationError$ | async) {
        <app-rosetta-info-message type="error" class="mb-8">
          <small
            >There was an error fetching your notifications.
            <a
              class="inline-link"
              href="javascript: void(0);"
              (click)="fetchNotifications()"
              >Retry</a
            ></small
          >
        </app-rosetta-info-message>
      }

      @if (notifications$ | async; as notifications) {
        @if (notifications.length === 0) {
          <div>
            <p class="mb-0 text-align-center">You have no new notifications</p>
          </div>
        }

        @for (notification of notifications; track notification.id) {
          <div class="notification">
            <div class="notification__text">
              <h3 class="font-weight-semi-bold mb-0">
                {{ notification.title }}
              </h3>
              <p>{{ notification.content }}</p>
            </div>

            @if (notification.dismissible) {
              <button
                class="notification__action"
                mat-stroked-button
                (click)="dismissNotification(notification.id)"
              >
                Dismiss
              </button>
            }
          </div>
        }
      }
    </div>
  </app-rosetta-menu>
</app-rosetta-overlay>
