import { Id } from '@models/id.model';
import { ServerError } from '@models/server-error.model';
import { SampleRowAction } from './sample-action.enum';
import { SampleCellState } from './sample-cell-state.enum';
import { SampleRowState } from './sample-row-state.enum';
import { TransformStatus } from './sample-row-status.enum';
import { TransformType } from './transform-type.enum';

/*
NOTE: DTOs (Data Transfer Objects) are defined by the backend and the frontend types should be kept in sync.
This ensures that the data being exchanged between the two systems is consistent and accurate.
*/
export namespace TransformDTO {
  export interface PipelineDef {
    readonly id: Id;
    readonly name: string;
    readonly transformInputType: string;
    readonly transformFunction: string;
    readonly transformType: TransformType;
    readonly inputSerialisationFormat: SerialisationFormat;
    readonly outputSerialisationFormat: SerialisationFormat;

    // TODO: The following properties are only being used in the backend and is irrelevant
    // to the UI. We should separate the DTO from types that are only used in the backend.
    readonly outputSerialisationConfigPath?: string;
    readonly translatorOptions?: any;
  }

  export interface TestPackDef {
    readonly id: Id;
    readonly name: string;
  }

  export interface TestPack {
    readonly testPackDef: TestPackDef;
    readonly status: TransformStatus;
  }

  export interface CreateTestPack {
    name: string;
  }

  export interface TestPackGridSelection {
    readonly pipelineDef: PipelineDef;
    readonly testPackDef: TestPackDef;
  }

  export interface TestPackGrid {
    readonly pipelineDef: PipelineDef;
    readonly testPackDef: TestPackDef;
    readonly rows: SampleRowDTO[];
  }

  export interface TestPackSampleId {
    readonly selection: TestPackGridSelection;
    readonly sampleDef: SampleDef;
  }

  export interface SampleDef {
    readonly id: Id;
    readonly name: string;
  }

  export interface SampleCellDTO {
    readonly columnId: string;
    readonly name?: string;
    readonly pathElements: string[];
    readonly cellState: SampleCellState;
    readonly outputValue?: string;
    readonly expectedBaseValue?: string;
    readonly expectedUpdatedValue?: string;
  }

  export interface SampleRowDTO {
    readonly id: Id;
    readonly name: string;
    readonly state: SampleRowState;
    readonly status: TransformStatus;
    readonly cells: SampleCellDTO[];
    readonly actions: SampleRowAction[];
    readonly diagnostics?: Diagnostics;
    readonly errorMessage?: string;
  }

  export interface JsonSample {
    readonly sampleDef: SampleDef;
    readonly json?: string;
    readonly runtimeError?: boolean;
  }

  export interface TransientTestPack {
    readonly testPackName: string;
    readonly samples: JsonSample[];
  }

  export interface TransientSample {
    readonly testPackName: string;
    readonly sample: JsonSample;
  }

  export interface RosettaPath {
    readonly value: string[];
  }

  export interface DiagnosticResult {
    readonly name: DiagnosticKey;
    readonly actual: number;
    readonly expected?: number;
    readonly details?: DiagnosticResultDetails[];
  }

  export interface DiagnosticResultDetails {
    readonly inputPath: RosettaPath;
    readonly outputPaths?: RosettaPath[];
    readonly issues?: string[];
    readonly category?: string;
    readonly name?: string;
  }

  export type DiagnosticKey = 'successes' | 'failures' | 'excluded';

  export type DiagnosticResults = Readonly<
    Partial<Record<DiagnosticKey, DiagnosticResult>>
  >;

  export enum DiagnosticType {
    Mapping = 'MAPPING',
    Validation = 'VALIDATION',
    Qualification = 'QUALIFICATION',
  }

  export interface DiagnosticRecord {
    readonly type: DiagnosticType;
    readonly results: DiagnosticResults;
  }

  export interface DiagnosticRecords {
    readonly mapping?: DiagnosticRecord;
    readonly validation?: DiagnosticRecord;
    readonly qualification?: DiagnosticRecord;
  }

  export interface Diagnostics {
    readonly overallSuccess: boolean;
    readonly diagnosticRecords: DiagnosticRecords;
  }

  // Translate 1.5 API
  export interface GeneratedSynonymSource {
    readonly codeBase: string;
    readonly srcPath: string;
  }

  export interface SynonymSourceState {
    readonly compiled: boolean;
  }

  interface SynonymSourceCompilationResultSuccess {
    readonly success: true;
    readonly codeBase: string;
  }

  interface SynonymSourceCompilationResultFailure {
    readonly success: false;
    readonly codeBase: string;
    readonly compileErrors: ServerError;
  }

  export type SynonymSourceCompilationResult =
    | SynonymSourceCompilationResultSuccess
    | SynonymSourceCompilationResultFailure;

  export enum SerialisationFormat {
    JSON = 'JSON',
    XML = 'XML',
  }
}
