import {
  AfterViewInit,
  Attribute,
  Directive,
  OnDestroy,
  Self,
} from '@angular/core';
import { StorageKey } from '@configs';
import { LocalStorageService } from '@core/services';
import { IOutputData, SplitComponent } from 'angular-split';
import { Subscription } from 'rxjs';

@Directive({
  standalone: true,
  selector: 'as-split[appSplitSaveSize]',
})
export class SplitSaveSizeDirective implements AfterViewInit, OnDestroy {
  constructor(
    @Self() private _split: SplitComponent,
    private _storageService: LocalStorageService,
    @Attribute('appSplitSaveSize') private _configName: string
  ) {}

  private _sub = new Subscription();

  private get _config() {
    return (
      this._storageService.getItem<Record<string, number>>(
        StorageKey.SplitPanelConfig
      ) || {}
    );
  }

  private set _config(data: Record<string, number>) {
    this._storageService.setItem(StorageKey.SplitPanelConfig, data);
  }

  ngAfterViewInit(): void {
    this._initSize();
    this._sub.add(
      this._split.dragEnd.subscribe(outputData => this._onDragEnd(outputData))
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private _initSize(): void {
    const percent = this._config[this._configName];
    this._setAreaSize(parseInt(`${percent}`, 10));
  }

  private _setAreaSize(size: number) {
    const [top, bottom] = this._split.displayedAreas;
    if (!bottom || !size) {
      return;
    }
    bottom.component.size = size;
    top.component.size = 100 - size;
  }

  private _onDragEnd(outputData: IOutputData): void {
    const size = outputData.sizes[outputData.gutterNum] as number;
    this._config = Object.assign(this._config, {
      [this._configName]: size.toFixed(2),
    });
    this._setAreaSize(size);
  }
}
