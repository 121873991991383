import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  booleanAttribute,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  standalone: true,
  imports: [MatButtonModule, FontsModule],
  selector: 'app-rosetta-file-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <input
      #inputElement
      hidden
      type="file"
      [multiple]="multiple"
      [accept]="accept"
      (change)="onFileSelected($event)"
    />
    <button
      mat-stroked-button
      (click)="inputElement.click()"
      [disabled]="disabled"
    >
      @if (icon) {
        <fa-icon [icon]="icon" />
      }
      <span class="label">{{ label }}</span>
    </button>
  `,
})
export class RosettaFileSelectComponent {
  @Input({ transform: booleanAttribute }) multiple = false;
  @Input() accept = '*';
  @Input() disabled = false;
  @Input() label = 'Select';
  @Input() icon: IconProp = 'file-alt';

  @Output() filesSelected = new EventEmitter<FileList>();

  @ViewChild('inputElement', { static: true })
  inputElementRef: ElementRef<HTMLInputElement>;

  onFileSelected(event: any): void {
    if (event.target.files) {
      this.filesSelected.emit(event.target.files);
      this.inputElementRef.nativeElement.value = '';
    }
  }
}
