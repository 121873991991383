<button mat-icon-button [matMenuTriggerFor]="projectCardMenu">
  <fa-icon icon="ellipsis-v" />
</button>

<mat-menu #projectCardMenu="matMenu" class="project-card-menu">
  <ng-template matMenuContent>
    @if (showProjectAdmin) {
      <a (click)="gotoProjectAdmin()" mat-menu-item>
        <fa-icon icon="user-gear" [fixedWidth]="true" />
        <span>Project Admin</span>
      </a>
    }
    @if (allDataPresent()) {
      <a (click)="viewLicence()" mat-menu-item>
        <fa-icon icon="id-badge" [fixedWidth]="true" />
        <span>View License</span>
      </a>
    }
    <a (click)="openReleaseNoteDialog()" mat-menu-item>
      <fa-icon icon="list" [fixedWidth]="true" />
      <span>Release Notes</span></a
    >
    @for (link of model.links; track link.url) {
      <a [href]="link.url" target="_blank" mat-menu-item>
        <fa-icon icon="external-link-alt" [fixedWidth]="true" />
        <span>{{ link.name }}</span>
      </a>
    }
  </ng-template>
</mat-menu>
