@if (!isLoading) {
  <button [disabled]="disabled">
    <span class="mat-h2 theme-color-primary"><ng-content /></span>
    <svg width="60" height="34">
      <g class="arrow">
        <path d="M.21 16.14H41.07V17.34H.21z" />
        <path
          d="M38.39 19.02 40.67 16.74 38.39 14.46 40.33 14.46 42.6 16.74 40.33 19.02 38.39 19.02z"
        />
      </g>
      <path
        class="circle"
        d="M41.6.17A16.59 16.59 0 0 0 25.28 14h1.21a15.38 15.38 0 1 1 0 5.5h-1.21A16.57 16.57 0 1 0 41.6.17Z"
      />
    </svg>
  </button>
} @else {
  <app-loading-spinner />
}
