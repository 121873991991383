import { AgGridEvent, IRowNode } from '@ag-grid-community/core';
import { DiagnosticResultKey } from '@shared/components/diagnostic-panel/models/diagnostic.model';
import * as transform from '@shared/components/transform/models/data-viewer';
import { SampleCellState } from '@shared/components/transform/models/sample-cell-state.enum';
import { TransformDTO } from '@shared/components/transform/models/transform-dto.model';

import {
  DiagnosticsSummaryData,
  DiagnosticSummary,
} from '../../diagnostic-chart/diagnostic.models';

export const updateSummary = ({
  api,
  context,
}: AgGridEvent<transform.DataViewerRow, transform.DataViewerContext>): void => {
  const cells: transform.DataViewerCell[] = [];
  const diagnostics: TransformDTO.Diagnostics[] = [];

  api.forEachNode((rowNode: IRowNode<transform.DataViewerRow>) => {
    // Excludes rows which are filtered out
    if (rowNode.displayed) {
      const rowCells = [...(rowNode.data?.cellMap.values() || [])];
      cells.push(...rowCells);

      if (rowNode.data?.diagnostics) {
        diagnostics.push(rowNode.data.diagnostics);
      }
    }
  });

  const shownMap = cells.reduce<transform.DataViewerSummary>((acc, cell) => {
    // Ignore all cells with no state or if they are in a hidden column
    if (cell.hidden) {
      return acc;
    }

    // When showChanges is active exclude cells which are in columns with no changes
    if (context.showChanges && !cell.hasChanges) {
      return acc;
    }

    switch (cell.cellState) {
      case SampleCellState.Valid:
      case SampleCellState.Updated:
        acc.valid++;
        break;
      case SampleCellState.New:
      case SampleCellState.Removed:
        acc.warning++;
        break;
      default:
        acc.error++;
        break;
    }

    if (acc.cellStates[cell.cellState] === undefined) {
      acc.cellStates[cell.cellState] = 0;
    }

    acc.cellStates[cell.cellState]++;
    return acc;
  }, transform.getDefaultDataViewerSummary());

  context.summarySubject.next({
    ...shownMap,
    rows: api.getDisplayedRowCount(),
    columns: api.getDisplayedCenterColumns().length,
    diagnosticsSummaryData: getDiagnosticsSummaryData(diagnostics),
  });

  function getDiagnosticsSummaryData(
    diagnostics: TransformDTO.Diagnostics[]
  ): DiagnosticsSummaryData | undefined {
    if (!diagnostics || !diagnostics.length) {
      return undefined;
    }

    const mapping: DiagnosticSummary = {};
    const validation: DiagnosticSummary = {};

    diagnostics.forEach(({ diagnosticRecords }) => {
      if (diagnosticRecords) {
        const mappingSummary = getDiagnosticSummary(
          diagnosticRecords.mapping?.results
        );
        mapping.success = addNumbers(mapping.success, mappingSummary.success);
        mapping.failure = addNumbers(mapping.failure, mappingSummary.failure);

        const validationSummary = getDiagnosticSummary(
          diagnosticRecords.validation?.results
        );
        validation.success = addNumbers(
          validation.success,
          validationSummary.success
        );
        validation.failure = addNumbers(
          validation.failure,
          validationSummary.failure
        );
      }
    });

    return new Map<DiagnosticResultKey, DiagnosticSummary>([
      ['mapping', mapping],
      ['validation', validation],
    ]);
  }

  function getDiagnosticSummary(
    diagnosticResults?: TransformDTO.DiagnosticResults
  ): DiagnosticSummary {
    const success = diagnosticResults?.successes?.actual || 0;
    const failure = diagnosticResults?.failures?.actual || 0;
    return {
      success,
      failure,
    };
  }

  function addNumbers(...num: (number | undefined)[]): number {
    return num.reduce((acc, curr) => acc + getNumber(curr), 0);
  }

  function getNumber(num: number | undefined): number {
    return num === undefined ? 0 : num;
  }
};
