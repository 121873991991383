import { PipeTransform, Pipe } from '@angular/core';
import { sortBy } from 'lodash-es';

export type SortByIteratees<T> = Parameters<typeof sortBy<T[]>>[1];

@Pipe({
  standalone: true,
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform<T>(collection: T[], ...iteratees: SortByIteratees<T>[]): T[] {
    return sortBy<T[]>(collection, ...iteratees) as T[];
  }
}
