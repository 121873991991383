import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { UserDto } from '@features/auth/login';
import {
  CopyWorkspaceRequest,
  UserSessionDto,
  WorkspaceId,
  WorkspaceInfo,
} from '@models';
import { createFormDataWithFile } from '@utils/file-utils';
import { sanitizeUrlPaths } from '@utils/url-utils';
import { Observable, catchError, throwError } from 'rxjs';
import { LastSyncDto } from '../models/sync.dto';

@Injectable()
export class SupportApiService {
  constructor(
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getUserSessions(): Observable<UserSessionDto[]> {
    return this._http.get<UserSessionDto[]>(
      `${this._config.resourcePaths.notificationServer}/users`
    );
  }

  getWorkspaceInfosForUser(userId: string): Observable<WorkspaceInfo[]> {
    return this._http.get<WorkspaceInfo[]>(
      `${this._config.resourcePaths.workspaceCore}/${sanitizeUrlPaths(
        'workspace-infos-for-user',
        userId
      )}`
    );
  }

  workspaceExistsForUser(
    userId: string,
    workspaceName: string
  ): Observable<boolean> {
    return this._http.get<boolean>(
      `${this._config.resourcePaths.workspaceCore}/${userId}/${workspaceName}/exists`
    );
  }

  downloadAndZipWorkspace(workspaceId: WorkspaceId): Observable<Blob> {
    return this._http
      .get(
        `${this._config.resourcePaths.workspaceCore}/${workspaceId.name}/download`,
        {
          responseType: 'blob',
        }
      )
      .pipe(catchError(() => throwError(() => 'Error zipping workspace')));
  }

  uploadWorkspace(file: File, workspaceName: string): Observable<void> {
    const formData = createFormDataWithFile(file);

    return this._http.post<void>(
      `${this._config.resourcePaths.workspaceCore}/${workspaceName}/upload`,
      formData
    );
  }

  copyWorkspace(request: CopyWorkspaceRequest): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.workspaceCore}/copy-workspace`,
      request
    );
  }

  listUsers(filterString: string): Observable<UserDto[]> {
    return this._http.get<UserDto[]>(
      `${
        this._config.resourcePaths.userServer
      }/support/list?filter=${encodeURIComponent(filterString)}`
    );
  }

  syncRoles(): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.userSync}/roles`,
      undefined
    );
  }

  syncUser(userId: string): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.userSync}/single-user?userId=${userId}`,
      undefined
    );
  }

  syncAllModels(): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.modelSync}/all`,
      undefined
    );
  }

  syncModel(modelId: string): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.modelSync}/${modelId}`,
      undefined
    );
  }

  getLastSyncHistory(): Observable<LastSyncDto[]> {
    return this._http.get<LastSyncDto[]>(
      `${this._config.resourcePaths.modelSync}/last-sync-history`
    );
  }
}
