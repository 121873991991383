@if (node()) {
  <div class="node-container">
    <small [matTooltip]="breadCrumbTrail()">
      @for (p of node().path; track p) {
        @if (modelContent() && p.type) {
          <span
            ><a [appGoToSymbol]="p">{{ p.name }}</a></span
          >
        } @else {
          <span>{{ p.name }}</span>
        }
      }
    </small>
    <div class="spacer"></div>
    <app-copy-to-clipboard [path]="breadCrumbTrail()" />
  </div>
}
