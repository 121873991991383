@if ($currentModel(); as currentModel) {
  <div class="model-options__wrapper" @slideIn>
    <app-curve-svg [color]="currentModel.primaryColor" />
    <div
      class="model-options"
      [style.background]="$backgroundColor()"
      [style.color]="$color()"
    >
      <app-project-logo size="sm" [data]="currentModel" />
    </div>
  </div>
}
