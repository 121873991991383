import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  Attribute,
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import {
  ActivatedRoute,
  IsActiveMatchOptions,
  NavigationEnd,
  Router,
} from '@angular/router';
import { FontsModule } from '@app/fonts/fonts.module';
import { FeatureTab } from '@models';
import { Store } from '@ngrx/store';
import { RosettaTourModule } from '@shared/modules';
import { LabsFeatureDirective } from '@shared/modules/feature-toggle/labs-feature.directive';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isDisabled } from '@workspace-engine/engine-config';
import { groupBy } from 'lodash-es';
import { Subscription } from 'rxjs';

const defaultOptions: IsActiveMatchOptions = {
  paths: 'subset',
  queryParams: 'ignored',
  fragment: 'ignored',
  matrixParams: 'ignored',
};

@Component({
  selector: 'app-feature-row',
  standalone: true,
  imports: [
    AsyncPipe,
    FontsModule,
    LabsFeatureDirective,
    MatTabsModule,
    NgTemplateOutlet,
    RosettaTourModule,
  ],
  templateUrl: './feature-row.component.html',
  styleUrls: ['./feature-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'feature-row' },
})
export class FeatureRowComponent implements OnInit, OnDestroy {
  constructor(
    private _router: Router,
    private _store: Store,
    private _route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    @Attribute('feature-row-border') private _borderAttr: string
  ) {}

  tabKeys: string[];

  @Output() selectedTabChanged = new EventEmitter<FeatureTab>();

  @Input() border: 'top' | 'bottom' | 'none' = 'none';

  @Input({ transform: booleanAttribute }) allTabsDisabled = false;

  @Input() set tabs(featureTabs: FeatureTab[]) {
    this._groupTabs(featureTabs);
    this._update();
  }

  private _groupedTabs: { [key: string]: FeatureTab[] };
  private _sub = new Subscription();

  contribution$ = this._store.select(
    WorkspaceSelectors.isContributionTypeWorkspace
  );

  activeTabName?: string;

  onClickTab(featureTab: FeatureTab): void {
    this.selectedTabChanged.emit(featureTab);
  }

  @HostBinding('class.border-top') borderTop = this._borderAttr === 'top';
  @HostBinding('class.border-bottom') borderBottom =
    this._borderAttr === 'bottom';

  ngOnInit(): void {
    this._sub.add(
      this._router.events.subscribe(s => {
        if (s instanceof NavigationEnd) {
          this._update();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  getTabs(groupId: string): FeatureTab[] {
    return this._groupedTabs.hasOwnProperty(groupId)
      ? this._groupedTabs[groupId]
      : [];
  }

  private _groupTabs(featureTabs: FeatureTab[]): void {
    this._groupedTabs = groupBy(featureTabs, 'groupId');
    this.tabKeys = Object.keys(this._groupedTabs);
  }

  private _update(): void {
    const featureTabs = Object.values(this._groupedTabs).flat();
    if (!featureTabs) {
      return;
    }
    const activeTab = featureTabs.find(t => this._isActive(t));
    this.activeTabName = activeTab?.name;
    if (activeTab?.action) {
      this._store.dispatch(activeTab.action);
    }
    this._cdr.markForCheck();
  }

  private _isActive(tab: FeatureTab): boolean {
    const queryParams = tab.queryParams || {};
    return this._router.isActive(
      this._router.createUrlTree([tab.url], {
        queryParams,
        relativeTo: this._route,
      }),
      tab?.matchOptions || defaultOptions
    );
  }

  protected readonly isTabDisabled = isDisabled;
}
