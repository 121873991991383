@if (modelIds.length > 0) {
  <div class="project-cell__wrapper">
    @for (modelId of modelIds.slice(0, maxCount); track modelId) {
      <app-project-logo [size]="size" [data]="modelId | model | async" />
    }
  </div>
  @if (modelIds.length > maxCount) {
    <div class="project-cell__overflow-count">
      &#43; {{ modelIds.length - maxCount }}
    </div>
  }
} @else {
  -
}
