import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ALL_MODELS, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import {
  ChangePasswordCredentials,
  PackagePlanDetailsDto,
  ProjectDto,
  UserDetailsDto,
  UserDto,
  UserLoginResponse,
} from '@features/auth/login';
import { UserRegistrationCredentials } from '@features/auth/register/models/register.model';
import { IDomainModelDetails, IDomainModelResponse, isResponse } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { Store } from '@ngrx/store';
import { AppActions } from '@store/.';
import { AuthSelectors } from '@store/selectors';
import { domainModelMapper } from '@store/workspace/effects/workspace.effects.helper';
import { getErrorMessage } from '@utils/error-utils';
import {
  Observable,
  UnaryFunction,
  catchError,
  first,
  map,
  pipe,
  switchMap,
  throwError,
} from 'rxjs';
import { SettingsDTO } from '../models/settings-dto.models';

@Injectable()
export class SettingsApiService {
  constructor(
    private _store: Store,
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getAllAdminModels(): Observable<IDomainModelDetails[]> {
    return this._store.select(AuthSelectors.selectUserDocuments).pipe(
      first(),
      switchMap(userDocuments =>
        this._http
          .get<
            IDomainModelResponse[]
          >(`${this._config.resourcePaths.models}/all`)
          .pipe(
            map(domainModels => domainModelMapper(domainModels, userDocuments))
          )
      )
    );
  }

  getUserDetails(): Observable<UserRegistrationCredentials> {
    return this._http
      .get<UserRegistrationCredentials>(
        `${this._config.resourcePaths.userServer}/auth/user-details`
      )
      .pipe(this._errorhandler());
  }

  getProjectUserDetails(): Observable<SettingsDTO.ProjectUserDetails[]> {
    return this._http
      .get<
        SettingsDTO.ProjectUserDetails[]
      >(`${this._config.resourcePaths.projectAdmin}/users`)
      .pipe(this._errorhandler());
  }

  userDetails(): Observable<UserDetailsDto[]> {
    return this._http.get<UserDetailsDto[]>(
      `${this._config.resourcePaths.userServer}/details`
    );
  }

  updateUserProfile(
    data: UserRegistrationCredentials
  ): Observable<void | null> {
    return this._http
      .post<void>(
        `${this._config.resourcePaths.userServer}/auth/update-user`,
        data
      )
      .pipe(this._errorhandler());
  }

  updateUserPassword(
    data: ChangePasswordCredentials
  ): Observable<UserLoginResponse> {
    return this._http
      .post<UserLoginResponse>(
        `${this._config.resourcePaths.userServer}/auth/change-password`,
        data
      )
      .pipe(this._errorhandler());
  }

  downloadModelUsers(modelId: ModelInstanceId): Observable<Blob> {
    return this._http
      .get(`${this._config.resourcePaths.userServer}/auth/download`, {
        responseType: 'blob',
        ...(modelId !== ALL_MODELS
          ? { params: { projectFilter: modelId } }
          : null),
      })
      .pipe(this._errorhandler());
  }

  updateUserProjects(user: UserDto, projects: ProjectDto[]): Observable<void> {
    const body = { user, projects };
    return this._http.post<void>(
      `${this._config.resourcePaths.userServer}/update-projects`,
      body
    );
  }

  updatePackagePlanDetails(
    user: UserDto,
    packagePlanDetailsDto: PackagePlanDetailsDto
  ): Observable<void> {
    const body: {
      user: UserDto;
      packagePlanDetailsDto: PackagePlanDetailsDto;
    } = { user, packagePlanDetailsDto };
    return this._http.post<void>(
      `${this._config.resourcePaths.packagePlans}/update-package-plan`,
      body
    );
  }

  private _errorhandler<T>(): UnaryFunction<Observable<T>, Observable<any>> {
    return pipe(
      map(response => {
        if (isResponse(response) && !response.success) {
          throw new Error(response.message);
        }
        return response;
      }),
      catchError((error: any) => {
        this._store.dispatch(
          AppActions.showBasicErrorMsg({ message: getErrorMessage(error) })
        );

        return throwError(() => error);
      })
    );
  }
}
