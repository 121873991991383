import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { StorageKey } from '@configs';
import { LocalStorageService } from '@core/services';
import { Subscription } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appPersistTabIndex]',
})
export class PersistTabIndexDirective implements OnInit, OnDestroy {
  constructor(
    private _storage: LocalStorageService,
    private _parent: MatTabGroup
  ) {}

  @Input('appPersistTabIndex') key!: string;

  private _sub = new Subscription();
  private _config: Record<string, number> =
    this._storage.getItem<Record<string, number>>(StorageKey.WorkspaceTab) ||
    {};

  ngOnInit(): void {
    this._setCurrentIndex();
    this._sub.add(
      this._parent.selectedIndexChange.subscribe(index => {
        this._storage.setItem(StorageKey.WorkspaceTab, {
          ...this._config,
          [this.key]: index,
        });
      })
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private _setCurrentIndex(): void {
    const currentIndex = this._config[this.key];

    if (currentIndex) {
      this._parent.selectedIndex = currentIndex;
    }
  }
}
