import { NgModule, Provider } from '@angular/core';
import {
  provideAnimations,
  provideNoopAnimations,
} from '@angular/platform-browser/animations';
import { NotificationModule } from '@core/modules/notifications/notifications.module';
import { TaskMessagesModule } from '@core/modules/task-messages/task-messages.module';
import { LanguageServerMiddlewareService } from '@core/services/language-server-middleware.service';
import { LanguageServerService } from '@core/services/language-server.service';
import { ServerStateSocketService } from '@core/services/server-state-socket.service';
import { CookieService } from 'ngx-cookie-service';
import {
  provideScrollbarOptions,
  provideScrollbarPolyfill,
} from 'ngx-scrollbar';
import { StatusPollingService } from '../services/status-polling.service';
import { GaModule } from './ga/ga.module';
import { MatModule } from './mat/mat.module';
import { NotificationService } from './snack-bar/services/notification.service';
import { NgrxStoreModule } from './store/ngrx-store.module';

export const provideAppAnimations = (): Provider[] => {
  return window.location.hash.indexOf('disableAnimations') > -1
    ? provideNoopAnimations()
    : provideAnimations();
};

@NgModule({
  imports: [
    NotificationModule,
    GaModule,
    NgrxStoreModule,

    // TODO: Move to lazy loaded module RosettaCoreModule
    // when other dependencies are moved
    TaskMessagesModule,
    MatModule,
  ],
  providers: [
    CookieService,
    NotificationService,

    provideAppAnimations(),
    provideScrollbarPolyfill('assets/scroll-timeline-polyfill.js'),
    provideScrollbarOptions({
      orientation: 'vertical',
      sensorThrottleTime: 500,
    }),

    // TODO: Move to lazy loaded module RosettaCoreModule
    // when other dependencies are moved
    LanguageServerService,
    LanguageServerMiddlewareService,
    ServerStateSocketService,
    StatusPollingService,
  ],
})
export class CoreModule {}
