import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FontsModule } from '@app/fonts/fonts.module';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { RosettaErrorMessage } from './error-message.model';

@Component({
  standalone: true,
  selector: 'app-error-message',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FontsModule, RosettaMenuComponent],
  template: `
    <app-rosetta-menu noShadow="true">
      <h4 class="font-weight-semi-bold error-color mt-8">
        <fa-icon
          class="status-icon error-color mr-8"
          icon="circle-xmark"
          size="lg"
        />
        {{ error.title }}
      </h4>
      <p>{{ error.summary }}</p>
      @if (error.details) {
        <p class="color-50">{{ error.details }}</p>
      }
    </app-rosetta-menu>
  `,
})
export class ErrorMessageComponent {
  @Input({ required: true }) error: RosettaErrorMessage;
}
