<svg width="82" height="34">
  <path
    class="engine-svg-lg-cog"
    d="m25.312 16.69-2.254-.752a10.3 10.3 0 0 0-.82-1.951l1.064-2.128a1.376 1.376 0 0 0-.264-1.59l-1.249-1.25a1.376 1.376 0 0 0-1.59-.263L18.072 9.82c-.621-.34-1.275-.614-1.952-.82l-.751-2.254a1.376 1.376 0 0 0-1.308-.975h-1.805a1.376 1.376 0 0 0-1.308.975L10.197 9c-.677.206-1.33.48-1.952.82L6.09 8.727a1.376 1.376 0 0 0-1.59.254l-1.25 1.259a1.376 1.376 0 0 0-.263 1.59l1.064 2.127c-.34.62-.615 1.275-.82 1.952l-2.254.751A1.376 1.376 0 0 0 0 17.968v1.795a1.386 1.386 0 0 0 .976 1.308l2.254.751c.205.677.48 1.331.82 1.952L2.986 25.91a1.376 1.376 0 0 0 .263 1.59l1.25 1.25a1.376 1.376 0 0 0 1.59.263l2.127-1.064c.62.34 1.275.615 1.952.82l.751 2.254a1.385 1.385 0 0 0 1.308.976h1.795a1.375 1.375 0 0 0 1.308-.976l.751-2.186a10.25 10.25 0 0 0 1.952-.82l2.127 1.064a1.376 1.376 0 0 0 1.59-.263l1.25-1.25a1.376 1.376 0 0 0 .292-1.658l-1.073-2.098c.34-.62.614-1.275.82-1.952l2.273-.83a1.376 1.376 0 0 0 .976-1.307v-1.727a1.375 1.375 0 0 0-.976-1.307Zm-12.168 5.786a3.59 3.59 0 1 1 3.59-3.59 3.6 3.6 0 0 1-3.59 3.59"
  />
  <path
    class="engine-svg-sm-cog"
    d="m38.24 9.625-.117-1.278a1.034 1.034 0 0 0-.79-.918l-1.727-.41a7.683 7.683 0 0 0-.703-1.463l.654-1.659a1.034 1.034 0 0 0-.303-1.17l-.975-.85a1.034 1.034 0 0 0-1.2-.088l-1.513.976a7.7 7.7 0 0 0-1.532-.478l-.79-1.669a1.034 1.034 0 0 0-1.035-.614L26.892.12a1.034 1.034 0 0 0-.918.79l-.41 1.727c-.49.202-.957.454-1.395.752l-1.649-.654a1.025 1.025 0 0 0-1.17.302l-.85.976a1.034 1.034 0 0 0-.087 1.2l.975 1.513a7.68 7.68 0 0 0-.478 1.532l-1.63.712a1.034 1.034 0 0 0-.614 1.035l.117 1.317a1.034 1.034 0 0 0 .79.917l1.727.41c.197.5.446.977.742 1.425l-.654 1.659a1.033 1.033 0 0 0 .303 1.17l.976.85a1.034 1.034 0 0 0 1.2.087l1.512-.976a7.695 7.695 0 0 0 1.532.479l.673 1.736a1.035 1.035 0 0 0 1.035.615l1.317-.117a1.035 1.035 0 0 0 .917-.79l.41-1.727c.5-.197.977-.446 1.425-.742l1.659.654a1.034 1.034 0 0 0 1.17-.303l.85-.976a1.035 1.035 0 0 0 .088-1.2l-.976-1.512a7.708 7.708 0 0 0 .478-1.532l1.63-.713a1.034 1.034 0 0 0 .614-1.034l.04-.078Zm-9.572 3.503a3.23 3.23 0 1 1 2.927-3.503 3.24 3.24 0 0 1-2.927 3.503"
  />
  <path
    class="engine-svg-text"
    d="M37.86 31v-8.727h5.677v1.325h-4.095v2.37h3.8v1.325h-3.8v2.382h4.129V31h-5.71Zm14.462-8.727V31h-1.407l-4.112-5.945h-.072V31H45.15v-8.727h1.415l4.108 5.949h.076v-5.95h1.573Zm7.569 2.787a2.256 2.256 0 0 0-.294-.618 1.897 1.897 0 0 0-.443-.473 1.788 1.788 0 0 0-.588-.294 2.453 2.453 0 0 0-.725-.103c-.468 0-.886.118-1.253.354-.366.236-.654.583-.865 1.04-.207.454-.31 1.008-.31 1.662 0 .659.103 1.217.31 1.675.208.457.496.805.865 1.044.37.235.799.353 1.287.353.444 0 .827-.085 1.15-.255.328-.17.579-.412.755-.725.176-.315.264-.684.264-1.108l.358.056h-2.369v-1.236h3.541v1.048c0 .747-.159 1.394-.477 1.939a3.26 3.26 0 0 1-1.313 1.261c-.556.293-1.196.44-1.917.44-.804 0-1.51-.181-2.118-.542a3.744 3.744 0 0 1-1.42-1.547c-.337-.67-.506-1.466-.506-2.386 0-.705.1-1.334.298-1.888a4.084 4.084 0 0 1 .844-1.41 3.59 3.59 0 0 1 1.27-.887 4.044 4.044 0 0 1 1.585-.307c.483 0 .933.071 1.35.213.418.14.79.338 1.113.597.327.259.595.565.805.92.21.356.348.748.414 1.177H59.89Zm4.793-2.787V31h-1.581v-8.727h1.58Zm8.884 0V31h-1.406l-4.113-5.945h-.072V31h-1.581v-8.727h1.415l4.108 5.949h.076v-5.95h1.573ZM75.29 31v-8.727h5.677v1.325H76.87v2.37h3.802v1.325H76.87v2.382h4.13V31h-5.71Z"
  />
</svg>

@if (disabled) {
  <fa-icon icon="lock" [appRosettaOverlayHoverTarget]="overlay" />
}

<app-rosetta-overlay #overlay>
  <app-rosetta-menu maxWidth="300">
    <p class="mb-0">
      Engine is not available in your workspace with your current package plan.
    </p>
  </app-rosetta-menu>
</app-rosetta-overlay>
