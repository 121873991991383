import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StorageKey } from '@configs';
import { WA_SESSION_STORAGE } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { AppActions } from '@store/index';
import { AppSelectors } from '@store/selectors';
import { Observable, first, map, switchMap, tap } from 'rxjs';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  constructor(
    private _store: Store,
    @Inject(WA_SESSION_STORAGE) private _storage: Storage
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap(event => {
        return this._store.select(AppSelectors.refreshRequired).pipe(
          first(),
          tap(refreshRequired => {
            if (!refreshRequired && event instanceof HttpResponse) {
              const serverVersion = event.headers.get('Rosetta-Version');
              if (serverVersion) {
                this._checkVersion(serverVersion);
              }
            }
          }),
          map(() => event)
        );
      })
    );
  }

  private _checkVersion(serverVersion: string): void {
    const localVersion = this._storage.getItem(StorageKey.AppVersion);

    if (!localVersion) {
      this._storage.setItem(StorageKey.AppVersion, serverVersion);
      return;
    }

    /**
     * TODO - Make this only from user server
     */
    if (localVersion !== serverVersion) {
      this._store.dispatch(AppActions.appRefreshUpdate.required());
    }
  }
}
