import { AsyncPipe, DatePipe } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
} from '@angular/core';
import { AppThemes } from '@models';
import { Store } from '@ngrx/store';
import { TrialEndingSoonDirective } from '@shared/directives';
import { DaysUntilDatePipe, RosettaTitleCasePipe } from '@shared/pipes';
import { AppSelectors, AuthSelectors } from '@store/selectors';
import { map, takeWhile } from 'rxjs';
import { RosettaOverlayModule } from '../../modules/rosetta-overlay/rosetta-overlay.module';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { TextBadgeComponent } from '../text-badge/text-badge.component';
import { fullLogoAnimation, stoneAnimation } from './logo.component.animation';

type LogoState = 'full' | 'logo' | 'animate-full' | 'animate-logo';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    DaysUntilDatePipe,
    RosettaTitleCasePipe,
    TextBadgeComponent,
    RosettaOverlayModule,
    RosettaMenuComponent,
    TrialEndingSoonDirective,
    DatePipe,
  ],
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  animations: [stoneAnimation, fullLogoAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'v2-style-wrapper',
  },
})
export class LogoComponent {
  constructor(
    private _store: Store,
    @Attribute('mode') public mode: LogoState
  ) {}

  private _logoUrl = '/assets/images/logos/';
  private _logoLight = 'rosetta_full_logo_white.svg';
  private _logoDark = 'rosetta_full_logo_black.svg';

  @Input({ transform: booleanAttribute }) showInfo = false;
  @Input({ transform: booleanAttribute }) enableAnimationHover = false;
  @Input() forceTheme?: AppThemes;

  packagePlan$ = this._store.select(AuthSelectors.getPackagePlanDetails);
  isDarkTheme$ = this._store.select(AppSelectors.isDarkTheme).pipe(
    map(isDark => this._setTheme(isDark)),
    takeWhile(() => this.forceTheme === undefined, true)
  );
  logoUrl$ = this.isDarkTheme$.pipe(map(isDark => this._getLogoUrl(isDark)));

  onMouseEnter(): void {
    if (this.enableAnimationHover) {
      this.mode = 'animate-full';
    }
  }

  onMouseLeave(): void {
    if (this.enableAnimationHover) {
      this.mode = 'animate-logo';
    }
  }

  private _setTheme(isDark: boolean): boolean {
    if (this.forceTheme !== undefined) {
      return this.forceTheme === AppThemes.LIGHT;
    }
    return isDark;
  }

  private _getLogoUrl(isDark: boolean) {
    const lightLogo = this._logoUrl + this._logoLight;
    const darkLogo = this._logoUrl + this._logoDark;
    return isDark ? lightLogo : darkLogo;
  }
}
