import { InjectionToken } from '@angular/core';
import {
  CoreResourcePaths,
  DynamicResourcePaths,
  ResourcePaths,
  WebSocketResourcePaths,
} from '@configs';
import { AppDocuments } from '@models';
import { StorageKey } from './storage-keys.config';

export const ROSETTA_CONFIG = new InjectionToken<IRosettaConfig>(
  'RosettaConfig',
  {
    providedIn: 'root',
    factory: () => RosettaConfig,
  }
);

export type IRosettaConfig = typeof RosettaConfig;
export type IResourcePath =
  (typeof RosettaConfig.resourcePaths)[keyof typeof RosettaConfig.resourcePaths];

export const RosettaConfig = {
  storage: {
    whitelist: [StorageKey.TourConfig] as string[],
  },
  color: {
    blue: '#006efb',
    primary: '#acaf38',
    warn: '#f44336',
  },
  resourcePaths: {
    ...DynamicResourcePaths,
    ...CoreResourcePaths,
    ...ResourcePaths,
  },
  sockets: {
    ...WebSocketResourcePaths,
  },
  regex: {
    inputName: RegExp(/[a-zA-Z0-9._-\s]{1,255}$/),
    workspaceName: RegExp(/^[a-zA-Z0-9_-]*$/),
    newNamespace: RegExp(/^(([a-zA-Z0-9]+)\.)*([a-zA-Z0-9]+)$/),
    password: RegExp(
      /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}/
    ),
    prodVersionPattern: (majorVersion = 0) =>
      RegExp(`^${majorVersion}\\.\\d+\\.\\d+$`),
    devVersionPattern: (majorVersion = 0) =>
      RegExp(`^${majorVersion}\\.\\d+\\.\\d+-dev\\.\\d+$`),
  },
  appDocuments: [AppDocuments.TERMS_OF_SERVICE],
  text: {
    uiUpdate:
      'The new version of Rosetta is live. In order to see the latest version please refresh.',
    workspaceLoadError:
      'We were unable to load your workspace due to a server error.',
    internalServerError:
      'There was an error processing your request. We apologize for any inconvenience. Please try again later.',
    gatewayTimeout:
      'The server is taking too long to respond. Please try again later.',
    MultipleSessions:
      "Oops! Looks like you've opened the application somewhere else (another tab perhaps). Hit Reconnect to continue using the application here.",
    MultipleWebsocketConnections:
      'Attempted to open new websocket connection without closing the previously opened connection',
    codeViewError:
      'Something went wrong while generating the sample code views, please try again',
    sampleNotFound:
      'Unable to find the sample, navigating to the test pack page',
    ingestionCannotRun:
      'Ingestion cannot run while there are errors in your workspace. Fix all errors before re-running ingestion.',
    payloadEmpty:
      'Payload should not be empty if not CANCELLED or ERROR status',
    unsupportedVersion:
      'This version is unsupported. We recommend you upgrade as some features might be broken.',
    workspaceIssue:
      'This workspace cannot be opened because something has gone wrong, contact support.',
    somethingWentWrong: 'Oops something went wrong. Please try again.',
    sessionExpired: 'Your session has expired. Please log in again.',
    workspaceNotReady: 'Workspace is not ready. Please retry in a moment.',
  },
} as const;
