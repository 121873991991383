<app-rosetta-selector
  class="mr-8"
  [selector]="pipelineSelector"
  [groupOptions]="pipelineGroupOptions"
  autoSelect="true"
/>
<app-rosetta-selector
  selectorClass="test-pack-selector"
  [selector]="testPackSelector"
  autoSelect="true"
  #testPackSelectorComp
>
  <ng-container *appRosettaSelectorTrigger="testPackSelector; let option">
    <span class="mr-16">
      {{ option?.label }}
    </span>
    <app-transform-status
      [status]="option?.data.status"
      [storedOnClient]="option?.data.storedOnClient"
    />
  </ng-container>
  <ng-container *appRosettaSelectorOptions="testPackSelector; let option">
    <div class="flex-row-center gap-1" appRosettaShowHideTrigger>
      <span class="spacer-no-gap">
        {{ option?.label }}
      </span>

      @if (option?.data.status !== existingTestPack) {
        <app-rosetta-show-hide
          [disabled]="option?.data.status !== addedTestPack"
        >
          <app-transform-status
            *appRosettaShowItem
            [status]="option?.data.status"
            [storedOnClient]="option?.data.storedOnClient"
          />
          <button
            *appRosettaHideItem
            class="small-button-icon"
            appStopPropagation
            mat-icon-button
            [disabled]="option?.data.status !== addedTestPack"
            (click)="deleteTestPack(option.data.testPackDef)"
          >
            <fa-icon icon="trash" />
          </button>
        </app-rosetta-show-hide>
      }
    </div>
  </ng-container>

  <div
    *appShowWhenWorkspace="'read-write'"
    class="add-action-btn-wrapper"
    appRosettaSelectorFooter
    matTooltip="Not supported for the currently selected Synonym Source"
    [matTooltipDisabled]="!addTestPackDisabled()"
  >
    <button
      class="add-action-btn width-full theme-border-top theme-bg-lighter"
      mat-button
      [disabled]="addTestPackDisabled()"
      (click)="openAddTestPackDialog(); testPackSelectorComp.close()"
    >
      <fa-icon icon="plus" />
      <span>Add Test Pack</span>
    </button>
  </div>
</app-rosetta-selector>
