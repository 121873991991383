import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PATH_JOIN_CHAR_WITH_SPACE } from '@configs';

@Component({
  selector: 'app-rosetta-path',
  standalone: true,
  imports: [MatTooltipModule],
  template: `
    <small [matTooltip]="displayPath()" [matTooltipShowDelay]="500">
      {{ displayPath() }}
    </small>
  `,
  styles: [
    `
      :host {
        display: inline-block;
        direction: rtl;
        width: 100%;
      }
    `,
  ],
  host: {
    class: 'text-ellipsis opacity-50',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaPathComponent {
  private _path!: string[];
  displayPath = signal('');

  @Input({ required: true })
  set path(value: string[]) {
    this._path = value;
    this.displayPath.set(value.join(PATH_JOIN_CHAR_WITH_SPACE));
  }
  get path(): string[] {
    return this._path;
  }
}
