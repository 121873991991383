import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CORE_RW, ResourceType } from '@configs';
import { WorkspaceInfo, WorkspaceMenuItem } from '@models/workspace.model';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { catchErrorAndReturn, firstNotNullAndComplete } from '@utils/operators';
import { Observable, first, map, of, switchMap } from 'rxjs';
import { rewriteProjectWorkspaceUrlPath } from './helper-interceptor';

export const HTTP_CONTEXT_TOKEN = new HttpContextToken<string | undefined>(
  () => undefined
);

@Injectable()
export class DynamicUrlPathInterceptor implements HttpInterceptor {
  constructor(private _store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this._createUrl(req).pipe(
      switchMap(url =>
        next.handle(
          req.clone({
            url,
          })
        )
      )
    );
  }

  private _createUrl(req: HttpRequest<any>): Observable<string> {
    if (req.url.startsWith(ResourceType.Dynamic)) {
      return this._handleServiceRequest(req);
    }
    return of(req.url);
  }

  private _handleServiceRequest(req: HttpRequest<any>): Observable<string> {
    const workspaceName = req.context.get(HTTP_CONTEXT_TOKEN);
    const mapWorkspaceInfoToUrl = (
      info: WorkspaceMenuItem | WorkspaceInfo | null | undefined
    ): string => {
      if (info) {
        return rewriteProjectWorkspaceUrlPath(req.url, info);
      }
      throw new Error('Workspace info is empty');
    };
    // Token is empty when on a workspace, so fallback to selectWorkspaceInfo
    const url$ = workspaceName
      ? this._store.select(WorkspaceSelectors.selectMenuItems).pipe(
          first(items => items?.length > 0),
          map(items => {
            const info = items.find(item => item.id.name === workspaceName);
            return mapWorkspaceInfoToUrl(info);
          })
        )
      : this._store
          .select(WorkspaceSelectors.selectWorkspaceInfo)
          .pipe(firstNotNullAndComplete(), map(mapWorkspaceInfoToUrl));

    return url$.pipe(
      catchErrorAndReturn(req.url.replace(ResourceType.Dynamic, CORE_RW))
    );
  }
}
