import { NgModule } from '@angular/core';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RosettaTourStartDirective } from './rosetta-tour-start.directive';
import { RosettaTourTemplateComponent } from './rosetta-tour-template.component';
import { RosettaTourService } from './rosetta-tour.service';

/**
 * Add the Rosetta tour module to each section/module you
 * wish to have a new independent tour collection.
 *
 * You will need to add a new <tour-template> to the root component of that module.
 */
@NgModule({
  imports: [
    RosettaTourTemplateComponent,
    RosettaTourStartDirective,
    TourMatMenuModule,
  ],
  exports: [
    RosettaTourTemplateComponent,
    RosettaTourStartDirective,
    TourMatMenuModule,
  ],
  providers: [RosettaTourService],
})
export class RosettaTourModule {}
