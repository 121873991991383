<div class="feature-logo__wrapper">
  <ng-content select="[feature-logo]" />
</div>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  @for (key of tabKeys; track key) {
    @if (getTabs(key); as tabs) {
      <div class="tab-group">
        @for (tab of tabs; track tab) {
          <ng-template #tabTemplate>
            <div
              mat-tab-link
              [disabled]="
                allTabsDisabled || (isTabDisabled(tab, contribution$) | async)
              "
              [active]="activeTabName === tab.name"
              (click)="onClickTab(tab)"
              (keyup.enter)="onClickTab(tab)"
              (keyup.space)="onClickTab(tab)"
            >
              <a class="tab-content">
                <fa-icon [icon]="tab.icon" />
                <span>{{ tab.label }}</span>
              </a>
            </div>
          </ng-template>
          <ng-template #tabLabs>
            @if (tab.params?.labsFeature) {
              <ng-container
                *appLabsFeature="tab.params.labsFeature"
                [ngTemplateOutlet]="tabTemplate"
              />
            } @else {
              <div
                mat-tab-link
                [disabled]="
                  allTabsDisabled || (isTabDisabled(tab, contribution$) | async)
                "
                [active]="activeTabName === tab.name"
                (click)="onClickTab(tab)"
                (keyup.enter)="onClickTab(tab)"
                (keyup.space)="onClickTab(tab)"
              >
                <a class="tab-content">
                  <fa-icon [icon]="tab.icon" />
                  <span>{{ tab.label }}</span>
                </a>
              </div>
            }
          </ng-template>
          @if (tab.tourAnchor) {
            <span [tourAnchor]="tab.tourAnchor">
              <ng-container [ngTemplateOutlet]="tabLabs" />
            </span>
          } @else {
            @if (tab.params?.labsFeature) {
              <ng-container
                *appLabsFeature="tab.params.labsFeature"
                [ngTemplateOutlet]="tabTemplate"
              />
            } @else {
              <div
                mat-tab-link
                [disabled]="
                  allTabsDisabled || (isTabDisabled(tab, contribution$) | async)
                "
                [active]="activeTabName === tab.name"
                (click)="onClickTab(tab)"
                (keyup.enter)="onClickTab(tab)"
                (keyup.space)="onClickTab(tab)"
              >
                <a class="tab-content">
                  <fa-icon [icon]="tab.icon" />
                  <span>{{ tab.label }}</span>
                </a>
              </div>
            }
          }
        }
      </div>
    }
  }
</nav>
<mat-tab-nav-panel #tabPanel />
<ng-content />
