import { NgModule } from '@angular/core';
import { CollapsiblePanelBodyComponent } from './components/collapsible-panel-body.component';
import { CollapsiblePanelGroupComponent } from './components/collapsible-panel-group.component';
import { CollapsiblePanelHeaderComponent } from './components/collapsible-panel-header/collapsible-panel-header.component';
import { CollapsiblePanelToggleButtonComponent } from './components/collapsible-panel-toggle-button.component';
import { CollapsiblePanelComponent } from './components/collapsible-panel/collapsible-panel.component';

const components = [
  CollapsiblePanelToggleButtonComponent,
  CollapsiblePanelHeaderComponent,
  CollapsiblePanelBodyComponent,
  CollapsiblePanelGroupComponent,
  CollapsiblePanelComponent,
];

@NgModule({
  imports: components,
  exports: components,
})
export class CollapsiblePanelModule {}
