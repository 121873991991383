import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { LoadingSpinnerComponent } from '../components/loading-spinner/loading-spinner.component';

class ShowSpinnerDirectiveContext<T = unknown> {
  public $implicit: T | null = null;
  public appShowSpinner: T | null = null;
}

@Directive({
  standalone: true,
  selector: '[appShowSpinner]',
})
export class ShowSpinnerDirective<T = unknown> implements OnChanges {
  constructor(
    private _container: ViewContainerRef,
    private _template: TemplateRef<any>
  ) {}

  @Input() appShowSpinner!: T;
  @Input('appShowSpinnerDiameter') diameter = 36;
  @Input('appShowSpinnerFlexCentre') flexCentre = false;

  private _viewRef: EmbeddedViewRef<ShowSpinnerDirectiveContext<T>> | null =
    null;
  private _context: ShowSpinnerDirectiveContext<T> =
    new ShowSpinnerDirectiveContext();

  // Make sure the template checker knows the type of the context with which the
  // template of this directive will be rendered
  static ngTemplateContextGuard<T>(
    dir: ShowSpinnerDirective<T>,
    ctx: unknown
  ): ctx is ShowSpinnerDirectiveContext<
    Exclude<T, false | 0 | '' | null | undefined>
  > {
    return true;
  }

  ngOnChanges({ appShowSpinner }: SimpleChanges): void {
    this._context.$implicit = this._context.appShowSpinner =
      appShowSpinner.currentValue;

    if (appShowSpinner.currentValue) {
      if (!this._viewRef) {
        this._container.clear();
        this._viewRef = this._container.createEmbeddedView(
          this._template,
          this._context
        );
      }
    } else {
      this._container.clear();
      this._viewRef = null;
      this._createSpinner();
    }
  }

  private _createSpinner(): void {
    const componentRef = this._container.createComponent(
      LoadingSpinnerComponent
    );
    componentRef.setInput('diameter', this.diameter);
    componentRef.setInput('flexCentre', this.flexCentre);
  }
}
