import { Directive, HostListener } from '@angular/core';
import { CoreDialogService } from '@app/dialogs/core-dialog.service';
import { Store } from '@ngrx/store';
import { AuthSelectors } from '@store/selectors';
import { first, switchMap } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[appClickToOpenUpgradeDialog]',
})
export class ClickToOpenUpgradeDialogDirective {
  constructor(
    private _dialog: CoreDialogService,
    private _store: Store
  ) {}

  @HostListener('click')
  onCLick(): void {
    this._store
      .select(AuthSelectors.selectFormConfig)
      .pipe(
        first(),
        switchMap(formConfig => this._dialog.openUpgradePlan(formConfig))
      )
      .subscribe();
  }
}
