import {
  Inject,
  inject,
  InjectionToken,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { IDomainModelDetails } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { Store } from '@ngrx/store';
import { first, map, Observable } from 'rxjs';

export const MODEL_DATA$ = new InjectionToken<
  Observable<Map<ModelInstanceId, IDomainModelDetails>>
>('ModelData$', {
  factory: () => inject(Store).select(WorkspaceSelectors.selectDomainModelsMap),
});

@Pipe({
  standalone: true,
  name: 'model',
})
export class ModelPipe implements PipeTransform {
  constructor(
    @Inject(MODEL_DATA$)
    private _modelData$: Observable<Map<ModelInstanceId, IDomainModelDetails>>
  ) {}

  transform(modelId: ModelInstanceId): Observable<IDomainModelDetails> {
    return this._modelData$.pipe(
      first(),
      map(modelMap => modelMap.get(modelId) || this._createDefault(modelId))
    );
  }

  private _createDefault(modelId: ModelInstanceId): IDomainModelDetails {
    return {
      id: modelId,
      name: modelId,
      versions: [],
      documents: {},
      shortname: modelId,
      accepted: false,
      primaryColor: '#e2e2e2',
    };
  }
}
