@if (parent?.canClose) {
  <app-collapsible-panel-toggle-button
    [isOpen]="false"
    (panelClick)="parent.toggle()"
    [@slideInOut]="showButton$ | async"
  />
}
@if (showTitle) {
  <small class="panel-title mb-0">{{ parentTitle }}</small>
}
<div
  class="flex-row flex-align-center"
  [class.justify-content-end]="contentAlign === 'end'"
>
  <ng-content />
</div>
