import { Id } from '@models/id.model';

export interface UserSessionDto {
  id: Id;
  email: string;
  eventTime: string;
  eventType: EventTypeDto;
  metaData: Record<string, any>;
}

export enum EventTypeDto {
  login = 'LOGIN',
  logout = 'LOGOUT',
  logoutBrowserClose = 'LOGOUT_BROWSER_CLOSE',
  logoutWorkspaceClose = 'LOGOUT_WORKSPACE_CLOSE',
  workspaceOpen = 'WORKSPACE_OPEN',
  workspaceClose = 'WORKSPACE_CLOSE',
}

export const eventMappings = {
  [EventTypeDto.login]: 'Active',
  [EventTypeDto.workspaceClose]: 'Active',
  [EventTypeDto.workspaceOpen]: 'Workspace',
  [EventTypeDto.logout]: 'Inactive',
  [EventTypeDto.logoutBrowserClose]: 'Inactive',
  [EventTypeDto.logoutWorkspaceClose]: 'Inactive',
};

export const activeEventTypes = [
  EventTypeDto.login,
  EventTypeDto.workspaceClose,
];

export const inactiveEventTypes = [
  EventTypeDto.logout,
  EventTypeDto.logoutBrowserClose,
  EventTypeDto.logoutWorkspaceClose,
];
