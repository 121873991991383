<ng-template #itemHeading let-item>
  <h4 class="mb-0 font-weight-bold">{{ item.name }}</h4>
  <app-rosetta-path [path]="item.inputPath?.value" />
</ng-template>

<ng-scrollbar>
  @if (data().get('mapping')) {
    <app-diagnostic-panel
      [groups]="data().get('mapping')"
      heading="Mappings"
      (clicked)="panelClicked.emit($event)"
    >
      <ng-template #issues appDiagnosticPanelGroupDef="failure" let-item>
        <ng-container
          *ngTemplateOutlet="itemHeading; context: { $implicit: item }"
        />
        <ul>
          @for (issue of item.issues; track issue) {
            <li class="list-style-disc">
              {{ issue }}
            </li>
          }
        </ul>
      </ng-template>

      <ng-template appDiagnosticPanelGroupDef="success" let-item>
        <ng-container
          *ngTemplateOutlet="itemHeading; context: { $implicit: item }"
        />
        <div class="mb-8"></div>
        <small class="font-weight-bold">Mapped to: </small>
        @if (item.outputPaths?.length > 1) {
          <ul>
            @for (path of item.outputPaths; track path) {
              <li>
                <app-rosetta-path [path]="path.value" />
              </li>
            }
          </ul>
        } @else {
          @if (item.outputPaths?.length === 1) {
            <app-rosetta-path [path]="item.outputPaths[0].value" />
          } @else {
            <small class="opacity-50">
              Used in conditional mapping expression
            </small>
          }
        }
      </ng-template>

      <ng-template #issues appDiagnosticPanelGroupDef="excluded" let-item>
        <ng-container
          *ngTemplateOutlet="itemHeading; context: { $implicit: item }"
        />
      </ng-template>
    </app-diagnostic-panel>
  }

  @if (data().get('validation')) {
    <app-diagnostic-panel
      [groups]="data().get('validation')"
      heading="Validations"
      (clicked)="panelClicked.emit($event)"
    >
      <ng-template appDiagnosticPanelGroupDef="failure" let-item>
        <ng-container
          *ngTemplateOutlet="itemHeading; context: { $implicit: item }"
        />
        <ul>
          @for (issue of item.issues; track issue) {
            <li class="list-style-disc">
              {{ issue }}
            </li>
          }
        </ul>
      </ng-template>

      <ng-template appDiagnosticPanelGroupDef="success" let-item>
        <ng-container
          *ngTemplateOutlet="itemHeading; context: { $implicit: item }"
        />
      </ng-template>
    </app-diagnostic-panel>
  }

  @if (data().get('qualification')) {
    <app-diagnostic-panel
      [groups]="data().get('qualification')"
      heading="Qualifications"
      (clicked)="panelClicked.emit($event)"
    >
      <ng-template appDiagnosticPanelGroupDef="failure" let-item>
        <ng-container
          *ngTemplateOutlet="itemHeading; context: { $implicit: item }"
        />
        <ul>
          @for (issue of item.issues; track issue) {
            <li class="list-style-disc">
              {{ issue }}
            </li>
          }
        </ul>
      </ng-template>

      <ng-template appDiagnosticPanelGroupDef="success" let-item>
        <ng-container
          *ngTemplateOutlet="itemHeading; context: { $implicit: item }"
        />
      </ng-template>
    </app-diagnostic-panel>
  }
</ng-scrollbar>
