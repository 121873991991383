import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { INTERCOM_INTEGRATION_CLASS_NAME } from '@configs';

@Directive({
  selector: '[appIntegrateIntercom]',
  standalone: true,
})
export class IntegrateIntercomDirective implements OnInit, OnDestroy {
  constructor(
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  ngOnInit(): void {
    this._renderer.addClass(
      this._document.body,
      INTERCOM_INTEGRATION_CLASS_NAME
    );
  }

  ngOnDestroy(): void {
    this._renderer.removeClass(
      this._document.body,
      INTERCOM_INTEGRATION_CLASS_NAME
    );
  }
}
