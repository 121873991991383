import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import {
  NotificationActions,
  NotificationSelectors,
} from '@core/modules/notifications/store';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { RosettaInfoMessageComponent } from '@shared/components';
import { RosettaMenuComponent } from '@shared/components/rosetta-menu.component';
import { ShowSpinnerDirective } from '@shared/directives/show-spinner.directive';
import { RosettaOverlayTargetDirective } from '@shared/modules/rosetta-overlay/rosetta-overlay-target.directive';
import { RosettaOverlayComponent } from '@shared/modules/rosetta-overlay/rosetta-overlay.component';
import { map, shareReplay } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    LetDirective,
    MatButtonModule,
    RosettaOverlayComponent,
    RosettaOverlayTargetDirective,
    MatBadgeModule,
    FontsModule,
    RosettaMenuComponent,
    RosettaInfoMessageComponent,
    ShowSpinnerDirective,
  ],
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMenuComponent {
  hasLoaded$ = this._store.select(NotificationSelectors.selectHasLoaded);
  notifications$ = this._store
    .select(NotificationSelectors.selectNotifications)
    .pipe(shareReplay(1));
  numberNotifications$ = this.notifications$.pipe(
    map(notifications => notifications.length)
  );
  notificationError$ = this._store.select(
    NotificationSelectors.selectHasNotificationError
  );

  constructor(private _store: Store) {}

  dismissNotification(id: string): void {
    this._store.dispatch(NotificationActions.dismissNotification({ id }));
  }

  fetchNotifications(): void {
    this._store.dispatch(NotificationActions.loadNotifications());
  }
}
