import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  numberAttribute,
} from '@angular/core';
import { FilterPipeFunc } from '@models';
import { LetDirective } from '@ngrx/component';
import { RosettaFilterPipe, SortByIteratees, SortByPipe } from '@shared/pipes';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { TextInputComponent } from '../text-input/text-input.component';
import { FilteredListHeightPipe } from './filtered-list-height.pipe';

@Component({
  standalone: true,
  selector: 'app-filtered-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './filtered-list.component.html',
  styleUrls: ['./filtered-list.component.scss'],
  imports: [
    NgTemplateOutlet,
    NgScrollbarModule,
    RosettaFilterPipe,
    RosettaMenuComponent,
    ScrollingModule,
    TextInputComponent,
    RosettaFilterPipe,
    FilteredListHeightPipe,
    SortByPipe,
    LetDirective,
  ],
})
export class FilteredListComponent<T = unknown> {
  constructor(
    @Attribute('inputPlaceholder') public inputPlaceholder = 'Filter'
  ) {}

  @ContentChild('staticContent', { read: TemplateRef })
  staticContentTemplate!: TemplateRef<null>;

  @ContentChild('rowContent', { read: TemplateRef })
  rowContentTemplate!: TemplateRef<any>;

  @Input({ required: true }) data: T[] = [];
  @Input() filterString = '';
  @Input() itemSize = 45;
  @Input({ transform: numberAttribute }) height = 400;
  @Input({ transform: numberAttribute }) width = 400;
  @Input() filterFunc?: FilterPipeFunc<T>;
  @Input() sortByIteratees?: SortByIteratees<T>;
}
