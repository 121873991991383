import { UserRegistrationCredentials } from '@features/auth/register/models/register.model';
import { ModelInstanceId } from '@models/domain-models';
import { createAction, props } from '@ngrx/store';

export const saveUserProfile = createAction(
  '[Settings] Save User Profile',
  props<{ userRegistration: UserRegistrationCredentials }>()
);
export const saveUserProfileSuccess = createAction(
  '[Settings] Save User Profile Success'
);
export const saveUserProfileError = createAction(
  '[Settings] Save User Profile Error',
  props<{ message: string }>()
);

export const openUpdatePasswordDialog = createAction(
  '[Settings] Open Update Password Dialog'
);

export const downloadModelUsers = createAction(
  '[Settings] Download Model Users',
  props<{ modelId?: ModelInstanceId }>()
);

export const downloadModelUsersSuccess = createAction(
  '[Settings] Download Model Users Success',
  props<{ modelId: ModelInstanceId }>()
);

export const downloadModelUsersError = createAction(
  '[Settings] Download Model Users Failure',
  props<{ modelId: ModelInstanceId; message: string }>()
);

export const cleanupSessionState = createAction(
  '[Settings] Cleanup Session State'
);
