import { Inject, Injectable } from '@angular/core';
import { StorageKey } from '@models';
import { WA_SESSION_STORAGE } from '@ng-web-apis/common';
import { deepMerge } from '@utils/object-utils';
import {
  parseExtended,
  stringifyExtended,
} from '@utils/stringify-parse-extended.utils';

@Injectable({
  providedIn: 'root',
})
export class ComponentStateService<T = any> {
  constructor(@Inject(WA_SESSION_STORAGE) private _storage: Storage) {}

  private readonly _globalKey = 'global-';

  get length(): number {
    return this._storage.length;
  }

  set(key: StorageKey, value: T): void;
  set(
    key: StorageKey,
    value: T extends object ? T : never,
    mergeData?: boolean
  ): void;
  set(key: StorageKey, value: any, mergeData?: boolean): void {
    if (typeof value === 'object' && mergeData) {
      value = deepMerge({}, this.get(key), value);
    }
    this._storage.setItem(this._getKey(key), stringifyExtended(value));
  }

  get(key: StorageKey): T | undefined {
    const item = this._storage.getItem(this._getKey(key));
    return item && parseExtended(item);
  }

  has(key: StorageKey): boolean {
    return !!this.get(key);
  }

  remove(...keys: StorageKey[]): void {
    keys.forEach(({ key, namespace }) => {
      this._storage.removeItem(`${namespace ? namespace + ':' : ''}${key}`);
    });
  }

  removeAll(options?: { clearGlobal: boolean }): void {
    if (options?.clearGlobal) {
      this._storage.clear();
    } else {
      const keysToRemove = Array.from({ length: this._storage.length }).reduce<
        StorageKey[]
      >((acc, _, idx) => {
        const key = this._storage.key(idx);
        if (key && !key.startsWith(this._globalKey)) {
          return acc.concat({ key });
        }
        return acc;
      }, []);
      this.remove(...keysToRemove);
    }
  }

  private _getKey({ key, namespace, global }: StorageKey): string {
    return `${global ? this._globalKey : ''}${
      namespace ? namespace + ':' : ''
    }${key}`;
  }
}
