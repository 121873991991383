type MapValue = [unknown, unknown][];
interface MapSerialized {
  __type: 'Map';
  __value: MapValue;
}
type SetValue = unknown[];
interface SetSerialized {
  __type: 'Set';
  __value: SetValue;
}

/**
 * JSON.stringify extended functionality to support "new Set()" and "new Map()"
 */
export const stringifyExtended = (value: any): string => {
  return JSON.stringify(value, (_, valueToStringify) => {
    if (valueToStringify instanceof Set) {
      return {
        __type: 'Set',
        __value: Array.from(valueToStringify),
      };
    }
    if (valueToStringify instanceof Map) {
      return {
        __type: 'Map',
        __value: Array.from(valueToStringify.entries()),
      };
    }
    return valueToStringify;
  });
};

/**
 * JSON.parse extended functionality to support "new Set()" and "new Map()"
 */
export const parseExtended = <R = any>(value: string | undefined): R => {
  if (value === 'undefined') {
    return undefined;
  }

  const isSet = (setValue: any): setValue is SetSerialized =>
    setValue['__type'] === 'Set';
  const isMap = (mapValue: any): mapValue is MapSerialized =>
    mapValue['__type'] === 'Map';

  return (
    value &&
    JSON.parse(value, function setReceiver<
      TValue,
    >(_: unknown, receiverValue: TValue): TValue | Map<string, any> | Set<any> {
      if (typeof receiverValue === 'object' && receiverValue !== null) {
        if (isSet(receiverValue)) {
          const entries = Object.values(receiverValue.__value).map(v =>
            setReceiver(null, v)
          );
          return new Set(entries);
        }
        if (isMap(receiverValue)) {
          const entries: any = Object.values(receiverValue.__value).map(
            ([k, v]) => [k, setReceiver(null, v)]
          );
          return new Map(entries);
        }
      }
      return receiverValue;
    })
  );
};
