import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import { combineLatest, first, map, switchMap, tap } from 'rxjs';
import { CodeViewSearchService } from './code-view-search.service';

@Component({
  selector: 'app-code-view-search',
  standalone: true,
  imports: [
    AsyncPipe,
    FontsModule,
    FormsModule,
    LetDirective,
    MatButtonModule,
    MatInputModule,
  ],
  templateUrl: './code-view-search.component.html',
  styleUrls: ['./code-view-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeViewSearchComponent {
  constructor(private _searchService: CodeViewSearchService) {}

  @Output() changes = new EventEmitter<string>();

  searchTerm = '';

  numberResult$ = this._searchService.current().pipe(
    switchMap(searchItem =>
      combineLatest([
        this._searchService.position(),
        this._searchService.count(),
      ]).pipe(
        map(([position, count]) => (count ? `${position} of ${count}` : null)),
        tap(() => this._change(searchItem?.key))
      )
    )
  );

  scrollToCurrent(): void {
    this._searchService
      .current()
      .pipe(first())
      .subscribe(searchItem => this._change(searchItem?.key));
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this._searchService.search(searchTerm);
  }

  next(): void {
    this._searchService.next();
  }

  prev(): void {
    this._searchService.prev();
  }

  clear(): void {
    this.search('');
    this.searchTerm = '';
  }

  private _change(value: string): void {
    if (value) {
      this.changes.emit(value);
    }
  }
}
