import { SampleCellState } from '@shared/components/transform/models/sample-cell-state.enum';
import { DiagnosticsSummaryData } from '../../components/diagnostic-chart/diagnostic.models';

export interface DataViewerSummary {
  rows: number;
  columns: number;
  valid: number;
  warning: number;
  error: number;
  cellStates: Partial<Record<SampleCellState, number>>;
  diagnosticsSummaryData?: DiagnosticsSummaryData;
}

export const getDefaultDataViewerSummary = (): DataViewerSummary => ({
  rows: 0,
  columns: 0,
  valid: 0,
  warning: 0,
  error: 0,
  cellStates: {},
});
