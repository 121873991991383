import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appForceLinkTargetBlank]',
})
export class ForceLinkTargetBlankDirective implements AfterViewInit {
  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    const links = this._el.nativeElement.querySelectorAll(
      'a[href]'
    ) as HTMLElement[];
    links.forEach(link =>
      this._renderer.setAttribute(link, 'target', '_blank')
    );
  }
}
