import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  SkipSelf,
  booleanAttribute,
  computed,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontsModule } from '@app/fonts/fonts.module';
import { RecursivePartial, StatusStateName } from '@models';
import { InOutAnimation, SlideInAnimation } from '@shared/animations';
import { deepMerge } from '@utils/object-utils';
import { PipelineRunInfo, PipelineRunResult } from '../../models';
import { StatusComponent } from '../status/status.component';
import {
  STATUS_COMPONENT_CONFIG,
  Statuses,
  defaultConfig,
} from './run-status.config';

@Component({
  standalone: true,
  imports: [FontsModule, StatusComponent, MatButtonModule],
  selector: 'app-run-status',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './run-status.component.html',
  animations: [SlideInAnimation, InOutAnimation],
  styles: [
    `
      :host {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        gap: 0 0.5rem;
        text-wrap: nowrap;
      }

      .status-text {
        display: flex;
        flex-direction: column;
        flex: 1;
        line-height: 1rem;
        overflow: hidden;
      }
    `,
  ],
})
export class RunStatusComponent {
  constructor(
    @Inject(STATUS_COMPONENT_CONFIG)
    @Optional()
    @SkipSelf()
    statusConfigOverride: RecursivePartial<Statuses> = {}
  ) {
    this._config = deepMerge(defaultConfig, statusConfigOverride);
  }

  @Output() rerun = new EventEmitter<void>();

  @Input({ required: true })
  set runResultState(value: PipelineRunInfo) {
    if (value) {
      this._updateStatusState(value);
    } else {
      this._resetState();
    }
  }

  @Input({ transform: booleanAttribute }) autoRun = false;

  private _config!: Statuses;
  readonly statusStateName = StatusStateName;

  statusState = signal(StatusStateName.Empty);
  description = signal<string | null>(null);
  canRerun = signal(false);
  state = computed(() => this._config[this.statusState()]);

  onRerun(): void {
    this.canRerun.set(false);
    this.rerun.emit();
    this._setStatusStale(true);
  }

  private _updateStatusState(runUpdate: PipelineRunInfo): void {
    const { result, errorMessage, stale } = runUpdate;

    if (!this.autoRun) {
      this.canRerun.set(stale);
    }

    if (errorMessage) {
      this._setStatusError();
    } else if (stale) {
      this._setStatusStale(this.autoRun);
    } else if (this._isRunComplete(result)) {
      this._setStatusFinished(result);
    } else {
      this._setStatusStarted(result);
    }
  }

  private _resetState(): void {
    this.statusState.set(StatusStateName.Empty);
    this.description.set(null);
  }

  private _isRunComplete(runResult?: PipelineRunResult): boolean {
    if (!runResult?.details) {
      return false;
    }
    return (
      runResult.details.currentPipeline === runResult.details.totalPipelines &&
      runResult.data !== null
    );
  }

  private _getCountText(runResult: PipelineRunResult): string {
    return `${runResult.details.currentPipeline} of ${runResult.details.totalPipelines}`;
  }

  private _setStatusError(): void {
    this.statusState.set(StatusStateName.Error);
    this.description.set(null);
  }

  private _setStatusStale(showDescription: boolean): void {
    this.statusState.set(StatusStateName.Stale);
    this.description.set(showDescription ? 'Waiting to rerun...' : '');
  }

  private _setStatusFinished(result: PipelineRunResult): void {
    this.statusState.set(StatusStateName.Finished);
    this.description.set(this._getCountText(result));
  }

  private _setStatusStarted(result: PipelineRunResult): void {
    const descriptionText = result.details.description || '';
    this.statusState.set(StatusStateName.Started);
    this.description.set(`${this._getCountText(result)} - ${descriptionText}`);
  }
}
