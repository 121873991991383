@if (chart) {
  <div [appRosettaOverlayHoverTarget]="popover" class="flex">
    <div class="chart-container">
      <google-chart #chartTemplate [data]="chart" />
      <fa-icon [icon]="icon" />
    </div>
    @if (completeness !== undefined) {
      <div class="description-container">
        <strong class="block color-75">{{ completeness }}%</strong>
        <small class="block">{{ title }}</small>
      </div>
    }
  </div>
}
