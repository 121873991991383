import { RosettaStepOption } from '../../modules/rosetta-tour/rosetta-tour.service';

export const transformTourSteps: RosettaStepOption[] = [
  {
    stepId: 'transform-1',
    anchorId: 'transform.synonym-source',
    title: 'Selecting a Synonym Source',
    content: `Selecting a synonym will select the first available test
              pack and run ingest automatically. Editing the model or changing
               synonyms will rerun ingest.`,
    isAsync: true,
  },
  {
    stepId: 'transform-2',
    anchorId: 'transform.test-pack',
    title: 'Selecting a Test Pack',
    content: `The first available test pack is selected and run automatically.
    Editing the model or changing test packs will rerun ingest.`,
    isAsync: true,
  },
  {
    stepId: 'transform-4',
    anchorId: 'rosetta-table.options',
    title: 'Test Pack Options',
    content: `Click to see options, including adding samples to your Test Pack.`,
    isAsync: true,
    nextOnAnchorClick: true,
  },
  {
    stepId: 'transform-3',
    anchorId: 'options-item.add-sample',
    anchorCheckOverrideId: 'rosetta-table.options',
    title: 'Adding Samples to a Test Pack',
    content: `Samples will be added to the selected Pipeline/Test Pack and
              all connected ones. Deleting a sample removes it
              from all connected Pipelines/Test Packs.`,
    isAsync: true,
  },
  {
    // TODO: Fix step triggering when sample row becomes visible, currently step is triggered before row is rendered
    stepId: 'transform-5',
    anchorId: 'xxx.xxx',
    title: 'Copy and edit existing samples',
    content: `Boost your efficiency! Quickly copy, edit, and rename samples within your pipeline.`,
    isAsync: true,
  },
];
