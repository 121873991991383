<span
  [@fullLogoAnimation]="mode"
  class="logo"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <img
    [@stoneAnimation]="mode"
    [src]="logoUrl$ | async"
    alt="Rosetta Full Logo"
  />
</span>

@if (showInfo && (packagePlan$ | async); as packagePlanDetails) {
  <span class="logo-divider" [class.dark-theme]="isDarkTheme$ | async"></span>
  <span class="logo-package-plan mat-small">{{
    packagePlanDetails.packagePlan | rosettaTitleCase
  }}</span>
  @if (packagePlanDetails.trial) {
    <app-text-badge
      class="ml-16 theme-bg-color"
      [appRosettaOverlayHoverTarget]="overlay"
    >
      <span>Trial</span>
      <span *appTrialEndingSoon>
        ends in {{ packagePlanDetails.endDate | daysUntilDate }}
      </span>
    </app-text-badge>
  }
  <app-rosetta-overlay #overlay>
    <app-rosetta-menu
      >Your trial ends in
      <strong>{{ (packagePlan$ | async)?.endDate | daysUntilDate }}</strong> ({{
        (packagePlan$ | async)?.endDate | date
      }})</app-rosetta-menu
    >
  </app-rosetta-overlay>
}
