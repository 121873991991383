import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FontsModule } from '@app/fonts/fonts.module';
import { ExternalLink } from '@configs';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { TextBadgeComponent } from '@shared/components/text-badge/text-badge.component';
import * as dialogActions from '@store/actions/dialog.actions';
import { AppSelectors } from '@store/selectors';

@Component({
  standalone: true,
  imports: [
    LetDirective,
    MatButtonModule,
    MatMenuModule,
    FontsModule,
    TextBadgeComponent,
  ],
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpMenuComponent {
  constructor(private _store: Store) {}

  readonly externalLink = ExternalLink;
  newReleaseNotes$ = this._store.select(AppSelectors.newReleaseNotes);

  openIssueTracker(): void {
    this._store.dispatch(dialogActions.openIssueTrackerDialog());
  }

  openReleaseNotes(): void {
    this._store.dispatch(dialogActions.openAppReleaseNoteDialog());
  }
}
