import { Directive, input } from '@angular/core';
import {
  IRosettaSelector,
  IRosettaSelectorTemplate,
} from './rosetta-selector.model';

@Directive({
  selector: 'ng-template[appRosettaSelectorTrigger]',
  standalone: true,
})
export class RosettaSelectorTriggerDirective<TData> {
  type = input<IRosettaSelector<TData>>(null, {
    alias: 'appRosettaSelectorTrigger',
  });

  static ngTemplateContextGuard<TContext>(
    directive: RosettaSelectorTriggerDirective<TContext>,
    context: unknown
  ): context is IRosettaSelectorTemplate<TContext> {
    return true;
  }
}
